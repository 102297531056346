.suggestHeader {
  color: #252f40;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.profileCounts {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 20px 27px 0px rgba(0, 0, 0, 0.05);
  padding: 16px 40px;
  display: inline-flex;
}

.profileCounts .totalprofile h4 {
  color: #0a0a0a;
  font-size: 32px;
  font-weight: normal;
  line-height: 42px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Just-M';
}

.profileCounts .totalprofile h4 span {
  color: #7e7e7e;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  padding-left: 4px;
  font-family: 'Just';
  margin-right: 12px;
}

.profileCounts .profilefilter {
  display: flex;
}

.profileCounts .profilefilter p {
  color: #7e7e7e;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 8px 0 0;
}

.profileFilterHeader .sliderText span.profiles {
  border-radius: 8px;
  border: 1px solid #bdbdbd;
  background: #BDBDBD;
  padding: 2px 8px;
  margin: 0px 8px 0 5px;
  color: #fff;
  font-size: 10px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.332px;
  vertical-align: middle;
}

.profileFilterHeader .sliderText.active span.profiles {
  border-radius: 8px;
  border: 1px solid var(--secondry-color);
  color: #fff;
  background: var(--secondry-color);
}

.profileHolder {
  padding: 24px 0;
}

.profileHolder .sliderText {
  color: #7e7e7e;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  margin-right: 16px;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  padding-bottom: 4px;
}

.profileHolder .sliderText.active {
  color: var(--secondry-color);
  /* border-bottom: 3px solid var(--secondry-color); */
  padding-bottom: 4px;
}

.profileHolder .profileCard {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 20px 27px 0px rgba(0, 0, 0, 0.05);
  margin-top: 23px;
  padding: 24px 0;
}

.suggestedProfile.profileHolder .profileCard {
  padding-top: 0;
}

.profileHolder .profileCard .profileFilterHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profileHolder .profileCard .profileFilterHeader .filterButtons {
  display: flex;
  align-items: center;
}

.profileHolder .profileCard .profileFilterHeader .searchbar {
  position: relative;
}

.profileHolder .profileCard .profileFilterHeader .searchbar input {
  background: #ffffff;
  border: 1px solid #d2d6da;
  border-radius: 8px;
  outline: none;
  padding: 10px 10px 10px 35px;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.387234px;
  color: #252f40;
}

.profileHolder .profileCard .profileFilterHeader .searchbar img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}

.customImportButton {
  border-radius: 8px;
  border: 1px solid var(--secondry-color);
  color: var(--secondry-color);
  font-size: 14px;
  line-height: 24px !important;
  font-weight: normal;
  line-height: normal;
  padding: 10px 19px 10px 19px;
  background: transparent;
  margin-right: 12px;
}

.customImportButton img {
  margin-right: 5px;
}

.profileHolder .profileCard .profileCardBody {
  padding: 10px 0 20px;
}

/* .profileHolder .profileCard .profileCardBody .tableResponsive {
  min-height: 900px;
} */


.profileCardBody .tableResponsive {
  width: 100%;
  overflow-x: auto !important;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.profileCardBody .profileTable {
  width: 100%;
}

.profileCardBody .profileTable thead tr {
  background-color: #fff;
  border-bottom: 1px solid #BDBDBD;
}

.profileCardBody .profileTable thead tr th {
  font-weight: normal;
  padding: 20px 15px;
  white-space: nowrap;
  font-size: 16px;
  line-height: 24px;
  color: #0a0a0a;
  font-family: 'Just-M';
}

.profileCardBody .profileTable thead tr th span {
  font-size: 16px;
  color: #0a0a0a;
  font-family: 'Just-M';

}

.profileCardBody .profileTable thead {
  border-bottom: 1.5px solid #9e9e9e;
}

.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.tableBottom .filterButton .dropdownButton .select-border {
  border-radius: 4px;
  background-color: #eceff4;
  margin: 0;
  color: #7e7e7e;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  /* padding: 8px 18px; */
}

.customPagination {
  margin: 0;
}

.customPagination.pagination>li>a {
  border: 0;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  color: #7e7e7e;
}

.customPagination.pagination .active a {
  border-radius: 4px;
  background: var(--primary-color);
  color: var(--secondry-color);
}

.profileCardBody .profileTable tbody tr td {
  padding: 20px 15px 20px 15px;
  vertical-align: middle;
}

.profileCardBody .profileTable tbody tr td p {
  color: #363636;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  margin: 0;
}

.profileCardBody .profileTable tbody tr td p a {
  text-decoration: underline;
  color: #0A0A0A;
}

.profileCardBody .profileTable tbody tr td p.role {
  color: #363636;
}

button.contact {
  border-radius: 8px;
  border: 1px solid var(--secondry-color);
  background-color: transparent;
  white-space: nowrap;
  color: var(--secondry-color);
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  padding: 6px 18px;
}

.profileHolder .profileCard .profileCardBody .profileTable tbody tr td .edit {
  border-radius: 8px;
  border: 1px solid #bdbdbd;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
}

.profileHolder .profileCard .profileCardBody .profileTable tbody tr td .edit i {
  color: var(--primary-color);
}

.profileHolder .profileCard .profileCardBody .profileTable tbody tr td .edit.delete {
  margin-right: 5px;
}

.profileHolder .profileCard .profileCardBody .profileTable tbody tr td .edit.delete i {
  color: red;
}

.profileCardBody .profileTable tbody tr td p.detail {
  color: #363636;
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  margin-top: 3px;
}

.importModal .header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.importModal .header i {
  cursor: pointer;
}

.importModal .header h4 {
  color: #0a0a0a;
  font-size: 18px;
  font-weight: normal;
  line-height: 24px;
  margin: 0;
  font-family: 'Just-M';
}

.importModal .header p {
  color: #7e7e7e;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  margin-top: 32px;
  margin-bottom: 24px;
}

.importModal .header p span {
  color: #0a0a0a;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  font-family: 'Just-M';
}

.importModal .modalBody p.sampleFile {
  color: #0a0a0a;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  margin: 0 0 24px;
}

.importModal .modalBody p.sampleFile a {
  color: var(--primary-color);
  text-decoration: underline;
}

.importModal .modalBody p.desc {
  font-size: 14px;
  font-family: "Just";
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  color: #363636;
}

.importModal .modalBody .uploadHolder {
  border-radius: 8px;
  border: 1px dashed #395f8c;
  text-align: center;
  padding: 20px;
  margin-bottom: 40px;
}

.importModal .modalBody p.desc span {
  font-family: "Just-B";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 0px;
  padding-top: 10px;
  color: #363636;
}

.importBtn {
  text-align: center;
  margin-top: 40px;
}

/* change background if button is disabled */
button:disabled,
button[disabled] {
  border: 1px solid #7e7e7e !important;
  border-radius: 8px;
  background: #bdbdbd;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07),
    0px 4px 6px -1px rgba(0, 0, 0, 0.12);
  color: #0a0a0a;
  border: 0;
  outline: none;
}

.importModal .modalBody .customImportButton {
  border: 3px solid #7e7e7e !important;
}

button.stepPagination:disabled i,
button.stepPagination[disabled] i {
  color: #fff;
}

button.contact:disabled,
button.contact[disabled],
button.contact.disabled {
  border-radius: 8px !important;
  border: 1px solid #bdbdbd;
  background: transparent;
  color: #bdbdbd;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.importModal .successBody {
  margin-top: 14px;
}

.importModal .successBody p.descrip {
  color: #0a0a0a;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  margin-bottom: 0;
  /* 19.6px */
}

.importModal .successBody p.descrip span {
  font-weight: 700;
}

.profileCardBody .succesTable tbody tr td p {
  color: #363636;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  line-height: normal;
}

.profileCardBody .succesTable tbody tr td p.detail {
  font-size: 14px;
}

.importModal .modal-body {
  padding: 32px;
}

.importModal .profileCardBody .profileTable tbody tr td {
  padding: 14px 79px 10px 10px;
  vertical-align: top;
}

.importModal .modalBody .fileHolder {
  height: 200px;
}

.importModal .modalBody .addedFiles {
  border-top: 1px solid #bdbdbd;
  border-bottom: 1px solid #bdbdbd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  margin-top: 8px;
}

.importModal .modalBody .addedFiles .fileInfo {
  display: flex;
  align-items: center;
}

.importModal .modalBody .addedFiles .fileInfo .fileName {
  margin-left: 8px;
}

.importModal .modalBody .addedFiles .fileInfo .fileName p {
  color: #0a0a0a;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  margin: 0;
  font-family: 'Just-M';
}

.importModal .modalBody .addedFiles .fileInfo .fileName span {
  color: #7e7e7e;
  font-size: 14px;
  line-height: normal;
}

.customReplaceButton {
  border-radius: 8px;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  background-color: transparent;
  padding: 10px 49px;
  cursor: pointer;
}

.importSampleFile p.desc {
  margin-top: 5px;
  margin-bottom: 0;
}

.importSampleFile .messageHolder {
  background: #fff0f3;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 32px;
  margin-top: 12px;
}

.importSampleFile .messageHolder .content {
  margin-left: 16px;
}

.importSampleFile .messageHolder .content h5 {
  color: #0a0a0a;
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  font-family: 'Just-M';
  margin-bottom: 16px;
}

.importSampleFile .messageHolder .content ul {
  padding: 0 50px 0 20px;
  margin: 0;
}

.importSampleFile .messageHolder .content ul li {
  color: #363636;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

.importSampleFile .messageHolder .content ul li strong {
  font-weight: normal;
  font-family: 'Just-M';
  color: #0a0a0a;
}

.importSampleFile .messageHolder .content ul li span {
  font-weight: 700;
}

.importSampleFile .messageHolder.successMessge {
  background: #eceff4;
}

.importModal .modalBody .addedFiles.loaderHolder {
  display: block;
}

.importSampleFile .loaderHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.importSampleFile .loaderHead p {
  color: #363636;
  font-size: 12px;
  font-weight: 400;
  line-height: 160%;
  /* 19.2px */
  margin: 0;
}

.importSampleFile .loaderHead p.percent {
  color: #0a0a0a;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  font-family: 'Just-B';
}

.importSampleFile .progressHolder {
  margin-top: 24px;
}

.importSampleFile .progressHolder .progress {
  border-radius: 5px;
  background: #e9ecef;
  height: 10px;
}

.importSampleFile .progressHolder .progress .progress-bar {
  height: 10px;
  background: var(--primary-color);
}

.contactModal .modal-dialog {
  max-width: 500px;
}

.contactModal .modal-body {
  padding: 32px;
}

.contactModal .header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.contactModal .contactBody h5 {
  color: #252f40;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
}

.contactModal .contactBody .contactProfile {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 32px;
}

.contactModal .contactBody .contactProfile .imageHolder {
  width: 24px;
  height: 24px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 3px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.contactModal .contactBody .contactProfile .contactName {
  border-radius: 8px;
  border: 1.5px solid #BDBDBD;
  background: #F2F5FA;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 10px;
  max-width: 160px;
  width: 100%;
}

.contactModal .contactBody .contactProfile .contactName p {
  color: var(--secondry-color);
  text-align: center;
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  margin: 0;
}

.contactModal .contactBody .contactProfile .contactNumber {
  margin-left: 40px;
}

.contactModal .contactBody .contactProfile .contactNumber .textField {
  color: #0a0a0a;
  font-size: 14px;
  font-weight: normal;
  font-family: 'Just-M';
  line-height: 24px;
  /* 19.6px */
  margin: 0;
  margin-bottom: 12px;
  display: flex;
  align-items: flex-start;
}

.contactModal .contactBody .contactProfile .contactNumber .textField ul {
  padding: 0 0 0 5px;
  margin: 0;
}

.importModal.contactModal.contact-informations .modal-dialog {
  max-width: 600px;
}

.importModal.contactModal.contact-informations .header {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 123456;
}

.importModal.contactModal.contact-informations .customFilterButton {
  width: 200px;
}

.contactModal .contactBody .contactProfile .contactNumber .textField ul li {
  list-style: none;
  color: #7e7e7e;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  margin-bottom: 2px;
}

.customFilterButton {
  border-radius: 8px;
  background: var(--primary-color);
  color: #000;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  padding: 10px 30px;
  outline: none;
  border: 1px solid var(--primary-color);
}

.customFilterButton i {
  margin-left: 8px;
}

.form-select {
  padding-right: 40px;
}

.importModal .modal-content {
  padding: 0;
}

.addMoreFeature {
  background: var(--primary-color);
  border: 1px dashed var(--secondry-color);
  border-radius: 8px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--secondry-color);
  margin-top: 16px;
  cursor: pointer;
}

.addMoreFeature img {
  margin-right: 4px;
  width: 10px;
}

.removeBtnContainer {
  position: relative;
}

.removeBtn {
  top: 11px;
  right: 10px;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 5px;
  cursor: pointer;
  background: var(--primary-color);
  color: #fff;
  text-align: center;
  position: absolute;
}

.removeBtn i {
  font-size: 12px;
}

.ConnectBtn {
  border-radius: 8px;
  border: 1px solid var(--primary-color);
  background: var(--primary-color);
  color: var(--secondry-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 6px 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ConnectBtn img {
  margin-right: 5px;
}

.premiumModal .modal-dialog {
  max-width: 545px;
}

.premiumModal .modal-dialog .importBtn button {
  display: unset;
}

.premiumModal .modal-dialog .premiumContent {
  margin-top: 20px;
}

.premiumModal .modal-dialog .premiumContent p {
  color: #363636;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  margin-bottom: 10px;
}

.premiumModal .modal-dialog .premiumContent .agree-button {
  margin: auto;
}

.masked {
  color: #9e9e9e !important;
}

.form-control.role-border {
  height: 40px;
  display: flex;
  align-items: center;
}

.form-control.role-border p.role {
  color: #7e7e7e;
  font-size: 14px;
  line-height: 24px;
  font-style: normal;
  font-weight: normal;
  margin: 0;
}

.disabled-link {
  cursor: not-allowed;
  /* Change the cursor to indicate not clickable */
  pointer-events: none;
  /* Disable pointer events to prevent clicks */
  text-decoration: none;
  /* Optionally remove underline */
  color: #7e7e7e !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none !important;
}

.customAddButton {
  background: var(--primary-color);
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.12),
    0px 2px 4px -1px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  border: 3px solid var(--primary-color);
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--secondry-color);
  padding: 10px 19px 10px 32px;
}

.customAddButton:hover {
  color: var(--secondry-color);
}

button.customAddButton:disabled,
button.customAddButton[disabled] {
  border-radius: 8px;
  background: var(--grey, #bdbdbd);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07),
    0px 4px 6px -1px rgba(0, 0, 0, 0.12);
  color: #000;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
}

.modalBody.importSampleFile button.customAddButton {
  width: 200px;
}

.customAddButton.removePadding {
  padding-left: 19px;
  width: 200px;
}

.importModal .customAddButton.removePadding {
  width: auto;
}

.labelForm {
  margin-top: 15px;
  margin-bottom: 2px;
  margin-left: 0px;
}

.profileFilterHeader {
  display: flex;
  align-items: center;
}

.importModal .modalBody .labelForm {
  margin-top: 15px;
  margin-bottom: 2px;
  margin-left: 0px;
  font-size: 14px;
  line-height: 24px;
  color: #7e7e7e;
  font-weight: normal;
}

ul.bulletSchedule {
  list-style: none;
  padding: 0;
  font-size: 12px;
  margin: 16px 0 40px;
}

.rescheduleSetting {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.rescheduleSetting .annually-btn {
  top: 4px;
  margin-left: 10px;
}

.rescheduleSetting .rescheduleText {
  font-weight: 800;
}

button.customAddButton.highlightBorder {
  background: transparent;
  border-width: 2px;
}

.modal .rescheduleTextarea .form-control {
  border-width: 1px;
  margin-top: 7px;
}

.moddal-primary.confirmMeeting .modal-dialog {
  max-width: 580px;
}

ul.bulletSchedule span {
  font-weight: 600;
}


table.profileTable tbody tr:nth-child(2n+2) {
  background: #F2F5FA;
  border-top: 1px solid #BDBDBD;
  border-bottom: 1px solid #BDBDBD;
}

@media screen and (max-width: 767px) {

  
  .customFilterButton {
    font-size: 11px;
    line-height: 16px;
    padding: 10px 20px;
  }

  .customImportButton{
    font-size: 11px;
    line-height: 16px;
    padding: 6px 15px 6px 15px;
  }

  .suggestHeader {
    font-size: 16px;
  }

  .profileCardBody .profileTable thead tr th,
  .profileCardBody .profileTable tbody tr td,
  .profileCardBody .profileTable tbody tr td p {
    font-size: 12px;
    line-height: 18px;
  }

  .profileHolder .profileCard .profileCardBody .profileTable tbody tr td .edit {
    width: 35px;
    height: 35px;
    font-size: 12px;
    line-height: 20px;
  }

  .profileCounts .totalprofile h4 {
    font-size: 26px;
    line-height: 32px;
  }

  .contactModal .contactBody .contactProfile {
    flex-direction: column;
  }

  .contactModal .contactBody .contactProfile .contactName {
    max-width: 100%;
    margin-bottom: 15px;
  }

  .contactModal .contactBody h5 {
    font-size: 16px;
  }

  .contactModal .contactBody .contactProfile .contactNumber .textField {
    margin: 0;
  }
}

@media screen and (max-width: 920px) {
  .profileFilterHeader {
    flex-direction: column;
    align-items: flex-start;
  }

  .profileFilterHeader .filterButtons {
    margin-top: 10px;
  }
}