body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Just", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* chatfeatue */
.message-page {
  margin-top: 34px;
  max-width: 1366px;
}

.message-page .messageRow {
  height: calc(100vh - 128px);
  overflow: hidden;
  position: fixed;
  left: 0;
  z-index: 1233;
  padding: 0;
  width: calc(100% - 2rem);
  margin: 0 1rem;
  top: 110px;
}

/* .message-page .messageRow .messageLeft {} */

.message-page .messageRow .messageRight {
  background: #ffffff;
  border-radius: 0px 20px 0px 0px;
  padding: 0;
  height: 100%;
  overflow: hidden;
  position: relative;
  border-left: 1px solid #d2d6da;
}

.message-page .messageRow .messageRight.collapsediv {
  border-radius: 0px;
  border-right: 1px solid #d2d6da;
}

.message-page .messageRow .messageRight .messageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  border-bottom: 1px solid #d2d6da;
}

.message-page .messageRow .messageRight .messageHeader .userName h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #0a0a0a;
  margin-bottom: 4px;
}

.message-page .messageRow .messageRight .messageHeader .userName p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #7e7e7e;
  margin: 0;
}

.message-page .messageRow .messageRight .messageHeader .userName p span {
  font-style: normal;
  font-weight: 400;
  color: #7e7e7e;
}

.message-page .messageRow .messageRight .messageHeader .userName p span.messageCompanyLink,
.message-page .messageRow .userProfile .profile-header .userInfo p.messageCompanyLink {
  text-decoration: underline;
  color: #0a0a0a;
  cursor: pointer;
}

.message-page .messageRow .messageRight .messageHeader .openDashboard {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.message-page .messageRow .messageRight .messageHeader .openDashboard span {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #0a0a0a;
}

.message-page .messageRow .messageRight .messageHeader .openDashboard .arrowIcon {
  width: 40px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #BDBDBD;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
}

.message-page .messageRow .messageRight .messageHeader .openDashboard .arrowIcon img {
  width: 10px;
  transform: rotate(180deg);
}

.message-page .messageRow .messageRight .messageHeader .openDashboard .arrowIcon img.showProfileSec {
  width: 10px;
  transform: rotate(0deg);
}

.message-page .messageRow .messageRight .messageOptions {
  padding: 12px 24px;
  border: 1px solid #d2d6da;
}

.message-page .messageRow .messageRight .messageOptions ul {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  list-style: none;
  margin: 0;
}

.message-page .messageRow .messageRight .messageOptions ul li {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #0a0a0a;
  margin-right: 16px;
  cursor: pointer;
}

.message-page .messageRow .messageRight .messageHolder {
  border-bottom: 1px solid #d2d6da;
  height: calc(80vh - 178px);
  /* height: calc(80vh - 230px); */
  overflow: auto;
}

.message-page .messageRow .messageRight .messageHolder.addedMedia {
  height: calc(80vh - 250px);
  /* height: calc(80vh - 270px); */
}

.message-page .messageRow .messageRight .messageHolder .messageList {
  list-style: none;
  margin: 0;
  padding: 24px 24px;
}

.customScroll::-webkit-scrollbar {
  width: 12px;
}

/* Track */
.customScroll::-webkit-scrollbar-track {
  background: #f5f5f5;
  border: 1px solid #d2d6da;
  width: 12px;
}

/* Handle */
.customScroll::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 24px;
}

/* Handle on hover */
.customScroll::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd;
}

.message-page .messageRow .messageRight .messageHolder .messageList .messageListInner {
  display: flex;
  align-items: flex-start;
  margin-bottom: 28px;
  justify-content: flex-start;
  max-width: 60%;
}

.message-page .messageRow .messageRight .messageHolder .messageList .ownProperty .messageListInner {
  display: flex;
  align-items: flex-start;
  margin-bottom: 28px;
  justify-content: flex-end;
  /* background: rgba(0, 0, 0, 0.04); */
  border-radius: 10px;
  padding: 10px 10px 0px;
  /* text-align: left; */
  width: fit-content;
  margin-left: auto !important;
  max-width: 60%;
}

.message-page .messageRow .messageRight .messageHolder .messageList .messageListInner:last-child {
  margin: 0;
}

.message-page .messageRow .messageRight .messageHolder .messageList .messageListInner .profile_photo {
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px;
  border-radius: 50%;
  background-position: center;
}

.message-page .messageRow .messageRight .messageHolder .messageList .messageListInner .messagebyUser {
  margin-left: 8px;
}

.message-page .messageRow .messageRight .messageHolder .messageList .messageListInner .messagebyUser .userName .userMessage h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #0a0a0a;
}

.message-page .messageRow .messageRight .messageHolder .messageList .messageListInner .messagebyUser .userName .userMessage h4 img {
  width: 13px;
  height: 13px;
}

.message-page .messageRow .messageRight .messageHolder .messageList .messageListInner .messagebyUser .userName .userMessage h4 span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #7e7e7e;
  /* margin-left: 8px; */
}

.message-page .messageRow .messageRight .messageHolder .messageList .messageListInner .messagebyUser .userName .userMessage .messagingService,
.message-page .messageRow .messageRight .messageHolder .messageList .ownProperty.skeleton .messageListInner .messagebyUser .userName .userMessage .messagingService {
  background: #ECEFF4;
  padding: 12px;
  border-radius: 12px;
  position: relative;
}

.message-page .messageRow .messageRight .messageHolder .messageList .ownProperty .messageListInner .messagebyUser .userName .userMessage .messagingService {
  background: #F3C258;
}

.message-page .messageRow .messageRight .messageHolder .messageList .messageListInner .messagebyUser .userName .userMessage p {
  /* padding-right: 70px; */
}

.message-page .messageRow .messageRight .messageHolder .messageList .messageListInner .messagebyUser .userName .userMessage .currentMessageTime {
  /* position: absolute; */
  /* right: 10px; */
  font-size: 11px;
  line-height: 20px;
  bottom: 4px;
  color: #363636;
  text-align: right;
  padding-right: 0;
}

.message-page .messageRow .messageRight .messageHolder .messageList .messageListInner .messagebyUser .userName .userMessage,
.message-page .messageRow .messageRight .messageHolder .messageList .messageListInner .messagebyUser .userName .userMessage p {
  font-style: normal;
  font-weight: 100;
  font-size: 14px;
  line-height: 24px;
  color: #0a0a0a;
  margin-bottom: 0;
  overflow: hidden;
  /* width: 418px; */
  text-overflow: ellipsis;
  word-break: break-word;
}

.message-page .messageRow .messageRight .message-input {
  /* padding: 12px 35px 0px; */
  /* position: absolute;
  bottom: 0; */
  /* width: 100%;
  right: 0px; */
  /* background-color: #fff;
  height: calc(20vh - 230px); */
  position: relative;
}

.message-page .messageRow .messageRight .message-input form input.form-input {
  width: 100%;
  border: 0;
  outline: 0;
  height: 40px;
  min-width: 90%;
  background-color: #fff;
}

.message-page .messageRow .messageRight .message-input form .messageBtns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 98%;
  /* position: absolute;
  bottom: 20px; */
  padding-top: 45px;
}

.message-page .messageRow .messageRight .message-input form .messageBtns img {
  margin-right: 20px;
  cursor: pointer;
}

.message-page .messageRow .messageRight .message-input form .messageBtns img.emojiIcon {
  border-right: 2px solid #d2d6da;
  padding-right: 10px;
}

.message-page .messageRow .messageLeft {
  background: #ffffff;
  box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
  border-radius: 20px 0px 0px 0px;
  padding: 0 !important;
  /* overflow: hidden; */
  height: 100%;
  overflow: auto;
}

.message-page .messageRow .messageLeft .userListHeader {
  background: #0a0a0a;
  padding: 16px 0;
  position: sticky;
  top: 0px;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.message-page .messageRow .messageLeft .userListHeader input [type="search"]::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #0a0a0a;
  mix-blend-mode: normal;
  opacity: 0.5;
}

.message-page .messageRow .messageLeft .userListHeader input {
  width: 100%;
  border: 0.80179px solid #d2d6da;
  border-radius: 6.41432px;
  display: block;
  padding: 12px 4px 12px 40px;
  outline: none;
  background: #ffffff url("../public/assets/img/searchIcon.svg") no-repeat 14px center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #252f40;
  opacity: 1;
  width: 90%;
}

.message-page .messageRow .messageLeft .userListHeader i {
  color: #ffffff;
  cursor: pointer;
}

.message-page .messageRow .messageLeft .userList ul li .messageDetail {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.message-page .messageRow .messageLeft .userList ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.message-page .messageRow .messageLeft .userList ul li {
  display: flex;
  align-items: flex-start;
  padding: 16px 16px;
  cursor: pointer;
  border-bottom: 1px solid #d2d6da;
}

.message-page .messageRow .messageLeft .userList ul li.active {
  background: #eceff4;
}

.message-page .messageRow .messageLeft .userList ul li .messageDetail p.userName {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #0a0a0a;
  margin-bottom: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 70%;
}

.message-page .messageRow .messageLeft .userList ul li .messageDetail p.userName img {
  width: 13px;
  height: 13px;
}

.message-page .messageRow .messageLeft .userList ul li .messageDetail p.userDetail {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #363636;
  margin-bottom: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 90%;
  padding: 0 0 12px;
}

.message-page .messageRow .messageLeft .userList ul li .messageDetail p.message {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #363636;
  margin-bottom: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 90%;
}

.message-page .messageRow .messageLeft .userList ul li .messageDetail p.message span {
  font-weight: 700;
}

.message-page .messageRow .messageLeft .userList ul li .messageDetail p.message.styleItalic,
.message-page .messageRow .messageLeft .userList ul li .messageDetail p.message.styleItalic span {
  font-style: italic;
}

.message-page .messageRow .messageLeft .userList ul li .messageDetail p.message.light.bold,
.message-page .messageRow .messageLeft .userList ul li .messageDetail p.message.light.bold span {
  font-weight: 700;
}

.message-page .messageRow .messageLeft .userList ul li .messageDetail p.message.light,
.message-page .messageRow .messageLeft .userList ul li .messageDetail p.message.light span {
  font-weight: 300;
}

.message-page .messageRow .messageLeft .userList ul li .messageDetail p.message span.tagName {
  font-weight: 700;
  margin: 0;
  padding: 0;
  background: transparent;
}

.message-page .messageRow .messageLeft .userList ul li .messageDetail p.unReadMessage {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 2px;
  color: #7e7e7e;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 90%;
}

.message-page .messageRow .messageLeft .userList ul li .profile_photo {
  background-size: cover;
  background-repeat: no-repeat;
  padding: 25px;
  border-radius: 50%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background-position: center;
  margin-right: 12px;
}

.message-page .messageRow .messageLeft .userList ul li .messageDetail .lastSeen {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #7e7e7e;
  position: absolute;
  top: 0px;
  right: 0;
}

.message-page .messageRow .messageLeft .userList ul li .messageDetail .messageCount {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: -1px;
  color: #000000;
  background: var(--primary-color);
  width: 18px;
  height: 18px;
  border-radius: 50px;
  position: absolute;
  align-items: center;
  display: flex;
  justify-content: center;
  bottom: 0;
  right: 0;
}

.message-page .messageRow .userProfile {
  background: #ffffff;
  border-radius: 0px 20px 0px 0px;
  padding: 0;
  height: 100%;
  overflow: auto;
  display: none;
}

.message-page .messageRow .userProfile.showProfile {
  display: block;
}

.message-page .messageRow .userProfile .profile-header {
  display: flex;
  align-items: center;
}

.message-page .messageRow .userProfile .profile-header .profile_photo {
  background-size: cover;
  background-repeat: no-repeat;
  padding: 32px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 50%;
  background-position: center;
}

.message-page .messageRow .userProfile .profile-header {
  padding: 25px 12px 20px;
  border-bottom: 1px solid #d9d9d9;
  position: relative;
}

.message-page .messageRow .userProfile .profile-header .btn-close {
  position: absolute;
  top: 4px;
  right: 1px;
}

.message-page .messageRow .userProfile .profile-header .userInfo {
  margin-left: 8px;
}

.message-page .messageRow .userProfile .profile-header .userInfo h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: #0a0a0a;
  margin: 2px;
  display: inline-flex;
  align-items: center;
}

.message-page .messageRow .userProfile .profile-header .userInfo p {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #0a0a0a;
  margin: 2px;
}

.message-page .messageRow .userProfile .profile-header .userInfo p.time {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin: 2px;
  color: #7e7e7e;
}

.message-page .messageRow .userProfile .profile-body ul {
  list-style: none;
  padding: 0;
}

.message-page .messageRow .userProfile .profile-body ul li {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  background: transparent;
  color: #0a0a0a;
}

ul.template-types>li>input[name="templateType"] {
  position: relative;
  top: 2px;
}

.message-page .messageRow .userProfile .profile-body ul .listHolder {
  border-bottom: 1px solid #d9d9d9;
  padding: 20px 16px;
}

.message-page .messageRow .userProfile .profile-body ul .listHolder.active {
  background: var(--third-color);
}

.message-page .messageRow .userProfile .profile-body ul li .arrowIcon {
  width: 32px;
  height: 32px;
  background: transparent;
  /* border: 1px solid rgba(63, 96, 142, 0.2); */
  border-radius: 7.11111px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.message-page .messageRow .userProfile .profile-body .addTask {
  padding: 12px 30px;
}

.message-page .messageRow .userProfile .profile-body .addTask .taskDiv {
  background: #ffffff;
  box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  border: 1px solid rgba(63, 96, 142, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 19px;
}

.message-page .messageRow .userProfile .profile-body .addTask .taskDiv p {
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 11.1408px;
  line-height: 15px;
  color: #52594d;
}

.popover.sidebar-popover {
  z-index: 9999;
}

.message-page .messageRow .messageRight .message-input .inputRow {
  position: relative;
  padding: 12px 35px 0px;
  background-color: #fff;
}

.message-page .messageRow .messageRight .message-input .userListCard {
  background: #fff;
  border: 1px solid #d2d6da;
  border-radius: 4px;
  width: 250px;
  position: absolute;
  z-index: 12;
  bottom: 123px;
  overflow: auto;
  height: 176px;
}

.message-page .messageRow .messageRight .message-input .userListCard .userListHolder .userList {
  /* font-style: normal; */
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #252f40;
  padding: 11px;
  cursor: pointer;
  overflow: hidden;
}

.message-page .messageRow .messageRight .message-input .userListCard .userListHolder .userList:hover {
  background: #eceff4;
}

.message-page .messageRow .messageRight .message-input .search-inner-section {
  font-size: 12px;
  line-height: 13px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.message-page .messageRow .messageRight .message-input .search-inner-section>img {
  height: 36px;
  width: 36px;
  margin-right: 10px;
  border-radius: 5px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.seperate-line {
  height: 1px;
  background: #BDBDBD;
  width: 100%;
  display: flex;
  margin: 30px 0;
  position: relative;
}

.seperate-line>span {
  position: absolute;
  right: 0;
  left: 0;
  margin: 0 auto;
  font-weight: 100;
  font-size: 12px;
  line-height: 12px;
  top: -6px;
  background: #fff;
  text-align: center;
  width: auto;
  display: inline-table;
  padding: 0 12px;
  color: #7e7e7e;
}

.userProfile .seperate-line>span {
  background: var(--third-color);
}

.userMessage .message-dangerous,
.message-dangerous * {
  text-align: justify;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #7e7e7e;
}

.userMessage .message-dangerous .highlighted {
  color: var(--primary-color);
}

.userMessage .message-dangerous strong,
.userMessage .message-dangerous b {
  font-weight: 700;
}

.userMessage .message-dangerous i {
  font-style: italic;
}

.textarea i {
  font-style: italic !important;
}

.messageListOuter {
  margin: 0px 0px;
}

.popover.emoji-popover {
  background: transparent;
  /* max-height: 80vh; */
  z-index: 999999999;
  padding: 0;
  box-shadow: unset;
  transform: unset !important;
  inset: unset !important;
  right: 35px !important;
  bottom: 65px !important;
  max-width: initial;
}

.popover.emoji-popover.collapse {
  right: 480px !important;
}

.popover .popover-arrow {
  display: none;
}

#editor-container {
  height: 375px;
}

.editor-wrapper {
  position: relative;
}

#emoji-palette {
  bottom: 0;
  top: unset !important;
}

.userListHolder .textarea,
.userListHolder textarea {
  width: 100%;
  height: 18vh;
  resize: unset;
  outline: unset;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  font-weight: 100;
  padding: 15px 30px 40px;
  overflow: auto;
  color: #7e7e7e;
}

.userListHolder.addedMedia .textarea,
.userListHolder.addedMedia textarea {
  height: 14vh;
}

.customButtons {
  /* position: absolute; */
  bottom: 0px;
  right: 110px;
  text-align: right;
  z-index: 1231311;
}

.customButtonsEditor {
  text-align: left;
  z-index: 1231311;
}

.customButtons button,
.customButtons label {
  background: unset;
  border: 0;
  overflow: unset;
  margin: 0;
  padding: 10px;
  cursor: pointer;
}

.message-dangerous p strong,
.message-dangerous p strong em {
  font-weight: 700;
}

.message-dangerous {
  white-space: pre-line;
}

.emoji-popover {
  position: absolute;
  bottom: 60px;
  right: 40px;
}

.mentionHolder {
  width: 248px;
  max-height: 272px;
  background: #ffffff;
  position: static;
  bottom: 0;
  right: 0;
  filter: drop-shadow(0px 20px 27px rgba(0, 0, 0, 0.05));
  overflow: auto;
  padding: 0;
}

.mentionHolder.addedMedia {
  bottom: 136px;
}

.mentionHolder ul,
.mentionHolder ul {
  list-style: none;
  padding: 22px 6px 10px;
  cursor: pointer;
}

.mentionHolder ul li,
.mentionHolder ul li {
  padding: 10px 10px;
  align-items: center;
  display: flex;
}

.mentionHolder ul li .image,
.mentionHolder ul li .image {
  background-position: center;
  padding: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 12px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 3px;
}

.mentionHolder ul li p {
  margin: 0;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #252f40;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.mentionHolder .mentionInner ul li:hover {
  background: #eceff4;
}

.ql-editor p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #252f40;
}

.mentionText {
  background: #eceff4;
}

.ql-mention-list-container.ql-mention-list-container-top,
.ql-mention-list-container.ql-mention-list-container-bottom {
  width: 260px;
  max-height: 230px;
  background: #ffffff;
  bottom: 93px;
  right: 70px;
  filter: drop-shadow(0px 20px 27px rgba(0, 0, 0, 0.05));
  overflow: auto;
  padding: 0;
  top: unset !important;
  left: unset !important;
}

span.mention img {
  display: none;
}

span.mention {
  background: #eceff4;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #252f40;
}

.message-dangerous p span.mention {
  color: #0a0a0a;
  background: unset;
}

.ql-mention-denotation-char {
  display: none;
}

.ql-mention-list-container.ql-mention-list-container-top::-webkit-scrollbar,
.ql-mention-list-container.ql-mention-list-container-bottom::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.ql-mention-list-container.ql-mention-list-container-top::-webkit-scrollbar-track,
.ql-mention-list-container.ql-mention-list-container-bottom::-webkit-scrollbar-track {
  background: #f5f5f5;
  border: 1px solid #d2d6da;
  width: 10px;
}

/* Handle */
.ql-mention-list-container.ql-mention-list-container-top::-webkit-scrollbar-thumb,
.ql-mention-list-container.ql-mention-list-container-bottom::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 24px;
}

/* Handle on hover */
.ql-mention-list-container.ql-mention-list-container-top::-webkit-scrollbar-thumb:hover,
.ql-mention-list-container.ql-mention-list-container-bottom::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd;
}

.ql-snow .ql-editor a {
  background: #eceff4;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  padding: 17px;
  position: relative;
  cursor: pointer;
}

.ql-snow .ql-editor a span {
  display: none;
}

.ql-snow .ql-editor a:hover {
  opacity: 1;
}

.userMessage .message-dangerous p a {
  background: #eceff4;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  padding: 17px;
  position: relative;
  cursor: pointer;
}

.userMessage .message-dangerous p a img:last-child {
  display: none;
}

.userMessage .message-dangerous p a span {
  display: block;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #252f40;
}

.userMessage .message-dangerous p a:hover {
  opacity: 1;
}

.styleMessage img {
  margin-right: 10px;
  cursor: pointer;
}

.mediaInner {
  padding: 11px 26px 0;
  display: flex;
  overflow: auto;
}

.mediaInner::-webkit-scrollbar {
  height: 9px;
}

/* Track */
.mediaInner::-webkit-scrollbar-track {
  background: #f5f5f5;
  border: 1px solid #d2d6da;
  height: 5px;
}

/* Handle */
.mediaInner::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 24px;
}

/* Handle on hover */
.mediaInner::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd;
}

.addMedia:hover {
  opacity: 1;
}

.addMedia {
  display: inline-flex;
  background: var(--primary-color);
  border-radius: 8px;
  align-items: center;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  margin-right: 6px;
  margin-bottom: 10px;
}

.addMedia span.imageView {
  width: 30px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
  background-size: cover;
}

.addMedia .addMediaPadding {
  padding: 17px 14px;
  display: flex;
  align-items: center;
  min-width: 320px;
}

.messageListOuter.ownProperty .addMedia {
  background: transparent;
}

.messageListOuter.ownProperty .addMedia .addMediaPadding{
  border-radius: 8px;
  background: #eceff4;
}

.messageListOuter.highlight .addMedia {
  background: #fff;
}

.addMedia img {
  width: 44px;
}


.addMedia span.extensionName {
  font-size: 15px;
  color: #ffff;
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* .addMedia span {
  font-size: 15px;
  color: #ffff;
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
} */

span.tagName {
  background: #eceff4;
  padding: 1px 5px;
  color: #252f40;
  margin: 0 3px;
}

span.removeImageHolder {
  position: relative;
  display: inline-flex;
}

.addMedia span.fileName {
  color: #252f40;
  word-break: break-all;
  line-height: 18px;
  font-size: 14px;
}

.removeImageHolder .addMedia span.fileName {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  /* This will ensure that long words are broken and do not overflow */
}

span.imagePreviewChat {
  width: 170px;
  height: 80px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  overflow: hidden;
  border-radius: 8px;
  background-position: center;
}

.userProfile span.imagePreviewChat {
  width: 100px;
  height: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  border-radius: 8px;
}

/* .addMedia .imageView {
  display: none;
} */

.message-dangerous .addMedia span.fileName {
  display: contents;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #252f40;
  text-transform: none;
}

.message-dangerous .addMedia img.addMediatextImage {
  display: none;
}

.message-dangerous .addMedia span.extensionName {
  display: none;
}

.message-dangerous .addMedia {
  width: auto;
}

.message-dangerous .addMedia .imageView {
  display: block;
  margin-right: 8px;
}

img.removeMedia {
  position: absolute;
  top: 7px;
  right: 12px;
  width: auto;
  z-index: 9999;
  cursor: pointer;
}

div.typing {
  position: absolute;
  top: -27px;
  left: 20px;
  z-index: 99999;
  font-size: 12px;
  font-weight: 100;
  text-align: justify;
  font-style: italic;
  font-weight: 500;
  background: #fff;
  width: 100%;
  left: 0;
  padding: 3px 21px;
}

.customButtonHolder {
  background: #fff;
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  padding: 0px 72px 0 37px;
  align-items: center;
}

.collapsediv .customButtonHolder {
  padding: 0px 37px 0 37px;
}

.editText {
  background: none;
  border: 0;
  outline: none;
}

.listHolder .commingSoon {
  background: unset;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 43px 0px;
}

.listHolder .commingSoon p {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #0a0a0a;
  margin: 0;
  margin-top: 7px;
}

.listHolder .commingSoon>div {
  text-align: center;
}

.listHolder .accordion-button {
  padding: 0;
}

.listHolder .accordion-button.collapsed .rotateArrow img {
  rotate: unset;
}

.listHolder .accordion-button .rotateArrow img {
  transition: all 0.5s;
  rotate: 90deg;
}

.listHolder li p {
  margin: 0;
  padding-left: 0px;
}

.messageRow .listHolder li p {
  padding-left: 35px;
}

.listHolder li img.icon {
  position: absolute;
  left: 5px;
}

.message-dangerous span.tagName {
  background: unset;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #0a0a0a;
  margin: 0;
}

[contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
  position: absolute;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.387234px;
  color: #7e7e7e;
  background-color: transparent;
}

.hideSearch {
  display: none;
}

.userlistToggle {
  display: none;
}

.message-page .messageRow .messageLeft .userList ul li .messageDetail .tagSomeoneMessage {
  font-size: 17px;
  color: var(--primary-color);
  ;
  width: 16px;
  height: 16px;
  position: absolute;
  align-items: center;
  display: flex;
  justify-content: center;
  bottom: 1px;
  right: 23px;
  font-weight: 700;
}

img.shortFav {
  width: 35px;
}

.userList.chat .titleDesc {
  display: none;
}

.titleDesc h3 {
  font-size: 13px;
  line-height: 13px;
  margin-bottom: 5px !important;
  font-weight: 600;
  color: #363636;
}

.titleDesc p {
  font-size: 12px !important;
  line-height: 18px !important;
  text-align: justify;
  margin-bottom: 5px !important;
  padding-right: 0 !important;
}

.titleDesc.showLess p {
  /* text-overflow: ellipsis !important;
  overflow: hidden !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; */
}


.removeImageHolder img.removeMedia {
  top: 1px;
  right: 7px;
  width: 20px;
}


.react-tabs .react-tabs__tab {
  padding-left: 0;
  padding-right: 0;
  margin-right: 15px;
  color: #252f40;
  opacity: 0.6;
}

.react-tabs .react-tabs__tab--selected {
  border-radius: 0;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  opacity: 1;
}

.react-tabs .react-tabs__tab-list {
  border: 0;
  display: flex;
  justify-content: center;
}

.react-tabs .react-tabs__tab-list li {
  width: auto !important;
}

.react-tabs .react-tabs__tab:focus:after {
  display: none;
}

.react-tabs .react-tabs__tab {
  border-bottom: 3px solid transparent;
}

.react-tabs .react-tabs__tab--selected,
.react-tabs .react-tabs__tab--selected:focus {
  border-bottom: 3px solid var(--primary-color);
}

.productViewRelative{
  position: relative;
}

.productViewEye{
  position: absolute;
  right: 25px;
  top:10px;
}
@media screen and (max-width: 767px) {
  .titleDesc h3 {
    display: none;
  }

  .listHolder li img.icon {
    width: 18px;
  }

  .message-page .messageRow .messageRight .messageHolder .messageList {
    padding: 15px 0;
  }

  .message-page {
    margin-top: 0;
    max-width: 100%;
  }

  .message-page .messageRow {
    height: calc(100vh - 190px);
    top: 0;
    position: relative;
    margin: 0;
    width: 100%;
    margin-bottom: 40px;
    z-index: 123;
  }

  .userListHolder .textarea,
  .userListHolder textarea {
    height: 13vh;
    padding: 15px 15px 40px;
  }

  .openDashboard {
    margin-top: 10px;
  }

  .message-page .messageRow .messageRight .messageHeader {
    padding: 14px 18px;
  }

  .message-page .messageRow .messageRight {
    border-radius: 20px;
    /* overflow: auto; */
  }

  .message-page .messageRow .messageRight .messageHeader .openDashboard {
    justify-content: space-between;
    padding-top: 10px;
  }

  .message-page .messageRow .messageRight .messageHolder {
    height: calc(80vh - 229px);
  }

  .message-page .messageRow .messageRight .messageHolder.addedMedia {
    height: calc(80vh - 331px);
  }

  .customButtonHolder {
    padding: 0px 23px 0px 23px;
  }

  .styleMessage img {
    margin-right: 5px;
    height: 10px;
  }

  .message-page .customButtons button,
  .message-page .customButtons label {
    padding-top: 0;
    padding-bottom: 0;
  }

  .message-page .customButtons button img,
  .message-page .customButtons label img {
    height: 15px;
  }

  .EmojiPickerReact button.epr-emoji .epr-emoji-img {
    height: auto;
  }

  .message-page .messageRow .userProfile {
    position: fixed;
    transition: all 0.5s;
    transform: translateX(750px);
    display: block;
  }

  .message-page .messageRow .userProfile.showProfile {
    transform: translateX(0px);
    z-index: 1234569;
    width: 94%;
  }

  .message-page .messageRow .messageRight .messageHolder .messageList .messageListInner .messagebyUser .userName .userMessage {
    width: 270px;
  }

  .e-sign-pdf-name {
    margin-top: 5px;
  }

  .message-page .messageRow .messageLeft {
    /* overflow: hidden; */
    height: 100%;
    overflow: auto;
    position: absolute;
    transform: translateX(-750px);
    z-index: 12356789;
    transition: 0.5s;
  }

  .message-page .messageRow .messageLeft.showUserlist {
    transform: translateX(0px);
    width: 94%;
  }

  .message-page .messageRow .messageLeft {
    border-radius: 20px;
  }

  .hideSearch {
    display: block;
  }

  .userlistToggle {
    display: block;
    width: 20px;
    margin-bottom: 10px;
    /* position: fixed;
    top: 20px;
    left: 13px;
    z-index: 999999; */
  }

  .userlistToggle.hasShowList {
    margin-bottom: 0px;
  }

  .userlistToggle i.sidenav-toggler-line {
    transition: all 0.15s ease;
    background: #67748e;
    border-radius: 0.125rem;
    position: relative;
    display: block;
    height: 2px;
    margin-bottom: 4px;
  }

  .userlistToggle i.fa-xmark {
    font-size: 20px;
    margin-bottom: 0;
  }

  .popover.emoji-popover {
    right: 0px !important;
    bottom: 52px !important;
  }

  aside.EmojiPickerReact.epr-main {
    width: auto !important;
  }

  .mentionHolder {
    bottom: 121px;
  }

  .mentionHolder.addedMedia {
    bottom: 183px;
  }
}

@media screen and (max-width: 767px) and (orientation: landscape) {
  .message-page .messageRow .messageRight .messageHeader {
    display: flex;
  }

  .message-page .messageRow .messageRight .messageHolder {
    height: calc(80vh - 219px);
  }

  .userListHolder .textarea,
  .userListHolder textarea {
    height: 24vh;
  }

  .customButtonHolder {
    bottom: 13px;
    display: block;
    right: 0;
    width: auto;
  }

  .customButtonsEditor {
    text-align: right;
  }

  .mentionHolder {
    bottom: 91px;
    max-height: 147px;
  }
}

@media screen and (min-width: 767px) and (max-width: 895px) {
  /* .message-page .messageRow .messageRight .messageHeader {
    display: block;
  } */

  .userListHolder .textarea,
  .userListHolder textarea {
    height: 13vh;
  }
}

@media screen and (min-width: 896px) and (max-width: 968px) {

  .userListHolder .textarea,
  .userListHolder textarea {
    height: 22vh;
  }

  .message-page .messageRow .messageRight .messageHolder {
    height: calc(80vh - 225px);
  }
}