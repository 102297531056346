@font-face {
  font-family: "Just";
  src: url("../public/assets/fonts/JUST Sans Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Just-M";
  src: url("../public/assets/fonts/JUST Sans Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Just-B";
  src: url("../public/assets/fonts/JUST Sans Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Just-EB";
  src: url("../public/assets/fonts/JUST Sans ExBold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

:root {
  --primary-color: #f3c258;
  --secondry-color: #0a0a0a;
  --third-color: #fff7de;
}

* {
  font-family: "Just";
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  letter-spacing: 0.4px !important;
}

body {
  font-family: "Just";
  font-weight: normal;
}

p {
  font-weight: normal;
}

/* b,
strong {
  font-family: 'Just-B';
  font-weight: normal;
} */

a,
a:hover {
  color: var(--primary-color);
}

a:hover {
  opacity: 0.7;
}

.navbar-vertical .navbar-nav .nav-item .nav-link .icon i {
  color: #0A0A0A !important;
}

.navbar-vertical.navbar-expand-xs .navbar-collapse {
  display: block;
  overflow: auto;
  height: auto;
  float: left;
  width: 100% !important;
}

.phil-text {
  text-transform: capitalize;
}

.navbar-vertical .navbar-nav>.nav-item .nav-link.active .icon {
  background: linear-gradient(90.89deg,
      var(--secondry-color) 0.3%,
      var(--secondry-color) 95.3%) !important;
  box-shadow: 0px 10px 5px rgba(0, 0, 0, 0.03) !important;
  border-radius: 8px;
}

.custom-scroll {
  scrollbar-color: rgba(255, 255, 255, 0.1);
  scrollbar-width: 6px;
  /* overflow-scrolling: touch; */
  overflow: auto;
  overscroll-behavior: none none;
  -webkit-overflow-scrolling: touch;
}

body.custom-scroll {
  overflow-x: hidden !important;
}

.custom-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  transition: 0.2s linear;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.custom-scroll::-webkit-scrollbar-button {
  display: none;
}

.sidenav {
  z-index: 121 !important;
  padding-right: 0 !important;
}

.modal-header {
  border: 0;
}

.modal-footer {
  padding: 0;
  border: 0;
}

.moddal-width .modal-dialog {
  max-width: 1000px;
}

.moddal-primary .modal-dialog {
  max-width: 944px;
}

.edit-information-width .modal-dialog {
  max-width: 944px;
}

.delete-information-width .modal-dialog {
  max-width: 720px;
}

.capitalTable th {
  border: 0;
}

.capitalTable td {
  width: 23.1% !important;
  border: 0;
}

.card-formalize {
  border-radius: 1rem !important;
  background: var(--secondry-color);
}

.card-body ul.list-group li.activity-list {
  width: 100%;
}

.card-body ul.list-group li.activity-list .activity-inner {
  width: 33.5%;
}

.card-btn,
.card-btn:hover {
  color: var(--secondry-color);
}

.carousel-control-prev,
.carousel-control-next {
  width: 5%;
}

/* .capital-head {
  position: absolute;
  top: -7px;
} */

.amendment-progress-button {
  margin: 0;
  text-transform: unset;
}

/* .amendment-progress-button:hover {
  transform: translateY(-50%) !important;
} */

.table-responsive table tr {
  position: relative;
}

.upgrad-modal-width .modal-header {
  padding: 20px 20px 0;
}

.upgrad-modal-width .modal-body {
  padding: 0 40px 10px;
}

.upgrad-modal-width ul {
  padding: 0;
}

.form-switch .form-check-input:checked {
  background: linear-gradient(145.53deg,
      var(--primary-color) 3.46%,
      #f5b43f 165.48%,
      #baad4c 223.28%,
      rgba(255, 255, 255, 0) 223.3%);
  /* background: var(--secondry-color); */
}

.col-border {
  border-right: 1px solid rgba(82, 89, 77, 0.3);
}

.card-header .btn.payment-text-invoice.dropdown-toggle {
  background-color: #fff;
  border: #fff;
}

.card-header .btn.payment-text-invoice.dropdown-toggle:focus {
  box-shadow: 0 3px 5px -1px rgb(0 0 0 / 9%), 0 2px 3px -1px rgb(0 0 0 / 7%);
}

.navbar-vertical .navbar-nav .nav-item .nav-link.active .icon i {
  color: #ffffff !important;
}

.shareCapital-width .modal-dialog {
  max-width: 648px;
}

.dropdown-icon,
.dropdown-icon:hover,
.dropdown-icon:active,
.dropdown-icon:focus {
  background: transparent !important;
  box-shadow: none !important;
  padding: 0;
  margin: 0;
  border: 0;
}

.learn-text {
  cursor: pointer;
}

/* .modal {
  top: 20px;
} */

.input-group.search-space .form-control {
  border: 1px solid #d9d9d9;
}

.input-group.search-space .form-control:focus:focus {
  color: #495057;
  background-color: #fff;
  border-color: var(--primary-color) !important;
  outline: 0;
  box-shadow: none;
}

.input-group.search-space .input-group-text.text-body {
  border: 0;
  margin: 0;
  background: transparent;
}

.navbar-nav .dropdown-icon.dropdown-toggle.btn.btn-primary::after {
  content: none;
}

.content-text-payment ul .table-heading.divider-head {
  margin: 0 0 5px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #9e9e9e;
}

.navbar-nav .dropdown-icon.dropdown-toggle.btn.btn-primary i {
  color: #67748e !important;
  font-size: 18px;
  position: absolute;
  top: 3px;
  left: 0;
}

.navbar-nav .dropdown-icon.dropdown-toggle.btn.btn-primary:hover {
  transform: none;
}

.errorMessage,
.importModal .header p span.errorMessage {
  color: #cc0000;
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  font-family: 'Just';
}

.btn-outline-primary.payment-invoice {
  color: white;
  background: #385f8a;
  border: none;
}

.upload-img {
  position: relative;
  padding: 0px 0px 258px;
  background-color: var(--primary-color);
  border-radius: 8px;
  text-align: center;
  background-image: url("../public/assets/img/upload-cloud.png");
  background-repeat: no-repeat;
  background-position: center center;
}

.drag-drop-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #0a0a0a;
}

.manage-share-text a.privacy {
  text-decoration: underline;
}

.upload-btn {
  padding: 0px 5px;
}

.taskInput {
  width: 100%;
  border: 1px solid #BDBDBD;
  border-radius: 8px;
  padding: 6px 8px;
}

.verification-icon {
  position: relative;
}

.verification-icon img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.annually-lable {
  padding-right: 8px;
}

.custom-modal .modal-body .form-group.form-margin .col-form-label.company-text {
  margin: 0 0 20px;
  padding: 0;
}

.custom-modal .modal-body p.edit-description,
.custom-modal .modal-body ul.edit-description,
.custom-modal .modal-body ol.edit-description {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 24px;
  color: #363636;
}

.custom-modal .modal-body span.edit-description {
  font-size: 14px;
  color: #363636;
  line-height: 24px;
}

p.edit-description-adv {
  margin-bottom: 20px;
  font-size: 14px;
  color: #363636;
  line-height: 24px;
  font-family: 'Just';
  font-weight: normal;
}

.custom-modal .modal-body p.edit-description:last-child {
  margin-bottom: 0;
}

.custom-modal .modal-body .edit-title {
  margin-bottom: 15px;
  font-size: 20px;
  line-height: 24px;
  color: #0a0a0a;
  font-family: 'Just-B';
  font-weight: normal;
}

.custom-modal.add-new-member .modal-body .edit-title {
  font-family: 'Just-M';
}

.custom-modal .modal-body h6.edit-subtitle {
  margin: 10px 0 15px;
  font-size: 14px;
  line-height: 18px;
  color: #0a0a0a;
  font-family: 'Just-M';
  font-weight: normal;
}

.custom-modal .modal-body .edit-title.confirmed-title {
  font-family: 'Just-B';
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #0a0a0a;
}

.right-side-row .business-activities {
  font-weight: 400;
}

.address-head {
  font-family: "Just";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: #52594d;
}

.register-col .form-group {
  margin-bottom: 5px;
}

.modal-body {
  padding-left: 40px;
  padding-right: 40px;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #363636;
}

.signIn-back {
  background-image: url("../public/assets/img/kimbocorp_site.png");
  background-size: auto 100%;
  background-position: center right;
  background-repeat: no-repeat;
}

.google-login {
  background: #ffffff;
  border: 1px solid #8392ab !important;
  border-radius: 8px;
  box-shadow: none;
  font-family: "Just";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #52594d !important;
}

.google-login img {
  margin-right: 5px;
}

.display-account ul li a.nav-link.active .icon .active-icon,
.display-account ul li div.nav-link.active .icon .active-icon {
  display: block;
}

.display-account ul li a.nav-link .icon .active-icon,
.display-account ul li div.nav-link .icon .active-icon {
  display: none;
}

.display-account ul li a.nav-link.active .icon .normal-icon {
  display: none;
}

.navbar-vertical .navbar-brand>img,
.navbar-vertical .navbar-brand-img {
  height: auto !important;
  max-height: none;
  filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.03));
  border-radius: 5px;
  cursor: pointer;
}

.sidenav-header .navbar-brand img.navbar-brand-img {
  float: left;
  width: 40px;
  opacity: 0.5;
}

.sidenav-header .navbar-brand img.navbar-brand-img.active {
  opacity: 1;
}

.close-button {
  z-index: 9999;
}

.table-responsive table.table-sharholder tr {
  width: 100%;
}

.table-responsive table.table-sharholder tr td,
.table-responsive table.table-sharholder tr th {
  vertical-align: middle;
  /* width: auto; */
  width: 20%;
  text-transform: initial;
}

.table-responsive table.table-sharholder tr td:nth-child(2),
.table-responsive table.table-sharholder tr th:nth-child(2) {
  width: 20%;
}

.table-responsive table.table-sharholder tr td:nth-child(3),
.table-responsive table.table-sharholder tr th:nth-child(3) {
  width: 10%;
}

.table-responsive table.table-sharholder tr td.action-icons {
  width: 100%;
}

/* .table-responsive table.table-sharholder tr td{
  padding-left: 1.5rem;
  padding-right: 1.5rem;
} */

.table-responsive table.table-sharholder tr th:first-child {
  /* width: 25% !important; */
  text-align: left;
}

.amendment-progress-button-second {
  margin: 0;
  text-transform: unset;
}

.custom-tooltip .tooltip-arrow {
  display: none !important;
}

.custom-tooltip .tooltip-inner {
  max-width: 280px;
  background: #ffffff;
  box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 16px;
}

.custom-tooltip .tooltip-inner h4 {
  text-align: left;
  font-family: "Just";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #52594d;
}

.custom-tooltip .tooltip-inner p {
  font-family: "Just";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #52594d;
  text-align: left;
  margin: 0;
}

.sidenav-header .sidebar-dropdown {
  background: var(--primary-color);
  border-radius: 5px;
  padding: 8px 10px;
  float: left;
  cursor: pointer;
}

.popover .dropdown-menu-inner {
  float: left;
  width: 100%;
  padding: 8px 0;
}

.popover .dropdown-menu-inner .business-trend {
  margin: 3% !important;
  float: left;
  line-height: 36px;
  width: 75%;
  white-space: nowrap;
  overflow: hidden;

}

.popover .dropdown-menu-inner .business-trend p {
  font-size: 12px;
  font-family: 'Just-M';
  text-overflow: ellipsis;
  margin-bottom: 0;
}

.popover .dropdown-menu-inner .business-trend p.designation {
  color: #BDBDBD;
  font-family: 'Just';
}

.popover .dropdown-menu-inner {
  font-family: "Just";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #52594d;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 3px 15px;
}

.sidebar-new.sidebar-popover.popover .dropdown-menu-inner:hover {
  background: #F5F5F5;
}

body.custom-scroll.prevent-scroll {
  overflow: hidden;
}

.popover .dropdown-menu-inner.active {
  background: var(--third-color);
  color: #52594d;
}

.popover .dropdown-menu-inner:hover {
  color: #52594d;
}

.popover .dropdown-menu-inner img {
  float: right;
  filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.2));
  border-radius: 5px;
  width: 22px;
}

.popover.sidebar-popover {
  background: #fff;
  padding: 0px 0 0px;
  top: 30px !important;
  max-height: 80vh;
  overflow: auto;
}

.sidebar-popover .popover-arrow {
  display: none;
}

.popover .dropdown-head {
  background: #fff;
  font-family: "Just";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #0a0a0a;
  padding: 14px 20px;
  border-bottom: 1px solid #BDBDBD;
}

.company-menu-section {
  overflow: hidden;
}

.company-menu-section.pendingCompanyList {
  background: #F5F5F5;
  margin-top: 10px;
}

.popover .company-menu-section.pendingCompanyList .dropdown-head,
.popover .company-menu-section.pendingCompanyList .dropdown-menu-inner .business-trend p.designation {
  color: #FE8900
}

.popover .company-menu-section .dropdown-head {
  background: transparent;
  margin-bottom: 3px;
}

.floatingCompanyMenuHolder.active {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  z-index: 321320;
}

.floatingCompanyMenuHolder .floatingCompanyMenu {
  position: absolute;
  background: #ffffff;
  width: 0px;
  top: 82px !important;
  left: auto;
  right: 50px;
  /* Set the initial position */
  z-index: 321321;
  /* This timing applies on the way OUT */
  transition-timing-function: ease-in;
  /* Quick on the way out */
  transition: 0.6s;
  opacity: 0;
  /* Start hidden */
  visibility: hidden;
  /* Prevent clicks when hidden */
  transform: translateX(100%);
  /* Slide out of view */
}

.floatingCompanyMenuHolder.active .floatingCompanyMenu {
  /* right: 50px; */
  z-index: 321321;
  opacity: 1;
  /* Fully visible */
  visibility: visible;
  /* Allow interactions */
  transform: translateX(0);
  min-width: 335px;
  width: 280px;
}


ul.navbar-nav li.nav-item .header-dropdown {
  background: #ffffff;
  box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 8px 15px;
}

ul.navbar-nav li.nav-item .header-dropdown::before {
  content: none;
}

ul.navbar-nav li.nav-item .header-dropdown .drop-item-inner {
  font-family: "Just";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #363636;
  padding-left: 0;
  padding-right: 0;
}

ul.navbar-nav li.nav-item .header-dropdown .dropDivider {
  border: 1px solid #bdbdbd;
}

ul.navbar-nav li.nav-item .header-dropdown .drop-divider-inner {
  font-family: "Just";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #bdbdbd;
  padding-left: 0;
  padding-right: 0;
  display: flex;
}

ul.navbar-nav li.nav-item .header-dropdown .drop-divider-inner img {
  margin-left: auto;
}

.file-conditions {
  font-family: "Just";
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #7e7e7e;
  display: block;
  margin-top: 10px;
}

.addressModal {
  top: 0;
}

.addressModal .modal-dialog {
  max-width: 780px;
}

.form-group {
  margin-bottom: 0;
}

.promoter-description {
  margin-bottom: 20px;
  font-family: "Just";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #363636;
}

.modal-title {
  font-family: "Just";
  font-style: normal;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  color: #252f40;
  width: 100%;
}

.locate-icon {
  background: #9e9e9e;
  border-radius: 4.25974px;
}

.location-text b {
  font-family: "Just";
  font-style: normal;
  font-weight: 700;
  font-size: 14.9091px;
  line-height: 17px;
  color: #bdbdbd;
}

.sidebar-popover .dropdown-menu-inner.add-Company-btn {
  text-align: center;
  border-radius: 3px;
  margin-top: 10px;
}

.sidebar-popover .dropdown-menu-inner.add-Company-btn .company-add img {
  float: none;
}

.sidebar-popover .dropdown-menu-inner.add-Company-btn .company-add p {
  font-family: "Just";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--secondry-color);
  width: auto;
  margin: 0;
}

/* .add-modal .modal-header .close-button {
  position: unset;
} */

.add-modal .modal-body {
  padding-top: 0;
}

.upload-holder .upload-btn {
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  padding: 7px 49px;
  font-family: "Just";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  color: var(--primary-color);
}

.upload-holder p {
  margin-bottom: 10px !important;
}

/* .bussinessTabel td {
  width: 30.2% !important;
} */

.bussinessTabel td {
  width: 100% !important;
}

.upload-here-img {
  padding: 22px 30px;
}

.upload-here-img .upload-btn {
  display: flex;
  padding: 0;
  align-items: center;
}

.upload-here-img .upload-btn h5 {
  float: none;
  padding-top: 5px !important;
}

.upload-here-img .upload-btn .load-btn {
  padding: 5px 19px;
  margin-right: 0px !important;
  margin-left: 24px !important;
}

.table-sharholder .text-row-color tr td.file-name-row {
  width: 60% !important;
}

.upload-task {
  position: absolute;
  top: 50%;
  margin: 0 auto;
  left: 0;
  right: 0;
  transform: translateY(-65%);
}

.upload-here-img-task .upload-img {
  padding: 0px 0px 170px;
}

.upload-here-img-task .upload-img .drag-drop-text {
  font-family: "Just";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  max-width: 200px;
  margin: 0 auto;
}

.upload-here-img-task .upload-img .fileUpload {
  bottom: 10px;
}

.table-sharholder tr td h6 img {
  width: 40px;
  margin-right: 10px;
}

.status-dropdown .dropdown-toggle,
.status-dropdown .dropdown-toggle:hover,
.status-dropdown .dropdown-toggle:focus,
.status-dropdown .dropdown-toggle:active {
  border: 0 !important;
  background: transparent !important;
  font-family: "Just";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #363636;
  box-shadow: none !important;
  padding: 0 10px 6px;
  margin: 0;
}

hr.horizontal.dark.mt-0 {
  float: left;
  width: 100%;
}

.sidenav.navbar.navbar-vertical #sidenavCard {
  width: 100%;
  float: left;
  background: linear-gradient(90.89deg,
      var(--secondry-color) 0.3%,
      var(--secondry-color) 95.3%);
  border-radius: 20px;
}

.notify-icon {
  position: absolute;
  background: #f5222d;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  top: 3px;
  left: 5px;
  padding: 0;
}

.notify-icon b {
  font-family: "Just";
  font-style: normal;
  font-weight: 700;
  font-size: 11.7px;
  line-height: 13px;
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* .input-text.heading {
  margin: 0;
} */

.errorDiv {
  margin-top: -10px;
  margin-bottom: 10px;
}

.card-formalize span.a {
  font-weight: 500 !important;
  color: #ffffff !important;
  cursor: pointer;
  font-size: 14px !important;
  line-height: 16px !important;
  margin-top: 24px;
  padding-bottom: 0;
}

.input-text.heading+img {
  width: 80px;
}

.business-font.main-company-logo img {
  width: 60px;
}

.webkitScroll::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

/* Track */
.webkitScroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.webkitScroll::-webkit-scrollbar-thumb {
  background: var(--primary-color);
}

/* Handle on hover */
.webkitScroll::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}

.fileUpload {
  position: absolute;
  bottom: 30px;
  left: 0;
  margin: 0 auto;
  padding: 0 50px;
  width: 100%;
}

.company-add {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 22px;
  width: 100%;
}

.form-control.recipient-file {
  cursor: pointer;
}

.form-control.recipient-file span.text {
  width: 95px;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  margin-right: 9px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.form-control.recipient-file .uploadbtn {
  display: inline-block;
  vertical-align: middle;
}

.tableHeadings th {
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 24px;
  color: #7e7e7e;
}

.shareData {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #7e7e7e;
  font-family: 'Just';
}

.shareData img {
  width: 13px;
}

.shareCreateDate {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #363636;
  font-family: 'Just';
}

.navbar-brand-name {
  float: right;
  filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.2));
  border-radius: 5px;
  width: 35px;
  text-align: center;
  font-size: 21px;
  color: #252f40;
  background: #ffff;
  text-transform: uppercase;
}

.navbar-header-icon {
  float: left;
  cursor: pointer;
  filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.03));
  border-radius: 5px;
  width: 35px;
  text-align: center;
  font-size: 21px;
  color: #252f40;
  background: #ffff;
  opacity: 0.5;
  padding: 2px 0;
}

.navbar-header-icon.active {
  opacity: 1;
}

.navbar-header-img {
  margin-right: 10px;
}

.navbar-header-img:last-child {
  margin-right: 0;
}

.company_logo {
  background: #fff;
  border-radius: 10px;
  filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.08));
  text-align: center;
  width: 35px;
  font-size: 21px;
  padding: 5px 0;
}

.sidebar-popover.mainsidebarsec {
  top: 0 !important;
  padding: 0;
  width: 100%;
  border-radius: 0;
}

.sidebar-popover.mainsidebarsec .dropdown-menu-inner.add-Company-btn {
  margin-top: 0;
  border-radius: 0;
}

.taskInput {
  resize: none;
}

.title-input {
  align-items: center;
  /* padding: 14px 39px 0; */
}

.title-input input {
  width: 100%;
  border: 1px solid #BDBDBD;
  border-radius: 8px;
  padding: 6px 8px;
}

.task-desc {
  font-weight: 500;
  color: #0a0a0a;
}

.disagree-button.agree-button {
  background: #BDBDBD !important;
  border: 1px solid #7e7e7e !important;
}

.react-datepicker__header {
  background-color: var(--primary-color) !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: var(--primary-color) !important;
}

.react-datepicker__header .react-datepicker__current-month,
.react-datepicker__header .react-datepicker-time__header,
.react-datepicker__header .react-datepicker-year-header,
.react-datepicker__header .react-datepicker__day-name,
.react-datepicker__header .react-datepicker__day,
.react-datepicker__header .react-datepicker__time-name {
  color: #0c0b0b !important;
  font-weight: 500 !important;
}

.react-datepicker__navigation-icon::before {
  border-color: #000 !important;
}

.react-datepicker__navigation:hover *::before {
  border-color: #000 !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
  border-bottom-color: #65d1b1;
}

.react-datepicker__header .react-datepicker__day-name {
  font-weight: 500;
}

.react-datepicker__header .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select select {
  background: rgb(255 255 255);
  border: 1px solid #e7e7e7;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 1%);
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgba(102, 112, 133, 0.8);
  height: 25px;
  padding-left: 10px;
}

.react-datepicker-wrapper {
  width: auto !important;
}

/* .activity_wrapper {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  max-width: 180px;
} */

.activity_wrapper {
  word-break: break-word;
  white-space: break-spaces;
  margin-bottom: 0;
}

.userImage {
  width: 47px;
  height: 47px;
  border-radius: 50px;
}

.user_profile.business-image {
  width: 74px !important;
  height: 74px !important;
}

.user_profile img {
  width: 100%;
  height: 100%;
  filter: drop-shadow(0px 0px 5px rgb(221, 216, 216));
  border-radius: 15px;
}

.connect-btn {
  padding: 3px 12px;
}

.otp_modal .modal-dialog {
  width: auto;
  position: absolute;
  top: 39%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.otp_modal .modal-body {
  padding: 15px 0 20px !important;
}

.otp_modal .modal-content {
  box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  text-align: center;
  max-width: 410px;
}

.otp_modal .modal-header {
  padding: 10px 20px 0;
  display: block;
}

.otp_modal .modal-header img {
  margin-bottom: 20px;
}

.otp_modal .modal-header h3 {
  font-weight: 700 !important;
  font-size: 18px;
  line-height: 21px;
  color: #252f40 !important;
}

.otp_modal .modal-header p {
  margin: 0;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 18px;
  text-align: center;
  color: #52594d;
  margin-bottom: 15px;
}

.otp_modal input.otp_input {
  background: #ffffff;
  border: 1px solid #bdbdbd;
  border-radius: 6px;
  width: 34px !important;
  height: 42px !important;
}

.otp_modal .modal-footer {
  padding: 0 0 0;
  display: block;
  text-align: center;
}

.otp_modal .modal-footer button {
  margin: 0;
}

.otp_input,
.otp_input:focus,
.otp_input:focus-visible {
  border-left: 0;
  border-right: 0;
  border-top: 0;
}

.otp_input:last-child {
  margin: 0 !important;
}

.otp_handle {
  cursor: pointer;
}

.resend_otp {
  display: block !important;
  justify-content: center !important;
  margin-bottom: 15px;
}

.resend_otp span {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #52594d;
}

.resend_otp button {
  background: transparent;
  border: 0;
  box-shadow: unset;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #52594d;
  padding: 0 10px;
}

.modal-card-task .text-status {
  padding-bottom: 10px;
}

.form-select {
  padding-right: 40px;
  padding: 0.5rem 0.75rem;
  font-size: 14px;
  line-height: 24px;
  /* width: 30%;
  min-width: 160px;
  padding-bottom: 0; */
}

.attachmentsItems p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  color: #9e9e9e;
  opacity: 0.6;
  position: relative;
}

span.remove {
  cursor: pointer;
  margin-left: 10px;
  font-size: 12px;
  color: red;
}

.red {
  color: red;
}

.terms {
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  line-height: normal;
  text-decoration-line: underline;
  color: var(--secondry-color);
}

.terms:hover {
  color: var(--secondry-color) !important;
}

.navbar-vertical .navbar-nav .nav-link.active {
  border-radius: 8px;
  background-color: var(--primary-color) !important;
  box-shadow: none;
}

.navbar-vertical .navbar-nav .nav-link .icon {
  border-radius: 8px;
  background-color: var(--primary-color) !important;
  box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.03);
  background-image: none !important;
}

.navbar.nav-back {
  background: var(--secondry-color);
}

.btn-info.focus,
.btn-info:focus,
.btn.bg-gradient-info.focus,
.btn.bg-gradient-info:focus {
  color: var(--secondry-color);
}

.userGuide {
  background: var(--primary-color);
  border-radius: 8px;
  padding: 7px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  cursor: pointer;
}

.userGuide img {
  margin-right: 5px;
}

.userGuide p {
  font-size: 11px;
  font-style: normal;
  font-weight: normal;
  margin: 0;
  color: var(--secondry-color);
  line-height: 16px;
}

.guide-modal .modal-dialog {
  max-width: 740px;
}

.guide-modal .modal-dialog .modal-content {
  padding: 0;
  overflow: hidden;
  border-radius: 20px;
}

.guide-modal .guideHeader {
  background: var(--primary-color);
}

.guide-modal .guideHeader p {
  color: var(--secondry-color);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  margin: 0;
  padding: 28px 80px;
  font-family: 'Just-M';
}

.guide-modal .bodyHeader {
  border-bottom: 1px solid #bdbdbd;
  padding: 10px;
}

.guide-modal .bodyHeader p {
  color: #0a0a0a;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}

.guide-modal .bodyHeader span {
  color: #0a0a0a;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
  line-height: 24px;
}

.guide-modal .bodyHeader span i {
  margin-right: 5px;
}

.guide-modal .guideHeader i {
  position: absolute;
  color: var(--secondry-color);
  top: 24px;
  right: 24px;
  cursor: pointer;
}

.guide-modal .guideBody {
  max-height: 379px;
  padding: 0px 24px;
  overflow: auto;
}

.guide-modal .guideBody .guideContent {
  padding: 28px 0;
  border-bottom: 1px solid #bdbdbd;
  transition: all 5s ease;
}

.guide-modal .guideBody .guideContent.stepsRow {
  min-height: 375px;
}

.guide-modal .guideBody .guideContent:last-child {
  border: 0;
}

/* .guide-modal .guideBody .guideContent .imgHolder {
  background-size: 100% auto;
  background-position: center;
  background-color: #9e9e9e;
  box-shadow: 0px 20px 27px 0px rgba(0, 0, 0, 0.05);
  background-repeat: no-repeat;
  max-height: 160px;
} */

.guide-modal .guideBody .guideContent .imgHolder img {
  width: 100%;
}

.guide-modal .guideBody .guideContent .contentHolder h5 {
  color: #0a0a0a;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  font-family: 'Just-B';
  margin-bottom: 1rem;
}

.guide-modal .guideBody .guideContent .contentHolder p {
  color: #363636;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  margin-bottom: 2rem;
}

.guide-modal .guideBody .guideContent .contentHolder .stepDesc p {
  color: #363636;
  font-size: 14px;
  line-height: 22px !important;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
}

.guide-modal .bodyFooter {
  background: linear-gradient(0deg, #fff 0%, #fff 100%), #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-top: 1px solid #bdbdbd;
  padding: 13px;
  text-align: center;
}

.inputHolderGuide {
  align-items: center;
  display: flex;
  justify-content: center;
}

.guide-modal .bodyFooter label {
  margin: 0;
  color: #0a0a0a;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  margin-left: 10px;
  cursor: pointer;
}

.guide-modal .bodyFooter button {
  margin: 15px auto 0;
}

.stepPagination {
  border-radius: 8px;
  border: 1px solid var(--primary-color);
  background: #fff;
  color: var(--secondry-color);
  padding: 10px 15px;
  margin-right: 24px;
}

button.stepPagination:disabled,
button.stepPagination[disabled] {
  opacity: 0.5;
}

.stepPagination i {
  color: var(--primary-color);
}

.needHelpHolder {
  position: fixed;
  bottom: 38px;
  right: 25px;
  width: 72px;
  height: 72px;
  background: var(--primary-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 123456;
}

.needHelpHolder img {
  width: 40px;
}

.needHelpHolder i {
  color: var(--secondry-color);
  font-size: 42px;
  font-family: "Just";
}

.askModal {
  position: fixed;
  top: auto;
  left: auto;
  bottom: 93px;
  right: 45px;
  width: auto;
  height: auto;
}

.helpPopover {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 20px 27px 0px rgba(0, 0, 0, 0.05);
  /* min-width: 380px; */
  /* min-height: 365px;
  max-height: 365px; */
  left: -70px !important;
  padding: 24px;
  transition: all 0.2s linear;
  z-index: 123456;
}

.askModal .modal-dialog {
  max-width: 380px;
}

.askModal .modal-content {
  padding: 0;
}

.helpPopover .dropdown-head {
  display: flex;
  align-items: center;
  justify-content: center;
}

.helpPopover .dropdown-head h5 {
  color: #0a0a0a;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  margin: 0;
  text-align: center;
  line-height: 24px;
  font-family: 'Just-B';
}

.helpPopover .dropdown-head i {
  position: absolute;
  right: 25px;
  cursor: pointer;
}

.helpPopover .needHelpMenu {
  padding: 8px 0;
}

.helpPopover .needHelpMenu .askQuestionHolder {
  border-radius: 4px;
  border: 1px solid #bdbdbd;
  background: #fff;
  padding: 15px;
  height: 175px;
  width: 100%;
  resize: none;
  outline: 0;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
}

.helpPopover .needHelpMenu .askQuestionHolder p {
  margin: 0 !important;
  color: #7e7e7e;
  font-size: 14px;
  line-height: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  letter-spacing: -0.387px;
  float: unset;
}

.helpPopover .needHelpMenu .attachFile {
  color: #363636;
  font-size: 14px;
  line-height: 24px;
  font-style: normal;
  font-weight: 400;
  text-decoration-line: underline;
  margin-top: 15px;
  cursor: pointer;
}

.helpPopover .needHelpMenu .attachFile i {
  rotate: -45deg;
  margin-right: 8px;
}

.helpPopover .needHelpMenu .buttonHolder {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 15px;
}

.helpPopover .needHelpMenu .buttonHolder button i {
  margin-right: 10px;
}

.helpPopover .needHelpMenu .buttonHolder .leaveMessage {
  border-radius: 8px;
  border: 1px solid var(--secondry-color);
  background: #fff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07),
    0px 4px 6px -1px rgba(0, 0, 0, 0.12);
  color: var(--secondry-color);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.helpPopover .needHelpMenu .chatWithUS {
  border-radius: 8px;
  background: var(--primary-color);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07),
    0px 4px 6px -1px rgba(0, 0, 0, 0.12);
  color: var(--secondry-color);
  font-size: 14px;
  line-height: 24px;
  font-style: normal;
  font-weight: normal;
  padding: 10px 15px;
  /* display: flex;
  align-items: center; */
  border: 1px solid var(--primary-color);
}

.helpPopover .needHelpMenu .emailSent {
  text-align: center;
  padding: 0px 50px;
}

.helpPopover .needHelpMenu .emailSent h5 {
  color: #252f40;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.553px;
  margin-top: 0;
  margin-bottom: 15px;
}

.helpPopover .needHelpMenu .emailSent p {
  color: #252f40;
  font-size: 14px;
  line-height: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  letter-spacing: -0.387px;
  margin-top: 0;
  margin-bottom: 24px;
}

.addaLoader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 12345;
  content: "";
}

.skeltonSectionGuide {
  display: flex;
}

.skeltonSectionGuide span {
  margin-left: 10px;
}

.articalBtn {
  text-transform: none !important;
}

/* new-payment-ui-css-start */
.paymentPage .segmentHolder {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.paymentPage .segmentHolder .segment {
  border-radius: 4px;
  padding: 8px;
  margin-right: 12px;
  cursor: pointer;
}

.paymentPage .segmentHolder .segment.active {
  background: var(--secondry-color);
}

.paymentPage .segmentHolder .segment p {
  margin: 0;
  color: var(--Dark-Grey, #7e7e7e);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.3px;
}

.paymentPage .segmentHolder .segment.active p {
  color: var(--Pure-White, #fff);
}

.paymentPage .tableHolder {
  width: 100%;
  background: #fff;
  margin-bottom: 20px;
  border-radius: 16px;
  box-shadow: 0px 20px 27px 0px rgba(0, 0, 0, 0.05);
  padding: 0 0 15px;
  height: fit-content;
  overflow: hidden;
  overflow-x: auto;
  border: 1px solid #BDBDBD;
}

.paymentPage .tableHolder.paymentPaidupTableHolder {
  padding: 5px 20px;
}

.paymentPage .tableHolder.paymentPaidupTableHolder .tableHeader tr th:first-child,
.paymentPage .tableHolder.paymentPaidupTableHolder .tableBody tr td:first-child {
  padding-left: 0;
}

/* .paymentPage .tableHolder.paymentPaidupTableHolder .tableBody tr td .planName,
.paymentPage .tableHolder.paymentPaidupTableHolder .tableBody tr td .planType,
.paymentPage .tableHolder.paymentPaidupTableHolder .tableHeader tr th .paidIp-Capital {
  padding-left: 20px;
} */

.paymentPage .tableHolder.paymentPaidupTableHolder .tableBody tr td.activeRow {
  border-radius: 0;
}

.paymentPage .tableHolder.paymentPaidupTableHolder .tableHrsection hr {
  margin: 0;
}

.paymentPage .tableHolder.paymentPaidupTableHolder .tableHrsection {
  padding: 0;
}

.paymentPage .tableHolder table {
  width: 100%;
}

.paymentPage .tableHolder .tableHeader {
  background: #fff;
  border-bottom: 1px solid #BDBDBD;
}

.paymentPage .tableHolder .tableHeader tr th {
  padding: 24px 20px;
  color: #0a0a0a;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  font-family: 'Just-M';
  vertical-align: top;

}

.paymentPage .tableHolder.activityPlan .tableHeader tr th {
  width: 35%;
}

.paymentPage .tableHolder .amendment-progress-button {
  font-size: 10px;
  line-height: 14px;
  padding: 8px 12px;
  width: max-content;
}

.paymentPage .tableHolder .tableBody tr {
  /* border-bottom: 0.5px solid var(--Grey, #bdbdbd); */
}

.paymentPage .tableHolder .tableBody tr td {
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  padding: 20px 20px;
  vertical-align: top;
}

.paymentPage .tableHolder .tableBody tr td .planName {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.paymentPage .tableHolder .tableBody tr td .planName img {
  margin-right: 6px;
}

.paymentPage .tableHolder .tableBody tr td .planName p {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  text-decoration: underline;
}

.paymentPage .tableHolder .tableBody tr td button.tableButton {
  border-radius: 8px;
  background: var(--primary-color);
  color: var(--secondry-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.3px;
  border: 0;
  padding: 10px;
  width: max-content;
}

.paymentPage .tableHolder .tableBody tr td button.active {
  border-radius: 8px;
  border: 1px solid var(--Dark-Grey, #7e7e7e);
  background-color: transparent;
}

.paymentPage .planHolder {
  border-radius: 6px;
  background: var(--IP-Gold, rgba(243, 194, 88, 0.4));
  backdrop-filter: blur(30px);
  padding: 19px;
}

.paymentPage .planHolder .suggestedBtn {
  border-radius: 4px;
  background: var(--secondry-color);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07),
    0px 4px 6px -1px rgba(0, 0, 0, 0.12);
  color: var(--primary-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 12px */
  letter-spacing: -0.27px;
  padding: 8px 14px;
}

.suggestedTextPopup {
  font-size: 14px;
  line-height: 24px;
  color: #0A0A0A;
  font-family: 'Just';
}

.paymentPage .planHolder .planName h4 {
  color: var(--secondry-color);
  font-size: 32px;
  font-style: normal;
  font-weight: normal;
  line-height: 42px;
  margin: 0px;
  padding: 24px 0px;
  font-family: 'Just-EB';
}

.paymentPage .planHolder .planName .planDescription,
.paymentPage .planHolder .planName p,
.paymentPage .planHolder .planName .planDescription p,
.paymentPage .planHolder .planName .planDescription ul,
.paymentPage .planHolder .planName .planDescription ol {
  color: #363636;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}

.paymentPage .planHolder .planName .planDetail .detailInner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
}

.paymentPage .planHolder .planName .planDetail .detailInner p {
  margin: 0;
  color: var(--secondry-color);
  font-size: 16px;
  font-style: normal;
  margin-left: 4px;
  font-family: 'Just-EB';
  line-height: 24px;
}

.paymentPage .planHolder .planName .planDetail .planDesc p {
  color: var(--secondry-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 16.8px */
  margin-top: 5px;
  margin-bottom: 0px;
}

.paymentPage .planHolder .planName .planDetail .planPrice {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.paymentPage .planHolder .planName .planDetail .planPrice p.salePrice {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  margin-right: 8px;
  background: var(--secondry-color);
  padding: 6px 12px;
  border-radius: 20px;
  margin-top: 8px;
}

.paymentPage .planHolder .planName .planDetail .planPrice p.actulaPrice {
  color: rgba(37, 47, 64, 0.4);
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: line-through;
  text-transform: capitalize;
}

.paymentPage .planHolder .planName .planDetail .planSegment {
  border-radius: 6px;
  border: 1px solid var(--IP-Black, #0a0a0a);
  background: rgba(243, 194, 88, 0.2);
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  margin-top: 14px;
}

.paymentPage .planHolder .planName .planDetail .planSegment .segment {
  padding: 10px 0px;
  background: transparent;
  border: 0;
  color: var(--secondry-color);
  text-align: center;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 11.2px */
  width: 90px;
}

.paymentPage .planHolder .planName .planDetail .planSegment .segment.active {
  border-radius: 4px;
  background: var(--secondry-color);
  color: #f5f5f5;
  text-align: center;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 11.2px */
}

.paymentPage .planHolder .planName .upgradePlan {
  padding-top: 90px;
  width: 100%;
}

.paymentPage .planHolder .planName .upgradePlan button.planUpgrade {
  border-radius: 8px;
  background: var(--IP-Yellow, #f3c258);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07),
    0px 4px 6px -1px rgba(0, 0, 0, 0.12);
  width: 100%;
  border: 0;
  padding: 13px;
  color: var(--secondry-color);
  font-size: 14px;
  line-height: 24px;
  /* 14px */
}

.paymentPage .planHolder .planName .upgradePlan button.planUpgrade:disabled {
  opacity: 0.7;
  border: unset !important;
}

.paymentPage .tableBottom,
.filesPage .tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 34px 20px;
}

.tableBottom .filterButton .dropdownButton .select-border {
  border-radius: 4px;
  background-color: #eceff4;
  margin: 0;
  color: #7e7e7e;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  /* padding: 8px 18px; */
}

.paymentPage .tableHolder .tableBody tr td p.price {
  color: #0a0a0a;
  font-size: 14px;
  font-style: normal;
  font-weight: 'JUST-B';
  line-height: 24;
  /* 16px */
  margin: 0;
}

.paymentPage .myinfoCapital {
  margin-top: 10px;
}

.paymentPage .myinfoCapital h3 {
  color: var(--secondry-color);
  font-size: 14px;
  line-height: 24;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  padding: 16px 16px 0;
}

.paymentPage .tableHolder .tableBody tr td button.pendingButton {
  border-radius: 4px;
  border: 1px solid #7e7e7e;
  background: var(--Light-Gray, #eceff4);
  color: #7e7e7e;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.332px;
  padding: 6px 10px;
  width: max-content
}

.paymentPage .tableHolder .tableBody tr td button.pendingButton.btn-identify-2 {
  background: var(--third-color);
  color: #363636;
  border-color: var(--primary-color);
}

.paymentPage .tableHolder .tableBody tr td p.desc {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  margin: 0;
}

.paymentPage .tableHolder .tableBody tr td p.desc.descBold {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
  margin: 0;
  text-decoration: none;
}

.paymentPage .tableHolder .tableBody tr td p.date {
  color: #7e7e7e;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  margin: 0;
}

.paymentPage .paymentHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.paymentPage .paymentHeader .addCapital {
  border-radius: 6px;
  border: 0.5px dashed var(--secondry-color);
  background: #fff;
  color: var(--secondry-color);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  letter-spacing: -0.332px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
}

.paymentPage .paymentHeader .addCapital img {
  margin-right: 7px;
  width: 15px;
}

.bankPage h4.header {
  color: var(--secondry-color);
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  margin-bottom: 16px;
  font-family: 'Just-B';
}

.bankPage .outerRow {
  padding: 16px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 20px 27px 0px rgba(0, 0, 0, 0.05);
  width: fit-content;
  width: 100%;
}

.bankPage .planDetail {
  border-radius: 6px;
  background: #0a0a0a;
  padding: 16px;
  height: 100%;
  position: relative;
}

.bankPage .planDetail .planName {
  border-radius: 4px;
  border: 0.4px solid #fff;
  padding: 16px;
}

.bankPage .planDetail .planName h4 {
  color: var(--primary-color);
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  margin: 0;
  line-height: 24px;
  font-family: 'Just-M';
}

.usdTransfer {
  padding-Top: 24px;
}

.bankPage .planDetail .planName .planPrice.usdTransferAnnual {
  padding: 24px 0px 0px;
}

.bankPage .planDetail .planName .planPrice {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}

.bankPage .planDetail .planName .planPrice p {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  margin: 0;
  font-family: 'Just';
}

.bankPage .planDetail .planTerms {
  margin-top: 24px;
  padding: 12px;
}

.bankPage .planDetail .planTerms p {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  margin-bottom: 10px;
}

.bankPage .planDetail .planTerms ol li {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  margin-bottom: 10px;
}

.bankPage .planDetail .planFooter {
  display: flex;
  align-items: center;
  margin-top: auto;
  /* justify-content: space-between; */
  position: absolute;
  bottom: 12px;
  width: 90%;
}

.bankPage .planDetail .planFooter p {
  margin: 0 10px 0 0;
}

.bankPage .planDetail .planFooter p a {
  color: #eceff4;
  font-size: 10px;
  font-style: normal;
  font-weight: normal;
  line-height: 14px;
  text-decoration-line: underline;
}

.bankPage .userDetail p.header {
  color: var(--secondry-color);
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  margin-bottom: 2rem;
}

.bankPage .innerDetail {
  border-bottom: 1px solid #bdbdbd;
}

.bankPage .innerDetail .inputHolder {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
}

.bankPage .innerDetail .inputHolder .label {
  margin: 0;
  max-width: 170px;
  text-align: end;
  padding-right: 16px;
  font-style: normal;
  font-size: 10px;
  line-height: 14px;
  color: #363636;
  font-weight: normal;
  width: 100%;
}

.bankPage .innerDetail .inputHolder .select-border {
  border-radius: 4px;
  background-color: #eceff4;
  margin: 0;
  color: #7e7e7e;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  width: 100%;
  /* padding: 8px 18px; */
}

.bankPage .innerDetail .inputHolder .SegmentButton {
  display: flex;
  align-items: flex-start;
  border-radius: 5px;
  border: 0.727px solid #bdbdbd;
  background: #fff;
  width: 65%;
}

.bankPage .innerDetail .inputHolder .SegmentButton label {
  background: transparent;
  border: 0;
  text-align: center;
  width: 50%;
  padding: 5px;
  color: var(--secondry-color);
  font-size: 13.091px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 18.327px */
}

.bankPage .innerDetail .inputHolder .SegmentButton label.active {
  border: 0.727px solid #bdbdbd;
  background: var(--IP-Yellow, #f3c258);
}

.bankPage .innerDetail .inputHolder .SegmentButton label.saveInfoYesHOlder.active {
  border-radius: 2.909px 0px 0px 2.909px;
}

.bankPage .innerDetail .inputHolder .SegmentButton label.saveInfoNoHOlder.active {
  border-radius: 0px 2.909px 2.909px 0px;
}

.bankPage .innerDetail .inputHolder .form-control {
  border-radius: 3.909px !important;
}

.bankPage .innerDetail .notifyText {
  color: #7e7e7e;
  font-size: 10px;
  font-style: normal;
  font-weight: normal;
  line-height: 14px;
}

.bankPage .submitSection.innerDetail {
  margin-top: 1.5rem;
  border: 0;
}

.bankPage .submitSection.innerDetail .inputHolder .amountHolder {
  display: flex;
  align-items: center;
  border-radius: 2.913px;
  border: 0.728px solid #bdbdbd;
  background: #fff;
}

.bankPage .submitSection.innerDetail .inputHolder .amountHolder .form-control {
  border: 0;
  border-right: 0.728px solid #bdbdbd;
  padding: 12px;
}

.bankPage .submitSection.innerDetail .inputHolder .amountHolder p {
  margin: 0;
  color: var(--secondry-color);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  padding: 12px;
  line-height: normal;
}

.bankPage .submitSection.innerDetail .submitBtns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
}

.bankPage .submitSection.innerDetail .submitBtns button {
  width: 49%;
  padding: 10px;
}

.bankPage .submitSection.innerDetail .submitBtns .backButton {
  border-radius: 8px;
  border: 1px solid var(--100, #7e7e7e);
  background: transparent;
  color: #7e7e7e;
  font-size: 14px;
  line-height: 24px;
  font-style: normal;
  font-weight: normal;
  /* 14px */
}

.bankPage .submitSection.innerDetail .submitBtns .upgradeBtn {
  border-radius: 8px;
  background: var(--primary-color);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 6px -1px rgba(0, 0, 0, 0.12);
  border: 1px solid var(--primary-color);
  color: var(--secondry-color);
  font-size: 14px;
  line-height: 24px;
  font-style: normal;
  font-weight: normal;
}

.bankPage .submitSection.innerDetail .submitBtns .upgradeBtn[disabled] {
  opacity: 0.3;
}

.bankPage .userDetail ul.listHolder {
  padding: 0;
  margin: 0;
}

.bankPage .userDetail ul.listHolder li {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid #bdbdbd;
  width: 100%;
  list-style: none;
}

.bankPage .userDetail ul.listHolder li p {
  color: #7e7e7e;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  margin: 0;
  line-height: 16px;
  width: 30%;
}

.bankPage .userDetail ul.listHolder li .copyText {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 70%;
  word-wrap: break-word;
}

.bankPage .userDetail ul.listHolder li .copyText p {
  color: #363636;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  text-wrap: wrap;
  width: 60%;
  margin: 0;
}

.bankPage .userDetail ul.listHolder li .copyText img {
  cursor: pointer;
  margin-left: 20px;
}

.bankPage .outerRow .bankDetailNotify {
  border-radius: 6px;
  background: #efefef;
  padding: 32px 32px 20px;
  width: 60%;
}

.bankPage .outerRow .bankDetailNotify ul {
  padding: 0;
  margin: 0;
}

.bankPage .outerRow .bankDetailNotify ul li {
  list-style: none;
  color: #363636;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  padding-bottom: 15px;
}

.bankPage .outerRow .bankDetailNotify ul li a {
  color: #0a0a0a;
}

.tickCompany {
  margin-top: 11px;
  margin-bottom: 0px;
}

.productplanTitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 23.4px;
  text-align: left;
  color: #0A0A0A;
}

.productPlanHolder .planDescription {
  margin-bottom: 24px;
}

.productPlanCta {
  position: sticky;
  top: 15px;
}


.productPlanPriceHolder .productplanTitle {
  color: #0A0A0A;
  font-size: 24px;
  font-weight: 800;
  line-height: 31.2px;
  text-align: left;
  margin-bottom: 12px;
}

.productPlanPriceHolder .recumentPrice {
  text-align: left;
  background: none;
  color: #0A0A0A;
  font-size: 26px;
  font-family: 'JUST-B';
  line-height: 33.8px;
  text-align: left;
  padding: 0;
  margin-bottom: 2px;
}

.choosePaymentMethod {
  padding: 20px 10px;
  border-radius: 8px;
}

.choosePaymentMethod .titlechoosePay {
  font-size: 12px;
  font-weight: 400;
  line-height: 10px;
  text-align: left;
  color: #000000;
}

.choosePaySelectHolder {
  border: 1px solid #E8E8E8;
  background: none;
  display: inline-flex;
  align-items: center;
  padding: 12px;
  border-radius: 6px;
  margin-top: 16px;
  width: 100%;
  cursor: pointer;
}

.choosePaySelectHolder img {
  margin-right: 8px;
}

.choosePaySelectHolder h6 {
  font-size: 12px;
  font-weight: 800;
  line-height: 15.6px;
  text-align: left;
  color: #7E7E7E;

}

.choosePaySelectHolderActive {
  background: #FFF2D6;
  border: 0.9px solid #FFE2A2
}

.choosePaySelectHolderActive h6 {
  color: #B07E12;

}

.productUpgradePlanBtn {
  background: #F3C258;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  text-align: center;
  padding: 17px;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #0A0A0A;
  cursor: pointer;
  border: 1px solid#F3C258;
}

.spanConatctUS {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  background: none !important;
  border: 0 !important;
  text-decoration: underline;
  box-shadow: none !important;
  margin: 0 auto !important;
}

.spanTextWhite {
  color: #ffffff;
  padding-bottom: 0;
}

.spanTextWhite:hover {
  color: #ffffff !important;
}

.spanConatctUS:disabled,
.spanConatctUS.disabled {
  background: none !important;
  box-shadow: unset !important;
  border: 0 !important;
}

.businessNameHolder {
  margin-left: 6px;
  width: 80%;
}

.businessNameHolder p {
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  text-align: left;
  color: #F5F5F5;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* max-width: 140px; */
}

.businessNameHolder p.designation {
  margin-bottom: 0px;
  color: #BDBDBD;
}

.carousel-inner-image {
  width: 100%;
  height: 100%;
}

.carousel-inner {
  cursor: pointer;
  /* height: 170px; */
}

.carousel-inner img {
  border-radius: 12px;
  /* height: 100%; */
}

.carousel.carousel-slider {
  border-radius: 20px;
}

.carousel .thumbs-wrapper {
  display: none;
}

.carousel .slider-wrapper {
  /* border-radius: 10px; */
}

.carousel .control-dots {
  position: absolute !important;
  bottom: -45px !important;
}

.carousel.carousel-slider {
  overflow: visible !important;
}

.carousel .control-dots .dot.selected {
  background-color: #F3C258 !important;
  width: 32px;
  height: 8px;
  box-shadow: none;
  border-radius: 11px;
  margin: 0 8px 0 0 !important;
}

.carousel .control-dots .dot {
  box-shadow: none;
  background-color: #BDBDBD !important;
  height: 8px;
  width: 8px;
  margin: 0 8px 0 0 !important;
}

.bannerRow {
  margin-bottom: 60px;
}

.breadcrumbs-container-custom {
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.8rem);
  padding-left: var(--bs-gutter-x, 0.8rem);
}

.breadcrumb-custom {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  background: none;
  width: 100%;
}

.back-button-custom {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  position: absolute;
  left: 16px;
  padding: 0px;
}

.breadcrumb-custom-mobile {
  border-bottom: 0.2px solid #7E7E7E;
  background: #FFFFFF;
  width: 100%;
  padding: 16px;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
}


.breadcrumb-custom-mobile .breadcrumb-item-custom {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.breadcrumb-custom-mobile-first-page .breadcrumb-item-custom {
  justify-content: flex-start;

}

.breadcrumb-custom-mobile .breadcrumb-item-custom p {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.20000000298023224px;
  text-align: center;
  color: #0A0A0A;
  margin: 0;
  -webkit-text-stroke-width: 0.4px;
  -webkit-text-stroke-color: #0A0A0A;
}

.breadcrumb-item-custom {
  margin-right: 8px;
  font-size: 14px;
  color: #666;
  text-transform: capitalize;
}

.breadcrumb-item-custom a {
  text-decoration: none;
  color: #666;
}

.breadcrumb-item-custom.active {
  color: #000;
  font-weight: bold;
}

.productHolder {
  cursor: pointer;
  margin-bottom: 24px;
}

.productImage {
  /* background: url('../public/assets/img/product1.png'); */
  padding-bottom: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.productImageHolder {
  background: #ECECEC;
  border-radius: 12px;
  padding: 10px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.4s ease-out;
}

.hover-content {
  position: absolute;
  bottom: -300%;
  /* Initially hide the content below the card */
  left: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  color: #333;
  padding: 20px;
  transition: bottom 0.4s ease-out;
  /* Smooth transition for the bottom property */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
}

.productImageHolder:hover .hover-content {
  bottom: 0;
  /* Move the content up to show it on hover */
}

.hover-content p {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 0;
}

.stockCount {
  padding: 6px 10px;
  border-radius: 50px;
  border: 0.6px solid var(--Burgundy, #74141D);
  background: #FFF5F6;
  font-size: 11px;
  font-weight: 400;
  line-height: 14.3px;
  text-align: center;
  color: #74141D;
  margin: 0;
  width: fit-content;

}

.productDetailHolder {
  padding: 8px 0px;
}

.productDetailHolder p {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #7E7E7E;
  margin-bottom: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.productDetailHolder h6 {
  font-size: 16px;
  font-family: 'JUST-B';
  line-height: 22.4px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #0A0A0A;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

p.productSlug {
  font-size: 11px;
  font-weight: 400;
  line-height: 15.4px;
  margin: 0;
  text-align: left;
  color: #7E7E7E;
}

.productDetailHolder button {
  background: #F3C258;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 6px -1px rgba(0, 0, 0, 0.12);
  border: 0;
  border-radius: 8px;
  width: 100%;
  margin-top: 15px;
  padding: 17px;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  color: #0A0A0A;

}

.productImageHolder .suggestedText {
  margin: 0;
  padding-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 60%;
}

.paymentMethodList {
  margin-bottom: 20px;
}

.planContactUS {
  border: 1px solid#120505;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 17px;
  width: 100%;
  font-size: 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
}

.stockContHolder {
  padding: 16px 0px;
}

.bankPage .innerDetail .inputHolder.inputHolderColumn {
  flex-direction: column;
  align-items: flex-start;

}

.bankPage .innerDetail .inputHolder.inputHolderColumn .label,
.bankPage .innerDetail .inputHolder.inputHolderColumn .label.instructionLabel {
  text-align: left;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  text-align: left;
  color: #7E7E7E;
  margin-bottom: 6px;
}

.bankPage .innerDetail .inputHolder.inputHolderColumn .select-border.bankTransferSelectField {
  width: 100%;
}


.bankPage .innerDetail .inputHolder.inputHolderColumn .form-control.bankTransferField {
  width: 100%;
}

.bankPage .innerDetail .inputHolder.inputHolderColumn .form-control.bankTransferField.bankTransferFieldAmount {
  width: 100%;
}

.bankPage .innerDetail .inputHolder.inputHolderColumn.saveInfoColumn {
  width: 100%;
}

.bankPage .submitSection.innerDetail .inputHolder.inputHolderColumn .amountHolder {
  width: 100%;
}

.hrlineSidebar {
  background: #C0C0C0;
  margin: 12px 0 !important;
}

.spanContactUs,
.spanContactUs:disabled,
.spanContactUs[disabled] {
  border: 0 !important;
  box-shadow: none !important;
  padding: 0;
  text-decoration: underline;
  color: #7E7E7E;
  font-family: 'Just';
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  text-align: left;
}

.asof {
  font-size: 12px;
  color: #7E7E7E;
}

.statusTextHeadr {
  font-family: 'Just';
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  text-align: left;
  color: #0A0A0A;
}


.paymentPage .planHolder .planName.statusTab h4 {
  padding-top: 12px;
  font-size: 24px;
  line-height: 31px;
}

.paymentPage .planHolder .planName.statusTab .status-item {
  display: flex;
  position: relative;
  align-items: flex-start;
  /* padding-bottom: 24px; */
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.paymentPage .planHolder .planName.statusTab .status-item .status-icon {
  /* position: relative; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center the icon */
  justify-content: flex-start;
  flex-shrink: 0;
  /* Ensure icon size doesn't change */
}


.paymentPage .planHolder .planName.statusTab .status-item .status-icon img {
  display: block;
  width: 24px;
  /* Ensure the icon has a fixed size */
  height: 24px;
}

.paymentPage .planHolder .planName.statusTab .status-item .status-icon .statusLine {
  position: absolute;
  width: 2px;
  height: 95%;
  /* Set the line height to 100% */
  background-color: #FFE8B6;
  top: 24px;
}

.paymentPage .planHolder .planName.statusTab .status-item .status-info {
  padding-bottom: 24px;
  padding-left: 12px;
}

.paymentPage .planHolder .planName.statusTab .status-item .status-info p.statusCall {
  font-family: 'JUST-B';
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #3B3B3B;
  text-transform: capitalize;
}

.paymentPage .planHolder .planName.statusTab .status-item .status-info p.dateTime {
  font-family: 'Just';
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #3B3B3B;
}

.paymentPage .planHolder .planName.statusTab .status-item:last-child .status-icon .statusLine {
  display: none;
  /* Remove the line after the last item */
}

.paymentPage .planHolder .planName.statusTab .status-item:last-child .status-info {
  padding-bottom: 0;
}

.paymentPage .tableHolder .tableBody tr td button.statusButton {
  text-transform: capitalize;
  background: #F7F7F7;
  border-radius: 50px;
  font-family: 'Just';
  font-size: 13px;
  font-weight: 900;
  line-height: 16.9px;
  letter-spacing: 0.02em;
  text-align: center;
  padding: 8px;
  max-width: 110px;
  min-width: 110px;
  border: 0px;
}


.paymentPage .tableHolder .tableBody tr td button.statusButton.paid,
.paymentPage .tableHolder .tableBody tr td button.statusButton.approved {
  background: #F0F8EA;
  color: #66B92D;
}

.paymentPage .tableHolder .tableBody tr td button.statusButton.canceled {
  background: #FAE9EB;
  color: #CC2738;
}

.speakToAdminBtn {
  background: #F3C258;
  padding: 17px;
  border-radius: 8px;
  width: 100%;
  border: 0px;
  margin-top: 24px;
  box-shadow: 0px 2px 4px -1px #00000012, 0px 4px 6px -1px #0000001F;
  color: #0A0A0A;
  font-family: 'Just';
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  text-align: center;
}

.paymentPage .planHolder .planName.statusTab .status-item.contentLoader .status-info p.statusCall {
  line-height: 0;
}

/* new-payment-ui-css-end */

/* product Landing Page Css */
.topShadeSection {
  background-color: #F3C258;
  box-shadow: 0px 20px 30px 0px #0000000A;
  padding: 10px;
}

.screenResolation {
  max-width: 1280px;
}

.headerBottomSection {
  background-color: #000;
  padding: 20px;
}

.registerBuy {
  background-color: #F3C258;
  border: 1px solid #F3C258;
  box-shadow: 0px 2px 4px -1px #00000012;
  color: #000;
  border-radius: 8px;
  padding: 15px 30px;
  font-size: 16px;
  line-height: 18px;
}

.toptext p {
  font-size: 14px;
  line-height: 20px;
  color: #000
}

.footerbackground {
  background-color: #000;
}

.footerSection {
  padding: 48px 0px;
}

.socialIconSection {
  display: flex;
  align-items: center;
  justify-content: end;
}

.socialIcon {
  background-color: #7E7E7E;
  padding: 12px;
  border-radius: 16px;
  margin-left: 24px;
}

.cursorPointer {
  cursor: pointer;
}

.centerfooterSection {
  padding: 32px 0px 50px;
}

.leftAppLink {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.leftAppLink>p {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  padding-bottom: 16px;
}

.leftAppLink>div>img {
  cursor: pointer;
}

.InfoSection {
  text-align: end;
}

.alignCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.userDetailsLinkHeader {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: #7e7e7e;
  padding-bottom: 12px;
}

.bottomSection {
  border-top: 1px solid #d1d1d1;
  padding: 32px 0px;
}

.policyLinkSection {
  display: flex;
  align-items: center;
  justify-content: end;
}

.policyLinkSection>a>div {
  padding-left: 16px;
}

.policyText {
  font-size: 14px;
  line-height: 18px;
  color: #656565;
}

.cursorPointer {
  cursor: pointer;
}

.textDecorationNone {
  text-decoration: none;
}

.policyText:hover {
  color: #F3C258;
}

.userDetailsLink {
  display: flex;
  align-items: center;
  justify-content: end;
  padding-bottom: 8px;
  text-decoration: none;
}

.userDetailsLink>img {
  padding-right: 8px;
}

.userDetailsLink>p {
  font-size: 14px;
  line-height: 20px;
  color: #fff;
}

.leftAppLink img {
  margin-right: 10px;
}

.herosSection {
  background: linear-gradient(0deg, #000000, #000000);
  background-image: linear-gradient(110.02deg, #0A0A0A 6.13%, rgba(255, 204, 102, 0.5) 162.37%);
  padding: 32px 10px 64px;
}

.formShadeSection {
  background: #00000033;
  border-radius: 24px;
  padding: 32px 48px;
}

.imageSection {
  display: flex;
  align-items: center;
  justify-content: center;
}

.heroHeadTitle {
  font-size: 38px;
  line-height: 46px;
  text-align: center;
  font-weight: 800;
  color: #fff;
}

.heroHeadTitle>span {
  color: #F3C258;
  font-weight: 800;
}

.heroSectionPera {
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  text-align: center;
  font-weight: 400;
}

.priceSectionMain {
  display: flex;
  align-items: center;
  justify-content: center;
}

.priceSectionUpDown {
  background-color: #FFE8B6;
  padding: 3px 15px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hrline {
  border-bottom: 0.5px solid #C0C0C0;
}

.priceSectionUpDown>img {
  padding-right: 8px;
}

.priceSectionUpDown>p {
  font-size: 32px;
  line-height: 42px;
  color: #36280B;
  font-weight: 600;
}

.priceSectionUpDown>p>span {
  font-size: 20px;
  line-height: 26px;
}

.purchaseFormSection {
  padding: 26px 0px 0px;
}

.purchaseFormSpace {
  padding-bottom: 14px;
}

.productLabel {
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  font-weight: 400;
}

.firstFieldPadding {
  padding-right: 7px;
}

.lastFieldPadding {
  padding-left: 7px;
}

.heroapplinkSection {
  display: flex;
  align-items: center;
  justify-content: end;
  padding-top: 24px;
}

.heroapplinkSection>p {
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  font-weight: 400;
}

.heroapplinkSection img {
  padding-left: 10px;
}

.keyFrameInnerSection {
  padding: 24px;
}

.keyFeatures {
  padding: 64px 0px;
}

.keyFrameTitle {
  font-size: 48px;
  line-height: 62px;
  font-weight: 900;
  text-align: center;
  padding-bottom: 48px;
}

.keyFrameInner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.keyFrameInner>img {
  padding-bottom: 20px;
}

.keyFrameInner>p {
  padding-bottom: 12px;
  font-size: 18px;
  line-height: 24px;
}

.keyFrameInner>h6 {
  font-size: 22px;
  line-height: 28px;
}

.borderRight {
  border-right: 1px solid #E6E6E6;
}

.keyDescriptionSection {
  padding-top: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.keyDescriptionSection>h6 {
  width: 60%;
  font-size: 22px;
  line-height: 28px;
  color: #3B3B3B;
  text-align: center;
  font-weight: 400;
  padding-bottom: 8px;
}

.keyDescriptionSection>p {
  width: 75%;
  font-size: 16px;
  line-height: 22px;
  color: #7E7E7E;
  font-weight: 400;
  text-align: center;
  margin: 0px;
}

.assuredSection {
  background-color: #000;
  padding: 64px 0px;
}

.assuredRowSection {
  display: flex;
  justify-content: center;
}

.assuredColSection {
  border: 1px solid #C0C0C0;
  padding: 32px;
  border-radius: 8px 8px 8px 32px;
  width: 384px;
  margin: 0px 16px;
}

.assuredColSection>img {
  width: 100%;
  height: 204px;
  padding-bottom: 24px;
  object-fit: cover;
}

.assuredColSection>p {
  font-size: 22px;
  line-height: 28px;
  color: #fff;
}

.keyFrameTitle.AssuredTitle {
  color: #fff;
}

.assuredSection.guaranteedreceive {
  background-color: #fff;
}

.keyFrameTitle.GuaranteedText {
  color: #000;
}

.assuredColSection.guarantcolSection {
  padding: 0px;
}

.assuredColSection.guarantcolSection>img {
  height: 260px;
  padding: 0px;
  border-radius: 8px 8px 0px 0px;
}

.assuredColSection.guarantcolSection>p {
  padding: 32px;
  color: #000;
}

.investSection {
  background-image: url(../public/assets/img/landingPage/investmentBanner.png);
  padding: 32px 0px;
}

.investTitle {
  font-size: 32px;
  line-height: 42px;
  font-weight: 900;
  color: #3B3B3B;
}

.investmentGold {
  display: flex;
  align-items: center;
  padding-bottom: 6px;
}

.investmentGold>img {
  padding-right: 10px;
}

.investmentGold>p {
  font-size: 14px;
  line-height: 18px;
}

.registertoBuy {
  display: flex;
  align-items: center;
  justify-content: end;
}

.thankYouSection {
  background-image: url(../public/assets/img/landingPage/thankyoubg.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.thankYouSectionInner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 64px 0px;
}

.thankYouSectionInner>img {
  padding: 32px 0px 48px;
}

.thankYouSectionInner>div {
  text-align: center;
  width: 70%;
}

.thankYouSectionInner>div>h2 {
  font-size: 32px;
  line-height: 42px;
  color: #fff;
  font-weight: 900;
  padding-bottom: 16px;
}

.thankYouSectionInner>div>h6 {
  font-size: 18px;
  line-height: 24px;
  color: #F7F7F7;
  padding: 0px 90px 24px;
}

.thankYouSectionInner>div>p {
  font-size: 14px;
  line-height: 18px;
  color: #C0C0C0;
  padding: 0px 150px 32px;
}

.topHeaderHolder {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999;
}

.companyChangeHolder {
  margin-right: 24px;
  margin-bottom: 0px;
  display: flex;
}

.companyChangeHolder li {
  padding-left: 13px;
  border-left: 2px solid #7E7E7E;
}


.companyChangeHolder li:last-child {
  border: 0;
}

.gold-bid-holder {
  display: flex;
  align-items: center;
}

.gold-bid-price-holder {
  display: flex;
  align-items: center;
}

.gold-bid-holder .gold-bid-price-holder .bidCurrencyHolder {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.gold-bid-price-holder .bidCurrencyHolder:last-child {
  margin-right: 0px;
}

.headerHolder {
  position: relative;
}

.productStockKeyHolder {
  position: absolute;
  top: 12px;
  left: 10px;
  right: 10px;
}

.editPayout {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.payoutBalance {
  padding-bottom: 0px !important;
}

.inputHolder.inputHolderColumn .bankTransferField span.upperCaseText {
  margin: 0;
  color: var(--secondry-color);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  padding: 12px 0px;
  line-height: normal;
}


@media only screen and (max-width: 768px) {
  .headerBottomSection {
    padding: 10px 16px;
  }

  .registerBuy {
    font-size: 12px;
    line-height: 12px;
    padding: 10px 24px;
  }

  .alignimgSec {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .alignMobSec {
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .formShadeSection {
    padding: 16px 12px;
  }

  .heroHeadTitle {
    font-size: 24px;
    line-height: 28px;
  }

  .heroSectionPera {
    font-size: 10px;
    line-height: 13px;
  }

  .priceSectionUpDown>p {
    font-size: 24px;
    line-height: 32px;
  }

  .herosSection {
    padding: 24px 6px;
  }

  .heroapplinkSection {
    flex-direction: column;
  }

  .keyFeatures {
    padding: 24px 16px;
  }

  .keyFrameTitle {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 24px;
  }

  .keyFrameInnerSection {
    padding: 0px
  }

  .keyFrameInner>img {
    padding-bottom: 10px;
  }

  .keyFrameInner>p {
    padding-bottom: 6px;
    font-size: 12px;
    line-height: 16px;
  }

  .keyFrameInner>h6 {
    font-size: 14px;
    line-height: 18px;
  }

  .mobilemargin {
    margin-bottom: 16px;
  }

  .keyDescriptionSection>h6 {
    font-size: 14px;
    line-height: 18px;
    width: 100%;
    margin: 0px;
  }

  .keyDescriptionSection>p {
    font-size: 12px;
    line-height: 16px;
    width: 100%;
  }

  .assuredSection {
    padding: 24px 16px;
  }

  .assuredColSection {
    margin: 0px 16px 16px;
    padding: 24px;
  }

  .assuredColSection>p {
    font-size: 16px;
    line-height: 20px;
  }

  .investSection {
    padding: 24px 6px;
  }

  .registertoBuy {
    justify-content: flex-start;
    padding-top: 24px;
  }

  .socialIconSection {
    justify-content: center;
  }

  .socialIcon {
    margin: 0px 10px;
  }

  .aligncenterimg {
    text-align: center;
    margin-bottom: 32px;
  }

  .centerfooterSection {
    padding: 20px 0px 32px;
  }

  .userDetailsLink {
    justify-content: center;
  }

  .bottomSection {
    padding: 20px 0px;
  }

  .InfoSection {
    text-align: center;
    padding-top: 20px;
  }

  .leftAppLink img {
    margin: 0px 10px;
  }

  .leftAppLink {
    align-items: center;
  }

  .policyText {
    font-size: 10px;
    line-height: 14px;
    padding-bottom: 12px;
    text-align: center;
  }

  .policyLinkSection {
    justify-content: center;
  }

  .policyLinkSection>a>div {
    padding: 0px 10px;
  }

  .thankYouSectionInner {
    padding: 24px 16px;
  }

  .thankYouSectionInner>img {
    padding: 32px 0px;
  }

  .thankYouSectionInner>div {
    width: 100%;
  }

  .thankYouSectionInner>div>h2 {
    font-size: 24px;
    line-height: 32px;
  }

  .thankYouSectionInner>div>h6 {
    font-size: 12px;
    line-height: 16px;
    padding: 0px;
  }

  .thankYouSectionInner>div>p {
    font-size: 12px;
    line-height: 16px;
    padding: 0px;
  }

  .mobile-overflow {
    display: flex;
    /* Use flexbox for horizontal layout */
    flex-wrap: nowrap;
    /* Prevent wrapping, keep columns on one line */
    overflow-x: auto;
    /* Enable horizontal scroll */
    white-space: nowrap;
    /* Prevent text wrapping inside the columns */
  }

  .toptext {
    width: 60%;
    /* Each column takes 50% of the row width */
    flex: 0 0 60%;
    /* Ensure flex basis is set to 50% */
  }
}

/* End product Landing Page Css */



@media screen and (max-width: 920px) {
  .sidenav {
    z-index: 9999 !important;
  }

  .modal {
    top: 0;
    z-index: 9999999;
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: 15%;
  }

  .navbar-nav .dropdown-icon.dropdown-toggle.btn.btn-primary i {
    left: -5px;
  }

  .navbar-collapse .navbar-nav .dropdown .dropdown-menu {
    inset: 0px 0 auto auto !important;
    /* right: -66px !important; */
  }

  .col-border {
    border-bottom: 1px solid rgba(82, 89, 77, 0.3);
    border-right: none;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .invoices-padding {
    margin: 0 !important;
  }

  .modal-dialog {
    width: 95%;
    margin: 20px auto;
  }

  .modal-body {
    padding: 15px 20px;
  }

  .close-button {
    right: 17px;
    top: 3px;
    font-size: 27px;
    z-index: 9999;
  }

  .colWidth-mobile {
    width: 50%;
  }

  .media-content-li {
    z-index: 99999;
  }

  .sidenav-header .icon {
    font-size: 26px;
  }

  .popover.sidebar-popover {
    top: 55px !important;
    z-index: 99999;
  }

  .floatingCompanyMenuHolder.active .floatingCompanyMenu {
    right: 10px;
    min-width: 90%;
  }

  .google-login {
    padding: 14px 0px;
  }

  .google-login img {
    margin-right: 5px;
  }

  .accountBtn {
    width: 100%;
    height: 40px;
  }

  .upload-here-img .upload-here {
    top: 50%;
    left: 25%;
  }

  .upload-here-img .upload-img {
    margin-top: 19px;
  }

  .upload-here-img .upload-btn .load-btn {
    margin-left: auto !important;
  }

  .upgrad-modal-width .modal-body {
    padding: 0 29px 10px;
  }

  .guide-modal .guideHeader p {
    font-size: 19px;
    padding: 13px 35px;
  }

  .guide-modal .guideHeader i {
    top: 10px;
    right: 16px;
  }

  .guide-modal .guideBody .guideContent .imgHolder {
    min-height: 150px;
    margin-bottom: 10px;
  }

  .guide-modal .guideBody .guideContent .contentHolder .contactus-modal-button {
    margin: 0 auto !important;
  }

  .guide-modal .guideBody .guideContent .contentHolder {
    padding: 0;
  }

  .guide-modal .guideBody .guideContent .contentHolder h5 {
    font-size: 17px;
  }

  .guide-modal .guideBody .guideContent .contentHolder p {
    font-size: 12px;
  }

  .askModal {
    top: auto !important;
    bottom: 94px;
    right: 0px;
    left: 0px;
    /* position: fixed; */
  }

  .helpPopover .needHelpMenu .buttonHolder {
    display: inline-block;
  }

  .helpPopover .needHelpMenu .buttonHolder .leaveMessage {
    width: 100%;
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 0;
  }

  .helpPopover .needHelpMenu .chatWithUS {
    width: 100%;
    display: inline-block;
  }

  .helpPopover .needHelpMenu .emailSent {
    padding: 0;
  }

  .paymentPage .paymentHeader {
    flex-direction: column;
    align-items: end;
  }

  .paymentPage .segmentHolder {
    margin-bottom: 10px;
  }

  .bankPage .userDetail {
    margin-top: 10px;
  }

  .bankPage .submitSection.innerDetail .submitBtns {
    margin-top: 18px;
  }

  .bankPage .bankDetailNotify {
    margin-top: 18px;
  }

  .profileCounts {
    padding: 20px !important;
  }

  .profileCounts .totalprofile h4 {
    margin: 0px;
  }

  .productPlanCta {
    position: unset;
  }

  .productPlanPriceHolder {
    padding: 20px 0px 0px;
    width: 100%;
  }

  .productPlanPriceHolder .productplanTitle {
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 15px;
  }

  .productPlanPriceHolder .recumentPrice {
    font-size: 14px;
    line-height: 19px;
  }

  .choosePaymentMethod {
    box-shadow: none;
    position: fixed;
    bottom: 0;
    background: #ffffff;
    left: 0;
    right: 0;
    margin: 0;
    padding: 16px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    z-index: 999999;
    box-shadow: 0px -10px 12px 0px #00000014;
  }

  .choosePaySelectHolder {
    margin-top: 10px;
    padding: 6px;
  }

  .choosePaySelectHolder img {
    width: 24px;
  }

  .choosePaySelectHolder h6 {
    font-size: 10px;
  }

  .productUpgradePlanBtn {
    padding: 10px 15px;
  }

  .productPlanHolder {
    margin-bottom: 135px;
  }

  .hover-content {
    display: none;
  }

  .stockCount {
    padding: 4px 10px;
    font-size: 9px;
  }

  .productImageHolder .suggestedText {
    font-size: 9px;
  }

  .productDetailHolder p {
    font-size: 14px;
  }

  p.productSlug {
    font-size: 9px;
  }

  .productDetailHolder h6 {
    font-size: 14px;
  }

  .productDetailHolder button {
    font-size: 12px;
    padding: 12px;
  }

  .productImage {
    padding-bottom: 50%;
  }

  .mobile-header {
    position: sticky;
    top: 0;
  }

  .breadcrumbs-container-custom {
    margin-bottom: 20px;
  }

  .bankPage .submitSection.innerDetail .submitBtns.submitBtnsColumn {
    flex-direction: column-reverse;
  }

  .bankPage .submitSection.innerDetail .submitBtns.submitBtnsColumn button.backButton {
    margin-top: 10px;
    width: 100%;
    margin-right: 0px;
  }

  .bankPage .submitSection.innerDetail .submitBtns.submitBtnsColumn button.upgradeBtn {
    width: 100%;
  }

  .planDescription p {
    font-size: 12px !important;
    line-height: 18px !important;
  }

  .mobilePaymentRow {
    flex-direction: column-reverse;
  }

  .mobilePaymentRow .segmentHolder {
    position: sticky;
    top: 10px;
  }

  .headerHolder {
    flex-direction: column-reverse;
    align-items: flex-end !important;
  }

  .companyChangeHolder {
    margin-bottom: 16px;
    margin-right: 0;
  }

  .companyChangeHolder li {
    border: 0;
  }

  .gold-bid-holder {
    align-items: flex-end;
    flex-direction: column-reverse;
  }

  .gold-bid-price-holder {
    margin-bottom: 10px;
    margin-right: auto;
    margin-left: 14px;
  }

  .currencySelectBoxHolder {
    position: absolute;
    top: 5px;
    left: 58px;
  }

  .gold-bid-holder .gold-bid-price-holder .bidCurrencyHolder {
    flex-direction: column;
    align-items: flex-start;
  }

  .bid-gold-price .bidCurrency {
    margin-left: 0 !important;
    margin-top: 4px !important;
  }
}

/* media 920 End */

.confirmbtn {
  margin-right: 20px;
  height: 42px;
}

@media (min-width: 1630px) {
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader-spinner {
  position: fixed;
  width: 100%;
  text-align: center;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 123456789;
  top: 0;
  left: 0;
}

.loader-spinner>span {
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  z-index: 1234165165616;
}

.payment-confirmation {
  text-align: center;
}

.Maincontainer {
  background-color: whitesmoke;
  padding: 2rem;
}

.Innercontainer {
  background-color: white;
  text-align: center;
}

.Mainheading {
  font-family: 'Just-M';
  font-size: 26px;
  color: #000;
  padding: 1rem;
  line-height: 32px;
}

.msgicon {
  font-size: 70px;
  color: var(--primary-color);
}

.declaration {
  font-size: 18px;
  padding: 2rem;
  color: #000;
  line-height: 24px;
  font-family: 'Just';
}

.btncust {
  color: var(--secondry-color);
  background-color: var(--primary-color);
}

.btncust:hover {
  color: var(--secondry-color);
}

.payment-bottom-btn,
.agree-button {
  text-transform: unset;
}

.business-activities {
  padding-bottom: 0;
}

.signIn-back .page-header {
  margin: 0 !important;
  padding: 0;
  height: 100vh;
}

/* New CSS style */
.card-login h3 {
  font-size: 36px;
  line-height: 41px;
}

.card-login p {
  font-weight: 400;
  color: #7e7e7e;
  font-size: 14px;
  line-height: 24px;
}

.card-login label,
.form-check-label {
  font-size: 14px;
  line-height: 16px;
  color: #7e7e7e;
  font-weight: normal !important;
}

.form-control {
  border-radius: 6px !important;
  border: 1px solid #BDBDBD;
}

.form-switch .form-check-input {
  height: 1.35em;
}

.form-switch .form-check-input:after {
  top: 2px;
  background-color: var(--secondry-color);
}

.card-login button {
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
}

.signup-back .page-header {
  margin-top: 0 !important;
  border-radius: 0px 0px 72px 72px;
}

.signup-back .card .card-header {
  padding-bottom: 0;
}

.signup-back .mt-lg-n10 {
  margin-top: -8rem !important;
}

.signup-back p.agree {
  margin: 0;
  padding: 5px 0;
  color: #252f40;
}

.table-heading {
  font-style: normal;
  font-size: 14px !important;
  line-height: 24px;
  color: #7e7e7e;
  font-family: 'JUST';
  font-weight: normal;
  padding-bottom: 4px;
  margin-top: 0 !important;

}

.bussinessTabel .business-font {
  font-size: 18px;
  line-height: 24px;
  color: #0a0a0a;
  font-weight: normal;
  font-family: 'Just-M';
}

.business-font.company-xyz-text a,
.business-font.company-xyz-text a:hover {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-decoration-line: underline;
  color: var(--secondry-color);
}

.btn.btn-link.edit-button {
  color: var(--edit-button-bg);
  margin-top: 2px;
  background: #ffffff;
  border: 1px solid #bdbdbd;
  border-radius: 8px;
  padding: 0;
  text-align: center;
  width: 40px;
  text-align: center;
  height: 40px;
  padding: 10px 0 !important;
  font-size: 14px;
  line-height: 20px;

}

.btn.btn-link.edit-button.with-image {
  padding: 7px 0 !important;
}

.btn.btn-link.edit-button.with-image img {
  width: 30px;
}

.btn.btn-link.edit-button i {
  margin: 0 !important;
}

.phil-text {
  font-size: 14px;
  line-height: 18px;
  color: var(--primary-color);
  margin: 0 !important;
  font-family: 'Just-M';
  font-weight: normal !important;
}

.phil-text-title {
  font-size: 20px;
  line-height: 24px;
  color: #0a0a0a;
  font-family: 'JUST-B';
}

.breadcrumb li.breadcrumb-item.text-sm {
  font-size: 14px !important;
  line-height: 16px;
  color: #0a0a0a;
}

h3.business-font.capital-head.high-cap {
  margin: 0 0 10px;
  padding: 0 0px;
  font-size: 18px;
  line-height: 24px;
  color: #0a0a0a;
  font-family: 'Just-M';
  font-weight: normal;
}

.capitalTable {
  padding: 0.5rem 0 0;
}

.capitalTable .table-heading {
  padding-bottom: 0;
}

.capitalTable .table.align-items-center th {
  padding-bottom: 0;
}

.btn-close {
  background: url("../public/assets/img/btn-close.svg");
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: center;
}

.cursorPointer {
  cursor: pointer;
}

.share-officer h3.business-font.capital-head.high-cap {
  padding: 0;
}

.share-officer h6 {
  font-weight: normal;
  word-break: break-word;
  white-space: break-spaces;
  display: flex;
  align-items: center;
  font-size: 14px !important;
  line-height: 24px;
  color: #363636 !important;
  font-family: 'Just';
}

.add-button {
  color: #74141d;
  border: 1px solid #74141d !important;
  padding: 10px 20px 10px 20px !important;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  border-radius: 8px;
  font-family: 'JUST';
}

.add-button:hover {
  color: #74141d !important;
}

.add-button-shareholder {
  color: var(--secondry-color);
  border: 1px solid var(--secondry-color) !important;
}

.add-button-shareholder:hover {
  color: var(--secondry-color) !important;
}

.table-responsive table.table-sharholder tr td .btn.btn-link {
  color: #0a0a0a;
  font-size: 16px;
}

.table-responsive table.table-sharholder thead th,
.table-responsive table.table-sharholder body th .table-responsive table.table-sharholder body td,
.table-responsive table.table-sharholder> :not(caption)>*>* {
  /* padding-left: 0;
  padding-right: 0; */
  padding: 16px 8px;
}

.table-responsive table.table-sharholder {
  padding-left: 1rem;
  padding-right: 1rem;
  /* min-width: 1040px;
  width: 930px; */
}

.card .card-header {
  padding: 1rem;
}

.view-button {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  border-radius: 8px;
  font-family: 'JUST';
  padding: 10px 40px;
}

.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link {
  font-size: 14px;
  line-height: 24px;
  color: #7e7e7e;
}

.docs-info h6 {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  padding: 10px 0;
  font-family: 'Just-M';
}

.docs-info p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.docs-info span {
  font-size: 14px;
  line-height: 24px;
  color: #0a0a0a;
  font-weight: normal;
  font-family: 'Just-M';
}

.header-promoters {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
  color: #7e7e7e;
  font-weight: normal;
  font-family: 'Just-M';
}

.no-record {
  padding-left: 1.5rem;
  padding-right: 1rem;
}

.content-text p {
  font-size: 12px;
  line-height: 20px;
}

.content-text h6 {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
}

.content-text button.upgrade-btn-dashboard,
.content-text button.upgrade-btn-dashboard:hover {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
}

.share-officer .card {
  padding: 8px 0;
}

.additional-action {
  padding: 1.5rem 1rem 1rem !important;
}

.text-right {
  text-align: right !important;
}

.table th.text-right,
.table td.text-right {
  padding-right: 15px;
}

ol.breadcrumb {
  margin-bottom: 8px !important;
}

.navbar-vertical .navbar-nav>.nav-item .nav-link.active .nav-link-text {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #252f40;
}

.sidebar-account {
  margin: 0px !important;
  padding: 0px 1rem !important;
}

.navbar-vertical .navbar-nav.navbar-nav.separate-link {
  margin-top: 30px;
  margin-bottom: 10px;
}

.navbar-vertical .navbar-nav.navbar-nav.separate-link .nav-link {
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  cursor: pointer;
}

.navbar-vertical .navbar-nav.separate-link .logout-icon {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-top: 5px;
}

.file-holder td {
  font-weight: normal;
  font-size: 14px !important;
  line-height: 20px;
  color: #0a0a0a;
}

.file-holder td:nth-child(2) {
  color: #363636;
}

.file-holder td:last-child a {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #0a0a0a;
  font-family: 'Just-M';
  text-transform: uppercase;
}

.file-holder td:last-child a i {
  vertical-align: middle;
}

.form-control {
  font-size: 14px;
  line-height: 24px;
  color: #363636;
}

.modal-content {
  padding: 2.5rem 2.5rem;
}

.modal-body {
  padding: 0;
}

.modal-footer.modal-bottom-action {
  margin-top: 30px;
}

.table-responsive.overflowHidden {
  overflow: hidden;
}

.edit-description-adv-bullet {
  color: #363636;
  font-size: 14px;
  line-height: 24px;
}

.content-text-payment .annually-toggal-row .annually-lable {
  font-weight: 500 !important;
  font-size: 12px;
  line-height: 14px;
  color: #9e9e9e;
}

.content-text-payment .annually-toggal-row .annually-lable.active {
  color: #52594d;
}

.payment-title {
  font-size: 18px;
  line-height: 21px;
  color: #52594d;
}

.page-header.profile-header {
  padding-bottom: 170px;
}

.profile-title-location .text-status p {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #7e7e7e;
  font-family: 'Just';
}

.profile-title-location .text-status .Vancouver-text {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #363636;
  margin-bottom: 0;
  font-family: 'Just-M';
}

.profile-information-bottom {
  list-style: none;
  padding: 0;
  margin: 00px 0 20px;
  padding-top: 30px;
  border-top: 1px solid #f0f0f0;
}

.profile-information-bottom li {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #52594d;
  margin-bottom: 20px;
}

.profile-information-bottom .click-nav-link {
  color: #52594d;
  cursor: pointer;
}

.profile-inf ormation-bottom .click-nav-link {
  opacity: 0.7;
}

.profile-information-bottom li a {
  color: #52594d;
}

.profile-information-bottom li:last-child {
  margin-bottom: 0;
}

.profile-information-bottom li i {
  margin-left: 8px;
}

.profile-head {
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
  font-family: 'Just-B';
}

.today-text-profile {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #7e7e7e !important;
  margin-bottom: 0;
}

.activity-logs .profile-text {
  font-weight: normal;
  font-size: 14px !important;
  line-height: 24px;
  color: #0a0a0a;
  margin-bottom: 0 !important;
}

.activity-logs span.datetime {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #363636;
}

.inner-container-fluid {
  padding: 0 80px;
  min-height: 100vh;
}

.main-page-title {
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #0a0a0a;
  font-family: 'Just-B';
}

h3.col-form-label.profile-head {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #252f40;
}

.card.new-card {
  padding: 20px 20px;
}

.authentication h6 {
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #0a0a0a;
  font-family: 'Just-M';
}

.col-form-label.edit-password-title {
  font-size: 20px;
  line-height: 24px;
  color: #0a0a0a;
  margin-bottom: 5px;
  font-weight: normal;
  font-family: 'Just-M';
}

.table-trust-moblie td {
  padding-left: 0;
  padding-right: 0;
}

.table-trust-moblie .table-heading {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #7e7e7e;
}

.table-trust-moblie .table-heading .business-font {
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #7e7e7e;
}

.small-text-heading {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #7e7e7e;
  font-family: 'Just-M';
}

.addressModal .modal-content {
  padding: 0;
}

/* .business_wrapper_name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  max-width: 330px;
} */

.business_wrapper_name {
  word-break: break-word;
  white-space: break-spaces;
}

/* .business_wrapper_link {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  max-width: 210px; 
} */

.business_wrapper_link {
  word-break: break-word;
  white-space: break-spaces;
}

/* .registered_wrapper_address {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  max-width: 500px;
} */

.registered_wrapper_address {
  white-space: break-spaces;
  word-break: break-word;
}

.business-image {
  width: 40px;
  height: 40px;
  border-radius: 13px;
  box-shadow: 0px 20px 27px rgb(0 0 0 / 5%);
  border-radius: 100%;
}

.business-image.hasLogo {
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-flex;
  background-position: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.dropdown-menu-inner .business-image.hasLogo {
  border: 4px solid #fff;
}

.business-image.hasText {
  background-image: none !important;
  text-align: center;
  align-items: center;
  display: grid;
  font-size: 22px;
  font-family: 'Just-M';
  box-shadow: 0px 4px 5px 1px rgb(0 0 0 / 10%)
}

.sidenav-header .business-image {
  border-radius: 5px;
  box-shadow: 0 4px 5px 1px #0000001a;
  cursor: pointer;
  float: left;
  height: 38px;
  margin-right: 3%;
  overflow: hidden;
  position: relative;
  width: 38px;
}

.business-image.hasText {
  background-image: url("../public/assets/img/business-shape.png") !important;
  background-size: cover;
}

.sidenav-header .business-image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

.sidenav-header .business-image.active::before {
  display: none;
}

.whole-task-section h5 {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #0a0a0a;
}

.whole-task-section text-status p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #363636;
}

.whole-task-section text-status p span {
  padding-left: 5px;
}

.edit-button-task {
  background: unset;
  border: 0;
  font-size: 15px;
  position: absolute;
  right: 0;
  top: 0;
}

.task-img-padding h5 {
  padding-right: 60px;
}

.form-select.newFormSelect {
  padding-right: 35px;
}

.payment-invoice-section {
  padding: 1rem 0.3rem;
}

.payment-text-invoice-head {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #52594d;
}

.invoice-id-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #7e7e7e;
}

.invoice-id-amount {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #7e7e7e;
}

.btn.btn-link.invoice-id-amount,
.btn.btn-link.invoice-id-amount:hover {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #7e7e7e;
}

.modal-footer.modal-bottom-action.align-text-left {
  align-items: start;
  justify-content: start;
}

.eye-show {
  position: relative;
}

.eye-show i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
}

.eye-show input.form-control {
  padding-right: 40px;
}

.pagination {
  margin: 45px auto 15px;
  display: flex;
  list-style: none;
  outline: none;
  justify-content: center;
}

.pagination>.active>a {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff;
}

.pagination>li>a {
  border: 1px solid var(--primary-color);
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  outline: none;
  color: #000;
}

.pagination>li>a,
.pagination>li>span {
  color: var(--primary-color);
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
  border-radius: unset;
}

.filter-button .btn.btn-secondary,
.filter-button .btn.btn-secondary:focus,
.filter-button .btn.btn-secondary:hover {
  background-color: unset;
  color: #0a0a0a;
  box-shadow: unset;
  padding-bottom: 0;
  padding-right: 15px;
  font-size: 14px;
  line-height: 24px;
  transform: none;
}

.filter-button .btn.btn-secondary::after {
  content: "\f0dd";
  position: absolute;
  top: 64%;
  transform: translateY(-50%);
  right: 0;
  font-family: "FontAwesome";
}

.table.tableaddresscard tbody tr:last-child td {
  width: 100%;
}

/* .sidenav-header.emptyheaderDesktop {
  display: none;
} */

.fa-xmark {
  font-size: 24px;
}

.form-group textarea {
  resize: none;
}

.otp_modal.address_modal .profile-form {
  padding: 0;
  box-shadow: unset;
}

.otp_modal.address_modal .modal-content {
  box-shadow: none;
  max-width: 432px;
  width: 100%;
  text-align: left;
}

.otp_modal.address_modal .modal-dialog {
  max-width: 432px;
  width: 100%;
}

.otp_modal.address_modal .modal-header {
  padding-top: 0;
}

.otp_modal.address_modal .modal-header h3 {
  text-align: center;
}

.otp_modal.address_modal button.btn.agree-button.accountBtn {
  float: right;
}

.custom-modal.save-modal-func::before {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  content: "";
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.companyActivityFilterProfile.dropdown:not(.dropdown-hover) .dropdown-menu {
  margin-top: 8px !important;
  max-height: 230px;
  overflow: auto;
  transform: translateY(30px) !important;
  inset: unset !important;
}

.companyActivityFilterProfile.dropdown:not(.dropdown-hover) .dropdown-menu::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

/* Track */
.companyActivityFilterProfile.dropdown:not(.dropdown-hover) .dropdown-menu::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.companyActivityFilterProfile.dropdown:not(.dropdown-hover) .dropdown-menu::-webkit-scrollbar-thumb {
  background: var(--primary-color);
}

/* Handle on hover */
.companyActivityFilterProfile.dropdown:not(.dropdown-hover) .dropdown-menu::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}

.import-note-disclaimer {
  text-align: center;
}

span.shareCreateDate.shareCreateEmail {
  padding-right: 10px;
  display: inline-block;
}

h6.title-description {
  font-size: 14px;
  color: #363636;
  line-height: 24px;
  font-weight: normal;
  font-family: 'Just';
}

h6.title-description strong {
  font-family: 'Just-B';
}

body .table-sharholder tr td h6 img.verifiedIcon {
  width: 17px;
  display: inline-block;
  padding: 0 !important;
  margin: 0 0px 0 3px !important;
}

.Twilio-CommonStyles,
.Twilio-RootContainer {
  font-family: "Just";
}

.conversation-text {
  font-size: 16px;
  line-height: 18px;
  font-family: "Just-M";
  color: #7e7e7e;
}

.signer-content .conversation-text {
  margin-bottom: 1rem !important;
}

img.button-success {
  padding: 6px;
  margin-right: 10px !important;
}

img.button-danger {
  margin-right: 10px !important;
  background: var(--secondry-color);
  padding: 6px;
  border-radius: 7px;
}

.conversation-section {
  height: fit-content;
  overflow: auto;
  max-height: 145px;
  border: 2px solid #BDBDBD;
  border-radius: 10px;
  margin: 0px;
  min-height: 50px;
  padding: 4px;
}

.conversation-section>.inner-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 6px 4px 6px;
}

.profile-img {
  background-image: url("../public/assets/img/team-3.jpg");
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 32px;
  height: 32px;
  min-width: 32px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.conversation-text-margin {
  margin-top: 1.75rem;
}

.profile-name {
  background-color: var(--secondry-color);
  border-radius: 8px;
  padding: 5px 8px;
  color: #ffffff;
  margin-left: 12px;
  font-size: 12px;
  line-height: 13px.79;
  gap: 4px;
  height: min-content;
  min-height: 32px;
}

.inner-section>span {
  color: #7e7e7e;
  margin-left: 10px;
  font-size: 11.49px;
  line-height: 14px !important;
  font-style: italic;
  line-height: 32px;
  height: fit-content;
}

.profile-name img {
  margin-left: 10px;
  vertical-align: middle;
  position: relative;
  top: -1px;
}

em {
  font-style: italic;
}

strong em {
  font-weight: 700;
  font-style: italic;
}

div#toolbar {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.ql-editor {
  min-height: 13vh;
  max-height: 13vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 0;
}

.startConversation {
  text-align: center;
  padding-top: 25%;
}

.messageTextarea {
  height: 130px;
}

th.action-heading {
  text-align: center;
}

.handleEye {
  position: relative;
}

.handleEye .viewEye {
  position: absolute;
  top: 8px;
  right: 2px;
  width: 30px;
  text-align: center;
  cursor: pointer;
}

.participantToggle {
  cursor: pointer;
  margin: 0 12px;
}

.editConvo {
  cursor: pointer;
  margin-left: 8px;
}

.editConvoInput {
  border: 0.80179px solid #d2d6da;
  border-radius: 6.41432px;
  padding: 8px 10px 8px 10px;
  outline: none;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #0a0a0a;
  opacity: 1;
  min-width: 240px;
  width: 100%;
}

.message-page .messageRow .messageLeft .userList ul li .messageDetail p.message .highlighted {
  font-weight: 700;
  color: var(--primary-color);
}

.messageListOuter {
  transition-property: background;
  transition-duration: 1s;
  transition-timing-function: linear;
  padding: 7px;
}

.messageListOuter.highlight {
  background: #eceff4;
  border-radius: 7px;
}

.partitipantMemberCountHead {
  color: #7e7e7e;
  font-size: 14px;
  font-weight: normal;
}

.card-btn.add-morepeople-bottom-btn {
  width: 100%;
  margin: 20px 0 0 0;
  padding: 15px;
}

.joinedLeaveChat {
  text-align: center;
  padding: 12px;
  font-size: 12px;
  /* font-style: italic; */
  color: #7e7e7e;
}

.userList.chat h6 {
  color: #7e7e7e;
  font-size: 10px;
  line-height: 11px;
  padding: 15px 18px 0px;
}

.messageListDown {
  float: left;
  width: 100%;
}

.mediaFullWidth {
  background: #eceff4;
  border-radius: 8px;
  display: inline-flex;
  width: auto;
  align-items: center;
  justify-content: flex-start;
}

.mediaFullWidth .addMedia {
  margin: 0;
}

.mediaFullWidth .fileNameSection .fileName {
  color: #252f40;
  font-size: 12px;
  margin: 0;
  display: none;
}

.mediaFullWidth .fileNameSection .fileSize {
  color: #7e7e7e;
  font-size: 12px;
  margin: 0;
  display: none;
}

.messagingService .message-box-hold-outer {
  margin: 10px 0;
}

.userList.chat .message-box-hold .message {
  margin-top: 5px;
}

input[type="search"]::-webkit-search-decoration:hover,
input[type="search"]::-webkit-search-cancel-button:hover {
  cursor: pointer;
}

.btn.pending-button-second,
.btn.pending-button-second:hover {
  cursor: unset;
  box-shadow: unset;
  color: #7e7e7e;
  background: #d2d6da;
  border: 0.813665px solid #7e7e7e;
  padding: 6px 12px;
  font-weight: normal;
  border-radius: 4px;
  font-size: 10px;
}

.btn.active-button-second.pending-button-second,
.btn.active-button-second.pending-button-second:hover {
  color: #363636;
  background: var(--third-color);
  border: 1px solid var(--primary-color);
}

.whole-task-section .customScroll {
  height: 70vh;
  overflow-y: auto;
}

.share-paid-up-page .capitalHolder {
  padding-top: 5px;
}

.add-button.add-button-shareholder.disabled {
  border-color: #bdbdbd !important;
  color: #bdbdbd;
  opacity: 1;
}

/* skeleton Css */
.skeltonSection {
  display: flex;
  flex-direction: column;
}

.bottomDashSkelton {
  margin-top: 24px;
}

span.appointed::before {
  position: relative;
  background-repeat: no-repeat;
  display: inline-block;
  content: url('../public/assets/img/verified.svg');
  vertical-align: middle;
  margin: 0 1px 0 2px;
  background-size: 110%;
  width: 14px;
  height: 14px;
  top: -1px;
}

span.potential,
span.appointed {
  color: #000;
}

span.potential::before {
  position: relative;
  background-repeat: no-repeat;
  display: inline-block;
  content: url('../public/assets/img/verified-outline.svg');
  vertical-align: middle;
  margin: 0 1px 0 2px;
  background-size: 110%;
  width: 14px;
  height: 14px;
  top: -1px;
}

.message-page .customButtons button[type="submit"] {
  border-left: 2px solid #D2D6DA;
}

.loader-chatSpinner {
  text-align: center;
  display: inline-block;
  width: auto;
  margin: 30px auto 0;
}

span.wrapTime {
  font-size: 11px;
  line-height: 13px;
  margin-bottom: 5px;
  display: block;
}

ul.template-types>li {
  vertical-align: middle;
}

ul.template-types>li label {
  margin: 8px;
  font-weight: 100;
  font-size: 14px;
  color: #363636;
}

ul.template-types>li input {
  accent-color: #000;
}

.typeOfFiles button.accountBtn {
  width: 100%;
}


.typeOfFiles button.accountBtn {
  width: 100%;
  justify-content: center;
  padding: 14px 10px;
  font-size: 14px;
  line-height: 24px;
}

ul.template-types>li {
  vertical-align: middle;
  display: inline-block !important;
}


.uploaded-file-list h6 {
  font-size: 12px;
  color: #7E7E7E;
  font-weight: 100;
}

.uploaded-file-list li {
  justify-content: space-between;
  padding: 5px 0 !important;
  align-items: flex-start !important;
}

.uploaded-file-list li img {
  margin-right: 6px;
}


.left-uploaded-file-name {
  width: 60%;
  font-size: 12px
}


.left-uploaded-change-file-name button {
  color: #7E7E7E;
  border: 1px solid #7E7E7E;
  background: transparent;
  border-radius: 5px;
  padding: 6px 12px 6px 12px;
  font-size: 10px;
}

.default-template-document {
  margin-bottom: 15px;
}


.short-container {
  max-width: 850px;
  width: 100%;
  margin: 0 auto;
}

ul.signer-steps {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  width: 100%;
}

ul.signer-steps li {
  padding: 0 30px;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  position: relative;
}

ul.signer-steps li::after {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "\f105";
  font-family: 'fontawesome';
}

ul.signer-steps li:last-child:after {
  display: none;
}

.signer-steps span.identify-step-avtar {
  margin-right: 8px;
  background: #fff;
  color: #000;
  text-align: center;
  width: 27px;
  height: 27px;
  display: inline-block;
  border-radius: 100%;
  padding: 2px 0;
}

.signer-steps li.active span.identify-step-avtar {
  background: var(--primary-color);
}

.signer-steps li.active {
  color: var(--primary-color);
}

.signer-body {
  margin-top: 50px;
}

.signer-body-content {
  background: #fff;
  border-radius: 14px;
  padding: 35px 25px;
}

.signer-head h3 {
  text-align: center;
  font-size: 20px;
  position: relative;
}

.signer-head h3 .back {
  position: absolute;
  left: 0;
  font-size: 14px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.signer-footer-nav {
  margin: 2rem 0 0;
  display: flex;
  justify-content: flex-end;
}

.signer-content {
  min-height: 300px;
}


.fileUploadedSection-filelist {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.fileUploadedSection-filelist li {
  border: 1px solid #BDBDBD;
  padding: 7px 10px;
  border-radius: 6px;
  display: inline-block;
  color: #363636;
}

ul.fileUploadedSection-filelist li img {
  margin-right: 5px;
}

.signer-head {
  margin-bottom: 40px;
}

.signer-content .fileUploadedSection h6 {
  font-size: 12px;
  color: #7E7E7E;
  font-weight: 100;
}

.saveAndSendSection {
  margin-top: 4rem !important;
}

.saveAndSendSection h4 {
  font-size: 18px;
  font-weight: 700;
  color: #0A0A0A;
  margin-bottom: 24px;
}

/* .signer-content .signer-page-3 h4 {
  font-size: 16px;
  color: #7e7e7e;
  margin-bottom: 24px;
} */


ul.savesend-filelist {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.savesend-filelist li {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.wrap-file-save-name {
  display: flex;
}

.wrap-file-save {
  margin-right: 20px;
}

.wrap-file-save i {
  font-size: 45px;
}

.wrap-file-name .signerDoc {
  font-size: 16px;
  font-weight: normal;
  color: #363636;
  margin: 0;
  font-family: 'Just-M';
}

.wrap-file-name .signerName {
  font-size: 14px;
  color: #7e7e7e;
  margin: 0;
}

.savesend-filelist .contactus-modal-button i {
  font-size: 12px;
  margin-right: 6px;
}

ul.sender-email-signer {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.sender-email-signer li {
  display: inline-block;
  color: #0a0a0a;
  background: #ECEFF4;
  font-size: 14px;
  line-height: 24px;
  border-radius: 16px;
  padding: 4px 8px;
  margin: 0 10px 10px 0px;
}

.saveAndSendFormSection .input-text.heading {
  font-size: 16px;
  color: #7e7e7e;
  font-weight: 700;
  margin-bottom: 1rem;
  margin-top: 0;
}

.saveAndSendFormSection .additional-margin-step-3 {
  margin-top: 1.5rem !important;
}

.saveAndSendFormSection .import-note {
  color: #9E9E9E;
  line-height: 18px;
}


.signer-footer-nav button.btn.agree-button {
  font-size: 16px;
  padding: 14px 50px;
}

.template-upload-document .upload-here-img-task {
  padding-bottom: 0;
  margin-bottom: 25px;
}

.template-upload-document .upload-here-img-task .upload-img {
  border: 1px dashed #252F40;
  background-color: #fff;
  background-image: url("../public/assets/img/upload-icon-bl.svg");
  background-size: 65px;
  background-position: center top 20px;
  padding-bottom: 120px;
}

.template-upload-document .upload-here-img-task .upload-img .drag-drop-text {
  color: #252F40;
  padding-left: 0;

}

.template-upload-document .upload-here-img-task .upload-img .fileUpload {
  padding: 0 20px;
  bottom: 12px;
}


.signer-content .profile-name {
  background: #ECEFF4;
  color: #0a0a0a;
  padding: 5px 12px;
}

.signer-content span.remove-name {
  cursor: pointer;
  margin-left: 12px;
}

.document-list-file-body .left-uploaded-file-name {
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  color: #0a0a0a;
  margin-bottom: 8px;
}

.document-list-file-body .left-uploaded-file-name img {
  margin-right: 6px
}

.document-list-file-body .left-uploaded-change-file-name {
  font-size: 12px;
  cursor: pointer;
  color: #0a0a0a;
  text-align: center;
  margin-top: 0;
  line-height: 14px;
}

.document-list-file-body .left-uploaded-change-file-name.inprogress {
  color: #7e7e7e;
}

.document-list-view-all {
  text-align: center;
  margin-top: 25px;
}

.document-list-view-all button.btn.view-all {
  color: #252F40;
  border: 1px solid #252F40;
  padding: 6px 15px;
  font-size: 12px;
  border-radius: 6px;
  background: #fff;
  margin: 0;
}

.document-list-file-body .left-uploaded-change-file-name a {
  color: #252F40;
}

.document-list-file-body .left-uploaded-change-file-name i {
  font-size: 11px;
  margin-right: 0px;
}

.document-list-file-header {
  border-bottom: 2px solid #BDBDBD;
}

.document-list-file-header h6 {
  margin: 0;
}

/* iframe {
  position: absolute;
  width: 100%;
  z-index: 32165165161611;
  height: 100%;
} */

.document-list-file-body {
  margin-top: 20px;
}

.document-list-file-body .row {
  padding: 0;
  position: relative;
}

/* .document-list-file-body .row::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 1px;
  width: 100%;
  background: #bdbdbd;
  right: 0px;
}

.document-list-file-body .row:last-child::after{
  display: none;
} */

.document-list-file-body .left-uploaded-file-date {
  font-size: 11px;
  color: #7e7e7e;
}

.document-list-file-body .left-uploaded-file-date .dot {
  display: inline-block;
  height: 2px;
  width: 2px;
  background: #7E7E7E;
  border-radius: 100%;
  vertical-align: middle;
  margin: 0 4px;
}

.left-uploaded-file-holder {
  display: flex;
  justify-content: flex-start;
}

.left-uploaded-file-holder .left-uploaded-file-img {
  margin-right: 8px;
  line-height: normal;
}


.wrap-file-name .signerName span {
  position: relative;
}

.wrap-file-name .signerName span::after {
  content: ", ";
}

.wrap-file-name .signerName span:last-child::after {
  content: "";
}

.signerIndication {
  margin-left: 15px;
  color: #7e7e7e;
}

.doc-pdf-preview {
  /* height: 50vh; */
  overflow-y: auto;
  position: relative;
}


.doc-pdf-preview:hover .page-controls {
  opacity: 1;
}

.page-controls {
  position: absolute;
  bottom: 5%;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: white;
  opacity: 0;
  transition: opacity ease-in-out .2s;
  box-shadow: var(--shadow);
  border-radius: var(--rounded-corners);
  z-index: 2;
  text-align: center;
}

.page-controls-mobile {
  text-align: center;
}

.page-controls span.spanButton,
.page-controls-mobile span.spanButton {
  padding: 0 20px;
  font-size: 22px;
}

p.total-pdf-pages {
  text-align: right;
  padding: 20px 0 0;
  margin-bottom: 40px;
  color: #7e7e7e;
  font-size: 14px;
}


.userList.chat .e-sign-pdf-message .e-sign-pdf-name h6,
.userList.chat .e-sign-pdf-message .e-sign-pdf-size {
  color: #252f40;
}

.userList.chat .e-sign-pdf-message {
  background: #F3C258;
  padding: 13px 10px 0;
  border-radius: 6px;
}

.e-sign-pdf-message {
  display: flex;
}

.e-sign-pdf-message .e-sign-pdf-button {
  display: flex;
  margin-top: 12px;
}

.e-sign-pdf-message .e-sign-pdf-img {
  background: #0A0A0A;
  width: 60px;
  height: 60px;
  padding: 9px 0;
  text-align: center;
  border-radius: 100%;
  margin-right: 12px;
  background-image: url('../public/assets/img/file_pdf.svg');
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center;
}

.e-sign-pdf-message .e-sign-pdf-img img {
  display: none;
}

.e-sign-pdf-button-with-size {
  margin-right: 10px;
}

.e-sign-pdf-message .e-sign-pdf-name h6,
.e-sign-pdf-message .e-sign-pdf-name h6 span {
  font-size: 14px !important;
  line-height: 16px;
  font-weight: 600;
  margin-bottom: 12px;
  padding-left: 0;
  padding-top: 0;
}

.e-sign-pdf-message p.e-sign-pdf-size {
  margin-bottom: 3px !important;
}

.e-sign-pdf-message p.e-sign-pdf-size,
.e-sign-pdf-message p.e-sign-pdf-signer {
  font-size: 10px !important;
  line-height: 12px !important;
  color: #7e7e7e !important;
  padding-right: 0 !important;
}

.e-sign-pdf-message p.e-sign-pdf-signer strong {
  font-weight: 600 !important;
  color: #363636;
}

.wrap-download-file {
  display: flex;
}

.e-sign-pdf-message .wrap-download-file button {
  padding: 4px 10px;
  margin-left: 3px !important;
  font-size: 11px;
  margin-right: 0 !important;
  border-radius: 5px;
}

.e-sign-pdf-message .wrap-download-file button:first-child {
  margin-left: 0;
}

.e-sign-pdf-message .wrap-download-file button i {
  margin-right: 3px;
  font-size: 9px;
}

.message-page .messageRow .messageRight .messageHolder .messageList .ownProperty .messageListInner .e-sign-pdf-message .wrap-download-file button {
  background: #eceff4 !important;
}

.message-page .messageRow .messageRight .messageHolder .messageList .ownProperty .messageListInner .e-sign-pdf-message p.e-sign-pdf-size,
.e-sign-pdf-message p.e-sign-pdf-signer {
  color: #363636 !important;
  font-size: 12px !important;
  line-height: 14px !important;
}

.message-page .messageRow .e-sign-pdf-message .e-sign-pdf-signer-section {
  margin-top: 10px;
}

.message-page .messageRow .e-sign-pdf-message p.e-sign-pdf-signer {
  font-size: 14px !important;
  line-height: 18px !important;
  margin-top: 6px;
}

.envonmentIdentify {
  background: red;
  color: #fff;
  padding: 0;
  position: fixed;
  top: 0px;
  width: 290px;
  z-index: 999999999999999;
}

.disabled-link-sidebar {
  color: #999;
  /* Change to your preferred disabled link color */
  cursor: not-allowed;
  pointer-events: none;
  text-decoration: none;
  opacity: 0.4;
}

/* Buillion Account */

.BuillionAccountPadding {
  padding: 20px 24px;
}

.accountBorderSection {
  background-color: #fff;
  border: 1px solid #BDBDBD;
  border-radius: 16px;
  margin-Top: 24px;
}

.accountPrimaryText {
  font-size: 14px;
  line-height: 24px;
  color: #7e7e7e;
  font-weight: normal;
}

.accountSecondaryText {
  font-size: 18px;
  line-height: 24px;
  color: #363636;
  font-family: 'Just-B';
}

.rightAlignText {
  text-align: right;
}

.cashHederSectionPadding {
  padding: 16px 24px;
}

.cashBottomPadding {
  padding: 16px 24px 24px;
}

.cashHeaderText {
  font-size: 14px;
  line-height: 16px;
  font-family: 'Just-M';
  font-weight: normal;
}

.cashDarkText {
  color: #0A0A0A;
}

.cashGreyText {
  color: #7E7E7E;
}

.currentBalanceText {
  font-size: 14px;
  line-height: 24px;
  color: #7e7e7e;
  padding-bottom: 16px;
}

.currentBalanceDetails {
  font-size: 14px;
  line-height: 24px;
  color: #363636;
  padding-bottom: 16px;
}

.flexSection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cashBottomButton {
  padding-top: 24px;
}

.iconMarginLeft {
  margin-left: 10px;
}

.button_align {
  display: flex;
  align-items: center;
}

.justifySection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skeletonFlexEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: end;
}

.skeletonFlexBB {
  display: flex;
  align-items: center;
}

.HeadericonAlign {
  position: absolute;
  right: 50px;
}

.customInputField {
  height: 25px;
}

.addRowAlignIcon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.textInputAlignText {
  text-align: right;
}

.customFilterButton.save-button {
  padding: 6px 16px;
  margin-bottom: 0px;
}

.deleteIcon {
  display: flex;
  justify-content: center;
}

.addRightPadding {
  padding-right: 21px;
}



.ProductTableInfo {
  width: 220px;
}

.ProductTablePr {
  width: 120px;
}

.suggestedText {
  font-size: 12px;
  line-height: 20px;
  color: #7E7E7E;
  padding-bottom: 20px;
}

.paymentPage .tableHolder.staticTable {
  overflow: hidden;
}

.activeRow {
  background-color: var(--third-color);
  border-radius: 10px 10px 0px 0px;
  /* border-bottom: 1px solid #FFF7DE !important; */
}

.rowBorder {
  border-bottom: 1px solid #BDBDBD;
}

.cursorPointer {
  cursor: pointer;
}

.productHeaderSection {
  background-color: #fff;
  padding: 20px 24px;
  border-bottom: 1px solid #BDBDBD;
}

.productHeaderText {
  font-size: 14px;
  line-height: 20px;
  color: #0a0a0a;
  font-family: 'Just-M';
}

.outerContainerFluid {
  padding: 16px 30px 0px;
}

.ProductDetails {
  padding: 24px 5px;
}

.productDetailsNameSection {
  display: flex;
  align-items: center;
}

.productDetailsName {
  font-size: 14px;
  line-height: 20px;
  text-decoration: underline;
  margin: 0px;
  padding-left: 5px;
  color: var(--secondry-color);
  font-family: 'Just-M';

}

.productDetailsInfo {
  font-size: 14px;
  line-height: 20px;
  color: #363636;
  display: flex;
  /* align-items: center; */
}

.productDetailsInfo.priceInfo {
  font-size: 14px;
  font-family: 'Just-M';
}

.upgradeBtn {
  font-size: 14px;
  line-height: 24px;
  padding: 6px 0;
  background-color: var(--primary-color);
  color: var(--secondry-color);
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  width: 120px;
}

.BackgroundPlanBankTransfer {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 48px 32px 24px;
}

.planTitle {
  font-size: 32px;
  line-height: 38px;
  font-family: 'Just-EB';
  color: var(--secondry-color);
  max-width: 480px;
  width: 100%;
}

.recumentPrice {
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  padding: 6px 20px;
  border-radius: 20px;
  background-color: var(--secondry-color);
  width: -moz-fit-content;
  width: fit-content;
}

.planAlignmentSection {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 46px;
  padding-top: 12px;
}

.planDescription {
  font-size: 14px;
  line-height: 24px;
  color: #0a0a0a;
  margin: 0px;
  width: 100%;
}

.planDescription p {
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  color: #0a0a0a;
  margin-bottom: 8px;
}

.planDescription ul,
.planDescription ol {
  font-size: 14px;
  line-height: 20px;
  margin: 0 0px 0px 30px;
  color: #0a0a0a;
}

.bottomPlanBankTransfer.top-section {
  padding: 20px 32px 0;
}

.bottomPlanBankTransfer {
  padding: 0px 32px 20px;
  overflow-y: scroll;
  max-height: 280px;
}

.bottomPlanBankTransfer.bottom-section {
  padding: 0 32px 5px;
}

.BottomBankPlantitle {
  font-size: 14px;
  line-height: 24px;
  color: var(--secondry-color);
  margin: 0px;
  padding-bottom: 12px;
  font-weight: normal;
  font-family: 'Just-M';
}

.youAreAssignReceive {
  display: flex;
  align-items: center;
  padding: 0px 10px 8px 0px;
  position: relative;
}

.youAreAssignReceive>img {
  /* width: 15px; */
  padding-top: 0px;
}

.youAreAssignReceive>p {
  font-size: 14px;
  line-height: 18px;
  color: #7E7E7E;
  margin: 0px;
  padding-left: 6px;
}

.footerBankPlan {
  padding: 20px 32px;
  background-color: var(--secondry-color);
}

.choosePayAlign {
  display: flex;
  align-items: center;
}



.choosePaySelect {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 12px;
  background-color: #fff;
  border-radius: 10px;
  margin-right: 8px;
  cursor: pointer;
  max-width: 120px;
}

.choosePaySelectActive {
  background-color: var(--primary-color);
}

.choosePaySelect>img {
  width: 24px;
}

.choosePaySelect>h6 {
  font-size: 12px;
  line-height: 14px;
  font-family: 'Just-M';
  padding-left: 7px;
  color: var(--secondry-color)
}

.icon-flex-button {
  display: inline-flex;
  width: 100%;
}

.titlechoosePay {
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  padding-right: 16px;
  font-weight: normal;
}

.upgradePlanSection {
  justify-content: end;
}

.upgradePlanBtn {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  padding: 8px 15px;
  cursor: pointer;
}

.upgradePlanBtn>h6 {
  font-size: 14px;
  line-height: 24px;
  margin: 0px;
  color: var(--secondry-color);
}

.suggestedRightSection {
  border-radius: 10px;
  margin-top: 12px;
}

.upgrad-modal-width.SuggestModalCustom .modal-dialog {
  max-width: 800px;
}

.customSide {
  padding-top: 50px;
}

.closeSuggestedModal {
  position: absolute;
  top: 10px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.closeSuggestedModal>i {
  cursor: pointer;
}

.paymentPage .tableHolder .tableBody tr td .planName p.namePurpose {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  font-family: 'Just-M';
}

.planType {
  margin: 0px;
  font-size: 14px;
  line-height: 20px;
  padding-top: 6px;
  color: #7e7e7e;
}

.transDateTimeWidth {
  width: 120px;
}

.paymentPage .tableHolder .tableBody tr td.transAmount {
  font-size: 14px;
  line-height: 20px;
  color: #0a0a0a;
  font-weight: normal;
  font-family: 'Just-M';
}

.paymentPage .tableHolder .tableBody tr td.transPayment {
  color: #252F40;
}

.paymentPage .tableHolder .tableBody tr td button.pendingButton.transAttemptedPay {
  color: #7e7e7e;
  border-radius: 6px;
  border: 1px solid #BDBDBD;
  width: 135px;
  text-transform: capitalize;
}

.paymentPage .tableHolder .tableBody tr td button.pendingButton.transAttemptedPay.paid {
  background: #FFF7DE;
}

.upperCaseText {
  text-transform: uppercase;
  width: 90px;
}

.bankTransferInput {
  display: flex;
  align-items: center;
}

.errorMessagePadding {
  padding-left: 170px;
}

.form-control.bankTransferField {
  width: 60%;
}

.form-control.bankTransferField.bankTransferFieldAmount {
  width: 80%;
}

.form-control.bankTransferField.bankTransferFieldAmount.disabled {
  background: #e9ecef;
}

.bankPage .innerDetail .inputHolder .select-border.bankTransferSelectField {
  width: 64%;
}

.bankPage .innerDetail .inputHolder .label.instructionLabel {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.instructionIcon {
  padding-left: 5px;
  cursor: pointer;
}

.olPadding {
  padding-left: 12px;
}

.textUpperCase {
  text-transform: uppercase;
}

.cursorPointer {
  cursor: pointer;
}

.btnToggle {
  background-color: var(--primary-color);
  color: #000;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  width: fit-content
}

.tableButtonpayup {
  border-radius: 8px;
  background: var(--primary-color);
  color: var(--secondry-color);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.3px;
  border: 0;
  padding: 6px 10px;
}

.paidIp-Capital {
  min-width: 180px;
  max-width: 180px;
}

.paidupCurrency {
  min-width: 110px;
  max-width: 110px;
}

.paidupShareType {
  min-width: 140px;
  max-width: 140px;
}

/* .paidStatus-Capital{
  min-width:140px;
  width:140px;
} */
.paidupAction {
  max-width: 120px;
  min-width: 120px;
}

.btn.btn-link.invoice-id-amount,
.btn.btn-link.invoice-id-amount:hover.payment-trans {
  color: var(--primary-color);
  font-size: 14px;
}

.productAlign {
  position: relative;
  right: 9px;
}

h5.edit-title {
  font-size: 22px;
  line-height: 24px;
  margin-bottom: 20px;
  color: #0a0a0a;
  font-weight: normal;
  font-family: 'Just-M';
}

.tableHrsection {
  padding: 0px 20px;
}


.table-responsive table.table-sharholder.table-files tr th.table-heading {
  font-size: 16px !important;
  line-height: 24px;
  color: #0a0a0a;
  font-family: 'Just-M';
}

.table-responsive table.table-sharholder.table-files {
  padding-left: 1rem;
  padding-right: 1rem;
  min-width: 100%;
  width: 100%;
}

.table-responsive table.table-sharholder.table-files .text-row-color tr td.file-name-row {
  width: auto !important;
}

.upload-files-border {
  border: 1px solid #BDBDBD;
}

.text-status span.rangeAlign {
  padding-left: 10px;
}

.alignSelect {
  align-items: center;
}

.selectAssignStatus {
  padding-left: 10px;
}

.selectAssignStatus span {
  padding-left: 0px !important;
}

.profileActivity {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.highlightOpenDashboard {
  margin-right: 20px;
  list-style: none;
}

.highlightOpenDashboard a {
  color: #fff;
}

.activeLogPadding {
  padding-top: 10px;
}

.locationAlignCenter {
  align-items: center;
}

.businessTitle {
  display: flex;
  align-items: center;
}

.marginLeftAutoMD {
  margin-left: auto;
}

.agreeContinue {
  padding: 10px 30px;
}

.textIconSize {
  height: 12px;
}

.animateSpin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

ul.navbar-nav.submenu-nav-item {
  margin-left: 15px;
}

ul.navbar-nav.submenu-nav-item li {}

span.iconAngle {
  position: absolute;
  right: 0;
}

.display-single-line {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.contactus-modal-button.lightTheme {
  /* border-color: #fff !important;
  width: auto;
  color: #fff !important; */
  margin: auto !important;
}

.contactus-modal-button.sidePaymentBanner {
  padding: 15px 25px;
}


.activitySegment ul.segmentUL {
  list-style: none;
  padding: 0;

}

.activitySegment ul.segmentUL li {
  color: #363636;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  padding-left: 20px;
  position: relative;
}

.activitySegment ul.segmentUL li::before {
  content: "\f00c";
  font-family: "FontAwesome";
  position: absolute;
  left: 0;
  top: 1px;
}


#spotlight-outlet>div {
  z-index: 999999 !important;
}

.skeletonHeight {
  display: flex;
  align-items: center;
  justify-content: left;
  height: 15px;
  margin: 5px 0px;
}

.searchSkeleton {
  width: 100%;
  padding: 0 30px;
}

/* react-joyride__tooltip */

.react-joyride__beacon>span {
  background-color: var(--secondry-color) !important;
  border: 2px solid var(--secondry-color) !important;
}

.react-joyride__tooltip {
  background-color: #fff !important;
  color: var(--secondry-color) !important;
  border-radius: 20px !important;
  padding: 0px !important;
}

.react-joyride__tooltip>div:nth-child(2) {
  padding: 0px 15px 15px;
  display: block !important;
}

.react-joyride__tooltip>div:nth-child(2)>div {
  position: absolute;
  top: 10px;
  right: 12px;
}

.react-joyride__tooltip>div:nth-child(2)>div>button {
  color: white !important;
}


.react-joyride__tooltip>div>h1 {
  color: var(--primary-color) !important;
  background-color: #000;
  padding: 12px 16px !important;
  border-radius: 20px 20px 0px 0px;
  text-align: justify;
}

.react-joyride__tooltip>div>button:nth-child(2) {
  background-color: #fff !important;
  border: 1px solid #7E7E7E !important;
  margin-right: 15px !important;
  /* pointer-events: none; */
}

.react-joyride__tooltip>div>button {
  border: 1px solid var(--primary-color) !important;
  background-color: var(--primary-color) !important;
  color: var(--secondry-color) !important;
  border-radius: 10px !important;
  padding: 10px !important
}

.__floater__arrow>span>svg>polygon {
  fill: var(--secondry-color);
}


/* react-joyride__tooltip End*/

.footerSection {
  padding: 20px;
}

.footerLeftText {
  font-size: 14px;
  line-height: 20px;
  color: #000;
}

.linkSection {
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 75px;
}

.linkSection>div {
  padding-left: 15px;
  cursor: pointer;
}

.linkSection>div>a {
  font-size: 14px;
  line-height: 20px;
  color: #000;
}

.minHeightVH {
  min-height: 100vh;
}

.bid-gold-price li {
  padding: 0 5px;
}

.bid-gold-price li:last-child {
  border-left: 2px solid #7E7E7E;
  padding: 0 2px 0 2px;
  margin-left: 5px;
}

/* .bid-gold-price li:first-child {
  border-left: 0;
  padding-left: 0;
} */

.bid-gold-price li .bidCurrencyHolder {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.bid-gold-price .bidName {
  color: var(--primary-color);
  font-family: 'Just-M';
  font-size: 12px;
  line-height: 14px;
  margin: 0;
}

.bid-gold-price .bidName img {
  width: 15px;
  margin-left: 5px;
  margin-right: 0;
}

.bid-gold-price .bidCurrency {
  background: #4BA88D;
  margin: 0 0 0 7px;
  padding: 7px 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bid-gold-price .bidCurrency.up {
  background: #4BA88D;
}

.bid-gold-price .bidCurrency.down {
  background: #9C1515;
}


.bid-gold-price .bidCurrency img.bidInflation {
  margin-right: 6px;
  height: 12px;
}

.bid-gold-price .bidCurrency img.bidBuy {
  width: 23px;
  margin-left: 6px;
}

.bid-gold-price .bidCurrency p {
  margin: 0;
  color: #FFF7DE;
  font-family: 'Just-M';
  font-size: 10px;
  line-height: 14px;
}

.bid-gold-price .bidCurrency button.bidBuy {
  background: #FFF7DE;
  border: 0;
  border-radius: 6px;
  margin-left: 10px;
  font-size: 12px;
}

.messageListOuter.tempFocus {
  background: #eceff4;
  border-radius: 5px;
}

.messageExternalLinkCompanyChange,
.messageExternalLinkCompanyChange:hover {
  color: #0a0a0a;
  font-weight: 800;
  text-decoration: underline;
  cursor: pointer;
}

.paymentPage .tableHolder .tableBody tr td button.tableButton[disabled] {
  background: var(--Light-Gray, #eceff4);
  color: #7e7e7e;
}

.paymentPage .tableHolder .tableBody tr td button.tableButton[disabled]>img {
  opacity: 0.3;
}

.companyWidth {
  width: 280px;
}

.profileCardBody .tableResponsive.scrollBOB {
  scrollbar-color: rgba(44, 44, 44, 0.1) transparent;
}

.paywithcard.disclaimer p {
  font-size: 14px;
  color: #0a0a0a;
  line-height: 18px;
  margin-bottom: 0;
}

button.btn.card-btn.popup.speakToAdmin {
  border: 1px solid var(--secondry-color) !important;
  color: var(--secondry-color);
  background-color: transparent !important;
  padding: 11px 15px !important;
  font-size: 14px;
  line-height: 16px;
  margin-right: auto !important;
}

.overflowScroll {
  overflow-x: auto !important;
}

.profileHolder .profileCard .profileCardBody .profileTable tbody tr td .fill-primary.edit {
  background: var(--primary-color);
  border-radius: 8px;
  border: 1px solid var(--primary-color);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 32px;
  cursor: pointer;
  margin-left: 5px;
}

.profileHolder .profileCard .profileCardBody .profileTable tbody tr td .fill-primary.edit i {
  color: #fff;
}

span.extraSmall {
  font-size: 12px;
  line-height: 14px !important;
  display: block;
}

.btn-secondary:disabled,
.btn-secondary.disabled {
  background-color: #bdbdbd !important;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.07);
  opacity: 1;
}

.btn.btn-link.edit-button.faded-edit {
  border: 0;
  cursor: auto;
}

.bobContactUS {
  display: inline-block;
  margin-right: 12px !important;
}

.sidenav-header {
  text-align: left;
  padding-left: 16px;
}

@media screen and (max-width: 767px) {
  /* #react-joyride-step-1>div{
    top: 38px !important;
  } */

  ul.savesend-filelist li {
    display: inline-block;
    width: 100%;
  }

  .wrap-download-file {
    margin-top: 20px;
  }

  ul.signer-steps {
    position: static;
  }

  .media-content.esignMedia {
    padding-top: 15px !important;
  }

  .conversation-section>.inner-section {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
  }

  .signer-content .conversation-section>.inner-section {
    flex-direction: column;
  }

  .signer-content .profile-name {
    margin-left: 0;
  }

  .signer-content .signerIndication {
    font-size: 12px;
    margin-left: 0;
  }

  ul.signer-steps li {
    font-size: 11px;
    line-height: 14px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .signer-steps span.identify-step-avtar {
    line-height: 24px;
    margin-right: 0;
    margin-bottom: 3px;
  }


  .e-sign-pdf-message {
    flex-direction: column;
  }

  span.iconAngle {
    position: absolute;
    right: 20px;
  }

  .needHelpHolder {
    width: 45px;
    height: 45px;
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.3);
  }

  .needHelpHolder img {
    width: 24px;
  }

  .react-joyride__tooltip {
    border-radius: 6px !important;
    width: 250px !important;
  }

  .react-joyride__tooltip>div>h1 {
    border-radius: 6px 6px 0px 0px;
    font-size: 14px !important;
  }

  .react-joyride__tooltip>div>h1+div {
    font-size: 12px !important;
  }

  .react-joyride__tooltip>div>button {
    font-size: 10px !important;
    margin-right: 5px !important;
  }

  .react-joyride__tooltip>div>button:nth-child(2) {
    margin-right: 5px !important;
    padding-bottom: 0;
  }

  .mobile-activity tr {
    display: flex;
    flex-direction: column;
  }

  .table tbody.mobile-activity tr td {
    width: 100%;
  }

  .table tbody.mobile-activity tr td.text-right {
    position: absolute;
    top: -11px;
    width: auto;
    right: 0;
  }

  .table-responsive table.table-sharholder {
    min-width: 1040px;
    width: 930px;
  }

  .contactus-modal-button.sidePaymentBanner {
    padding: 15px 35px;
    width: 100%;
    margin-bottom: 10px !important;
  }

  .message-page .messageRow .userProfile .profile-body ul .listHolder {
    padding: 30px 16px;
  }

  .typeOfFiles button.accountBtn {
    line-height: 14px;
  }

  .messageTextarea {
    height: 120px;
  }

  #style-1::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  #style-1::-webkit-scrollbar {
    width: 15px;
    /* background-color: #F5F5F5; */
  }

  #style-1::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #9e9e9e;
    background-clip: padding-box;
    border: 4px solid rgba(0, 0, 0, 0);
  }

  .shareholder-img {
    height: 34px;
    width: 34px !important;
    min-width: 34px;
    border-radius: 5px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    float: left;
    margin-right: 10px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  .action-heading,
  .status-heading {
    text-align: center;
  }

  .action-icons>span {
    color: #252f40 !important;
  }

  .action-icons.status-type {
    text-align: center;
  }

  .status-type {
    text-align: center;
  }

  .search-inner-section {
    font-size: 12px;
    line-height: 13px;
    font-weight: 700;
  }

  .search-inner-section>img {
    height: 36px;
    width: 36px;
    margin-right: 10px;
    border-radius: 5px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  .search-inner-section>span {
    font-size: 10px;
    line-height: 11.49px;
    font-style: italic;
    margin-left: 10px;
  }

  .appointed>td>span,
  .appointed>td>div>div>h6,
  .appoint .shareCreateDate {
    color: var(--secondry-color);
  }

  .appoint>td>span,
  .appoint>td>div>div>h6,
  .appoint .shareCreateDate {
    color: #f5b43f;
  }

  .appoint-progress-button {
    font-weight: 500;
    font-size: 9px;
    line-height: 10px;
    text-align: center;
    letter-spacing: -0.270067px;
    color: #f5b43f;
    background: #fff9ed;
    border-radius: 6px;
    padding: 7px 10px;
    border: 1px solid #f5b43f !important;
    margin: 0px;
  }

  .appoint-progress-button:hover {
    color: #f5b43f;
  }

  .pending-button-second {
    font-weight: 500;
    text-align: center;
    font-size: 9px;
    line-height: 10px;
    color: #7e7e7e;
    background: #d2d6da;
    border-radius: 6px;
    padding: 5px 8px;
    border: 1px solid #7e7e7e !important;
  }

  .pending-button-second:hover {
    color: #7e7e7e;
  }

  .active-button-second {
    color: var(--primary-color);
    background: #d1eae3;
    border: 1px solid var(--primary-color) !important;
  }

  .active-button-second:hover {
    color: var(--primary-color);
  }

  .shareHolderName {
    font-size: 12px;
    line-height: 13.79px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .limit-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 140px;
  }

  .email-share {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #9e9e9e;
  }

  .header-promoters {
    text-align: center;
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    padding-bottom: 24px;
    color: #252f40;
  }

  .invite-page h6.table-heading {
    color: #252f40;
    font-weight: 700;
  }

  .invite-page h3.business-font.capital-head.high-cap {
    color: #52594d;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .invite-page div.no-record {
    padding-left: 1rem !important;
  }

  .businessTitle {
    padding-bottom: 10px;
  }

  .businessVerification {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .phil-text {
    width: 280px;
    padding: 10px 5px;
    font-size: 16px;
  }

  .bankPage .planDetail .planFooter {
    position: static;
    width: auto
  }

  .bankPage .planDetail .planFooter p {
    line-height: 12px;
  }

  .bankPage .outerRow .bankDetailNotify {
    width: 100%;
  }

  .activelogWidth {
    width: max-content
  }

  .choosePaySelect {
    width: 100%;
    max-width: 100%;
    margin: 0px;
    margin-bottom: 10px;
  }

  .upgradePlanBtn {
    width: 100%;
    justify-content: center;
  }

  h3.business-font.capital-head.high-cap {
    font-size: 14px;
  }

  .fullWidth {
    width: max-content;
  }

  .bankPage .submitSection.innerDetail .submitBtns button.backButtonMD {
    width: 40%;
    margin-right: 10px;
  }

  .bankPage .submitSection.innerDetail .submitBtns button.upgradeBtnMD {
    width: 60%;
  }

  .bankPage .submitSection.innerDetail .submitBtns button.backToBankMD {
    width: 72%;
    margin-right: 10px;
  }

  .bankPage .submitSection.innerDetail .submitBtns button.CompleteMD {
    width: 28%;
  }

  .linkSection {
    flex-direction: row;
    padding: 0;
    justify-content: space-between;
  }

  .linkSection>div {
    padding: 10px 0px 0px;
    line-height: 18px;
    text-align: center;
  }

  .linkSection>div>a {
    font-size: 10px;
    line-height: 14px;
  }

  .footerLeftText {
    text-align: center;
    font-size: 12px;
    line-height: 18px;
  }

  .media-content .mobile-gold-bid-holder ul.bid-gold-price {
    position: static;
  }

  .mobile-gold-bid-holder ul.bid-gold-price li .bidCurrencyHolder {
    flex-direction: column;
    align-items: flex-start;
  }

  .media-content .mobile-gold-bid-holder ul.bid-gold-price .bidCurrency.up,
  .media-content .mobile-gold-bid-holder ul.bid-gold-price .bidCurrency.down {
    background: transparent;
    padding: 0;
    margin: 0;
  }


  .bid-gold-price li {
    border-left: 1px solid #7E7E7E;
    padding: 0 0 0 7px;
    margin-left: 10px;
  }

  .bid-gold-price li:first-child {
    border-left: 0px;
    margin-left: 0px;
    padding-left: 0px;
  }

  .bid-gold-price li:last-child {
    padding: 0 0px 0 0px;
    margin-left: 10px;
    border-left: 1px solid #7E7E7E;
  }

  .bid-gold-price .bidName {
    font-family: 'Just';
    font-size: 8px;
  }

  .bid-gold-price .bidCurrency p {
    font-family: 'Just';
    font-size: 10px;
  }

  .bid-gold-price .bidName img {
    width: 12px;
  }

  .paymentPage .planHolder .planName h4 {
    font-size: 16px;
    line-height: 20px;
  }

  .errorMessage,
  .importModal .header p span.errorMessage {
    font-size: 14px;
    line-height: 18px;
  }

}

@media screen and (max-width: 767px) {

  .container-top {
    margin-top: 0px;
  }

  .envonmentIdentify {
    position: relative;
    width: 100%;
    z-index: 1;
  }

  .inner-container-fluid {
    padding: 0;
    max-width: 92%;
  }

  .signIn-back {
    background-image: unset;
  }

  .signup-back .page-header {
    margin-top: 0 !important;
    border-radius: 0px 0px 72px 72px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top center;
    padding-bottom: 180px !important;
    min-height: auto !important;
  }

  .modal-content {
    padding: 2rem 1.5rem;
  }

  .guide-modal .modal-dialog .modal-content {
    border-radius: 10px;
  }

  .agree-button {
    padding: 9px 10px;
    justify-content: center;
    width: max-content;
  }

  .filter-button .btn.btn-secondary::after {
    content: "";
    top: 74%;
    font-size: 20px;
  }

  .footer-row {
    margin-top: 0;
  }

  .table-trust-moblie {
    width: 100%;
  }

  .table-trust-moblie .verification-section>div:nth-child(1),
  .table-trust-moblie .verification-section>div:nth-child(2) {
    margin-bottom: 30px;
  }

  .table-responsive.overflowHidden {
    overflow: auto;
  }

  .register-col {
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    padding-right: calc(var(--bs-gutter-x) * 0.5);
  }

  .navbar-vertical.navbar-expand-xs .navbar-nav.separate-link>.nav-item {
    padding: 5px 0;
  }

  .view-all-button {
    padding: 10px 10px;
    font-size: 10px;
  }

  .sidenav-header.emptyheaderDesktop {
    display: inline-block;
  }

  .share-officer h6 {
    width: max-content;
  }

  .mobileViewImportButton,
  .mobileViewImportButton.customImportButton {
    font-size: 10px !important;
    line-height: 14px !important;
    padding: 8px 10px !important;
  }

  .HeadericonAlign {
    position: static;
    padding-bottom: 10px;
  }

  .buttonRIghtMargin {
    margin-right: 5px !important;
  }

  .textInputAlignTextM {
    text-align: left;
  }

  .mobileAlign {
    justify-content: space-between;
    padding-top: 5px;
  }

  .addRightPadding {
    padding-right: 8px;
  }

  .customSide {
    padding-top: 0px;
    padding-bottom: 35px;
  }

  .productOverFlowSection {
    overflow: scroll;
  }

  .outerContainerFluid {
    padding: 0px 10px;
  }

  .planAlignmentSection {
    display: block;
    padding-bottom: 25px;
  }

  .upgradePlanSection {
    padding-top: 8px;
  }

  .choosePayAlign {
    padding-top: 8px;
    flex-direction: column;
    justify-content: center;
  }

  .choosePayAlignMobile {
    display: block;
  }

  .productHeaderSection {
    width: 277%;
  }

  .ProductDetails {
    width: 290%;
  }

  .paymentPage .planHolder .planName .upgradePlan {
    padding-top: 30px;
  }

  .titlechoosePay {
    text-align: center;
    padding-right: 0px;
  }


  .addDashboard {
    padding: 15px 20px !important;
    margin-top: 20px;
  }

  .addDashboard p {
    line-height: 18px;
    padding-bottom: 10px;
  }

  .BuillionAccountPadding {
    padding: 0 20px 25px;
    margin-top: 0;
  }

  .accountPrimaryText {
    font-size: 12px;
    line-height: 18px;
  }

  .accountSecondaryText {
    font-size: 14px;
    line-height: 20px;
  }

  .cashHederSectionPadding {
    padding: 15px 20px;
  }

  .cashBottomPadding {
    padding: 15px;
  }

  .currentBalanceDetails,
  .currentBalanceText {
    line-height: 16px;
    font-size: 12px;
  }

  .paymentPage .segmentHolder .segment {
    margin-right: 10px;
  }

  .paymentPage .segmentHolder .segment p {
    font-size: 12px;
    width: max-content;
  }

  .add-button {
    padding: 10px !important;
    font-size: 12px;
    line-height: 20px;
  }

  .paymentPage .segmentHolder {
    width: 100%;
    overflow: scroll;
  }

  .filter-button .btn.btn-secondary,
  .filter-button .btn.btn-secondary:focus,
  .filter-button .btn.btn-secondary:hover {
    padding: 10px 25px 0px 0px;
  }

  .profile-form {
    padding: 20px;
  }

  .arrowAlign {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  .stepPagination {
    margin: 0px 10px;
  }

  .alignCenter {
    text-align: center;
  }

  .upload-holder .upload-btn {
    padding: 7px 15px;
  }

  .contactus-modal-button {
    padding: 10px 15px;
  }

  .whole-task-section .customScroll {
    height: auto;
    padding-top: 10px;
  }

  .marginLeftAutoMD {
    margin-left: inherit;
  }

  .paddingBottomMD {
    padding-bottom: 100px !important;
  }

  .card-formalize h6 {
    font-size: 12px;
  }

  .card-formalize ul li {
    font-size: 12px;
    line-height: 16px;
  }

  .card-formalize button,
  .card-formalize button:disabled,
  .card-formalize a {
    font-size: 12px;
    line-height: 16px;
  }

  .planTitle {
    font-size: 22px;
    line-height: 26px;
  }

  .recumentPrice {
    font-size: 14px;
    line-height: 20px;
    padding: 5px 12px;
  }

  .planDescription {
    font-size: 12px;
    line-height: 18px;
  }

  .BottomBankPlantitle {
    font-size: 12px;
    line-height: 18px;
  }

  .youAreAssignReceive>p {
    font-size: 12px;
    line-height: 16px;
  }
}


/* Apply landscape-specific styles */
@media screen and (orientation: landscape) {
  body {
    /* Disable scrolling to prevent landscape view */
    overflow: hidden;
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  .openDashboard {
    margin-top: 5px;
  }


  .mobileViewImportButton,
  .mobileViewImportButton.customImportButton {
    font-size: 10px !important;
    line-height: 14px !important;
    padding: 8px 10px !important;
  }

  .bankPage .submitSection.innerDetail .submitBtns button.CompleteMD {
    width: 28%;
  }

  .cashBottomPadding {
    padding: 16px 20px;
  }

  .bankPage .submitSection.innerDetail .submitBtns button.backToBankMD {
    width: 72%;
    margin-right: 10px;
  }

  .upgradePlanBtn {
    padding: 8px 20px;
  }

  .bankPage .innerDetail .inputHolder .label {
    max-width: 78px;
  }

  .errorMessagePadding {
    padding-left: 78px;
  }

  .whole-task-section .customScroll {
    height: 36vh;
  }

  .contactus-modal-button {
    padding: 10px 15px;
  }

  .agree-button {
    padding: 10px 15px;
  }

  .upload-holder .upload-btn {
    padding: 7px 20px;
  }

  .colWidth-mobile {
    width: 100%;
    height: 60px;
  }

  .liAlign {
    display: flex;
    align-items: center;
  }

  .typeOfFiles button.accountBtn {
    padding: 26px 10px;
  }

  .collapsediv .customButtonHolder {
    padding: 0px 25px 0px 37px;
  }

  .message-page .messageRow .messageRight .messageHolder .messageList .ownProperty .messageListInner {
    max-width: 90%;
  }

  .message-page .messageRow .messageRight .messageHolder .messageList .messageListInner {
    max-width: 90%;
  }

  .envonmentIdentify {
    position: relative;
    width: 100%;
  }

  .bankPage .outerRow .bankDetailNotify {
    width: 100%;
  }

  .bankPage .planDetail .planTerms ol li {
    font-size: 10px;
    line-height: 18px;
  }

  .container-fluid.inner-container-fluid.py-4.bankPage {
    padding-left: 30px;
    padding-right: 30px;
  }

  .paymentPage .planHolder .planName h4 {
    font-size: 24px;
    line-height: 30px;
  }

  .productOverFlowSection {
    overflow: scroll;
  }

  .productHeaderSection {
    width: 223%;
  }

  .ProductDetails {
    width: 240%;
  }

  .paymentPage .paymentHeader {
    align-items: flex-end;
  }

  .upload-here-img .upload-btn {
    align-items: baseline;
  }

  .fileUpload {
    padding: 0px 10px;
  }

  .inner-container-fluid {
    padding: 0px 30px;
  }

  .agree-button {
    justify-content: center;
  }

  .sidebaralign {
    align-items: flex-start;
    flex-direction: column;
  }

  .filter-button .btn.btn-secondary::after {
    content: "";
    top: 74%;
    font-size: 20px;
  }

  .paddingBottomMD {
    padding-bottom: 100px !important;
  }


  .media-content .mobile-gold-bid-holder ul.bid-gold-price {
    position: static;
  }

  .mobile-gold-bid-holder ul.bid-gold-price li .bidCurrencyHolder {
    flex-direction: column;
    align-items: flex-start;
  }

  .media-content .mobile-gold-bid-holder ul.bid-gold-price .bidCurrency.up,
  .media-content .mobile-gold-bid-holder ul.bid-gold-price .bidCurrency.down {
    background: transparent;
    padding: 0;
    margin: 0;
  }


  .bid-gold-price li {
    border-left: 2px solid #7E7E7E;
    padding: 0 0 0 7px;
    margin-left: 10px;
    min-width: 100px;
  }

  .bid-gold-price li:first-child {
    border-left: 0px;
    margin-left: 0px;
    padding-left: 0px;
  }

  .bid-gold-price li:last-child {
    padding: 0 0px 0 0px;
    margin-left: 10px;
  }

  .bid-gold-price .bidName {
    font-family: 'Just';
    font-size: 12px;
  }

  .bid-gold-price .bidCurrency p {
    font-family: 'Just';
    font-size: 14px;
  }

  .bid-gold-price .bidName img {
    width: 12px;
  }

  .g-sidenav-show .navbar:not(.sidenav).navbar-main .navbar-collapse,
  .g-sidenav-show .navbar:not(.mixed-navbar) .navbar-collapse {
    justify-content: flex-end;
  }
}

@media only screen and (min-width: 1030px) and (max-width: 1366px) {
  .bankPage .outerRow .bankDetailNotify {
    width: 100%;
  }

  .bankPage .planDetail .planTerms ol li {
    font-size: 10px;
    line-height: 18px;
  }

  .container-fluid.inner-container-fluid.py-4.bankPage {
    padding-left: 30px;
    padding-right: 30px;
  }

  .editConvoInput {
    min-width: 120px;
    width: 100%;
    max-width: 120px;
  }

  .message-page .messageRow .userProfile .profile-header {
    padding: 45px 12px 20px
  }

  .message-page .messageRow .userProfile .profile-header .btn-close {
    top: 12px;
    right: 11px;
    font-size: 12px;
  }

  .userGuide {
    min-width: 110px;
  }

  .navbar-expand-lg .navbar-collapse {
    justify-content: flex-end;
  }

  .mobile-gold-bid-holder {
    margin-bottom: 1px;
  }

  .bid-gold-price li:first-child {
    padding-left: 0;
  }
}

@media only screen and (min-width: 921px) and (max-width: 1366px) {
  .choosePaySelectHolder {
    flex-direction: column;
    padding: 12px 2px;
    align-items: flex-start;
  }

  .choosePaySelectHolder img {
    margin: 0;
    width: 30%;
  }
}



@media only screen and (min-width: 1366px) and (max-width: 1470px) {
  .choosePaySelectHolder {
    padding: 12px 2px;
  }

  .choosePaySelectHolder img {
    margin-right: 5px;
    width: 30%;
  }
}

.editPayout .btn.btn-link.edit-button.payoutBalanceButton {
  margin-right: 16px;
  padding: 10px 10px !important;
}