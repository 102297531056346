/* .landingPage .banner {
  background: #74141d;
  border-radius: 8px;
  padding: 15px;
  position: relative;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
} */

.landingPage .banner {
  background: #74141d;
  border-radius: 8px;
  padding: 15px 60px;
  position: relative;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.landingPage .banner.success {
  background: var(--primary-color);
}

.landingPage .banner p {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  display: inline-block;
  margin-right: 32px;
}

.landingPage .banner.success p {
  color: #000;
}

.landingPage .banner.success .bannerBtn {
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  color: var(--primary-color);
}

.landingPage .banner .bannerBtn {
  background: #ffffff;
  border: 1px solid #74141d;
  box-shadow: 0px 10px 5px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #74141d;
  padding: 7px 24px;
  overflow: hidden;
  min-width: 180px;
}

.landingPage .banner .close-banner {
  background: transparent;
  border: none;
  position: absolute;
  right: 15px;
}

.landingPage .banner.success .close-banner {
  color: #000;
}

.latestTable .latestTableTd p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  color: #0a0a0a;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 176px;
}

.latestTable .latestTableTd.text-center p {
  margin: 0 auto;
}

.latestTable .latestTableTd span {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #67748e;
}

.landingPage .edit-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #252f40;
}

.landingPage .edit-description {
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  color: #252f40;
  margin-bottom: 0;
  margin-top: 24px;
}

.landingPage .verfiyDesc {
  padding-left: 40px;
}

/* .landingPage .verifyForm {
  background: #ffffff;
  box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 32px 48px;
} */

.landingPage .verifyForm .form-group .heading {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #252f40;
}

.landingPage .verifyForm .form-group .company-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #363636;
  margin-bottom: 0;
}

.landingPage .verifyForm .form-group .verifyComDesc {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #7e7e7e;
  margin-bottom: 0;
  margin-top: 8px;
}

.landingPage .verifyForm .form-group .select-border {
  margin-top: 6px;
}

.landingPage .verifyForm .modal-footer.modal-bottom-action {
  justify-content: center;
}

.landingPage .verifyForm .verifyIndicator {
  background: #ffffff;
  border: 2px solid #9e9e9e;
  border-radius: 20px;
  text-align: center;
  padding: 27px 25px;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.landingPage .verifyForm .verifyIndicator.active {
  border: 2px solid var(--primary-color);
}

.landingPage .verifyForm .verifyIndicator button {
  background: var(--secondry-color);
  border-radius: 13px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.331915px;
  color: var(--primary-color);
  border: 0;
  padding: 4px 14px;
}

.landingPage .verifyForm .verifyIndicator img {
  margin-top: 24px;
}

.landingPage .verifyForm .verifyIndicator h5 {
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #0a0a0a;
  margin-top: 24px;
  margin-bottom: 10px;
  font-family: 'Just-M';
}

.landingPage .verifyForm .verifyIndicator p {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #363636;
  margin: 0;
  letter-spacing: 0.6px !important;
}

.landingPage .verifyForm .form-group .stepDiv {
  margin-top: 24px;
}

.custom-plan-modal .modal-dialog .modal-content {
  padding: 0;
  border-radius: 12px;
  border: 0;
  overflow: hidden;
}

.custom-plan-modal .modal-dialog .modal-content .modal-body {
  padding: 0;
}

.custom-plan-modal .modal-dialog .modal-content .modal-body .planBack {
  /* background-image: url("../../../public/assets/img/planBack.png"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* padding-top: 374px; */
  position: relative;
}

.custom-plan-modal .modal-dialog .modal-content .modal-body .planBack .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: relative;
  padding-top: 374px;
}

.custom-plan-modal .modal-dialog .modal-content .modal-body .planBack h4 {
  margin: 0;
  font-weight: 500;
  font-size: 72px;
  line-height: 83px;
  letter-spacing: -1px;
  color: #ffffff;
  padding-left: 15px;
  position: absolute;
  bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-shadow: 1px 1px 10px #000;
}

.custom-plan-modal .modal-dialog .modal-content .modal-body .plandesc {
  background-image: linear-gradient(90deg,
      rgba(255, 255, 255, 0.4),
      var(--third-color)),
    linear-gradient(90deg, #ffff, var(--primary-color));
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 15px 24px;
}

.custom-plan-modal .modal-dialog .modal-content .modal-body .plandesc p {
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: var(--secondry-color);
}

.custom-plan-modal .modal-dialog .modal-content .modal-body .planPrice {
  background: linear-gradient(90.89deg,
      var(--third-color) 0.9%,
      var(--primary-color) 95.3%);
  padding: 24px;
  height: 100%;
}

.custom-plan-modal .modal-dialog .modal-content .modal-body .planPrice .number-text {
  font-weight: 500;
  font-size: 80px;
  line-height: 92px;
  color: var(--secondry-color);
}

.custom-plan-modal .modal-dialog .modal-content .modal-body .planPrice .annually-toggal-row .annually-toggal .annually-lable {
  font-weight: 700;
  font-size: 19.8px;
  line-height: 23px;
  color: var(--secondry-color);
}

.custom-plan-modal .modal-dialog .modal-content .modal-body .planPrice .annually-toggal-row .annually-toggal .form-switch .form-check-input {
  height: 1.55em;
  background: transparent;
  border: 1px solid var(--secondry-color);
}

.custom-plan-modal .modal-dialog .modal-content .modal-body .planPrice .annually-toggal-row .annually-toggal .form-switch .form-check-input:checked {
  background: linear-gradient(145.53deg,
      var(--primary-color) 3.46%,
      #f5b43f 165.48%,
      #baad4c 223.28%,
      rgba(255, 255, 255, 0) 223.3%);
}

.custom-plan-modal .modal-dialog .modal-content .modal-body .planDetail {
  padding: 24px 15px 0;
}

.custom-plan-modal .modal-dialog .modal-content .modal-body .listHolder button {
  position: absolute;
  margin: 0 auto 0 !important;
  left: 50%;
  right: auto;
  bottom: 20px;
  transform: translateX(-50%);
  color: var(--secondry-color);
}

.custom-plan-modal .modal-dialog .modal-content .modal-body .planDetail h5 {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #9e9e9e;
}

.custom-plan-modal .modal-dialog .modal-content .modal-body .planDetail li {
  padding: 13px 0 0;
}

.custom-plan-modal .modal-dialog .modal-content .modal-body .planDetail li p {
  margin-bottom: 0;
  margin-left: 8px;
}

.custom-plan-modal .modal-dialog .modal-content .modal-body .payment-bottom-btn {
  margin-top: 32px;
  margin-bottom: 32px !important;
}

.custom-plan-modal .modal-dialog .modal-content .modal-body .listStageHold {
  position: relative;
  height: 660px;
}

.custom-plan-modal .modal-dialog .modal-content .modal-body .listHolder {
  height: 590px;
  overflow: auto;
}

.custom-plan-modal {
  padding: 0 !important;
}

.custom-plan-modal .modal-dialog .modal-content .modal-body .content-text-payment ul.planDetail li {
  align-items: flex-start;
}

.commonCard {
  margin-top: 24px;
}

.commonCard:first-child {
  margin: 0;
}

.licensesData {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #252f40;
}

.businessDetail {
  padding: 31px 20px;
}

.card-headings {
  padding: 0 0 30px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.card-headings h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  color: #252f40;
}

.card-headings img {
  margin-left: 5px;
}

.businessDetail .header {
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  color: #7e7e7e;
  font-family: 'JUST';
  font-weight: normal;
}

.businessDetail .bussinessvalue {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #0a0a0a;
  padding-top: 4px;
  font-family: 'Just-M';
}

.businessDetail .colPadding {
  padding-top: 24px;
}

.capitalHolder {
  padding-top: 40px;
}

.capitalHolder .sliderHeader {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #252f40;
  margin: 0;
  margin-bottom: 16px;
}

.capitalHolder .sliderText {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #7e7e7e;
  margin-right: 16px;
  cursor: pointer;
}

.capitalHolder .sliderText.active {
  color: #0a0a0a;
  border-bottom: 3px solid #0a0a0a;
  padding-bottom: 4px;
}

.agree-button {
  display: flex;
  align-items: center;
}

.agree-button img {
  margin-left: 3px;
}

.stepBack {
  left: 37px;
  position: absolute;
  top: 21px;
  font-size: 32px;
}

.modalHeaderPadding {
  padding: 0 0 32px 0;
}

.objectiveCard {
  border-radius: 12px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 58px 24px;
  height: 144px;
  cursor: pointer;
  margin-bottom: 20px;
}

.objectiveCard::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.55);
  top: 0;
  left: 0;
}

.objectiveBorder.objectiveCard::after {
  display: none;
}

.objectiveCardHold {
  z-index: 11;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.objectiveFooter {
  background: var(--primary-color);
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: right;
  padding: 6px;
}

.objectiveFooter i {
  cursor: pointer;
  color: var(--secondry-color);
}

.objectiveCard p.objectiveName {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  margin: 0;
  position: absolute;
  top: 50%;
  text-align: center;
  width: 100%;
  transform: translateY(-67%);
  padding: 0px 10px;
  text-shadow: 2px 2px #000;
}

.objectiveBorder p.objectiveName {
  color: var(--secondry-color);
}

.expandedObjectiveCard {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 12px;
  /* position: relative; */
  height: 310px;
  overflow-y: auto;
}

.expandedObjectHold {
  z-index: 123;
  /* position: relative; */
}

.expandedObjectHold::after {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
}

.expandedObjectHoldContent {
  position: relative;
  z-index: 123564;
  padding: 25px 55px 40px 25px;
  overflow: hidden;
}

p.expandedObjectiveName {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
  margin: 0px 0 10px;
  font-family: 'Just-M';
}

p.objectiveDesc {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #ffffff;
  margin-top: 9px;
  margin-bottom: 0px;
}

button.objectiveClose {
  background: none;
  outline: none;
  border: none;
  position: absolute;
  top: 20px;
  right: 20px;
}

.objectiveModal .modal-dialog {
  max-width: 656px;
}

button.btn.agree-button:disabled {
  background-color: #bdbdbd !important;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.12),
    0px 2px 4px -1px rgba(0, 0, 0, 0.07);
  opacity: 1;
}

.addBusinessModal .modal-dialog {
  max-width: 556px;
}

.objectiveCard img.selectedIcon {
  position: absolute;
  top: 12px;
  right: 12px;
}

button.dashboardAdd {
  text-align: center;
  background: var(--primary-color);
  border: 1px dashed var(--secondry-color);
  border-radius: 4px;
  /* margin-top: 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 14px 0px;
}

button.dashboardAdd p {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--secondry-color);
}

.addDashboard {
  background: var(--third-color);
  box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 36px 40px;
}

.addDashboard h5 {
  /* font-style: normal; */
  /* font-weight: 700; */
  font-family: 'Just-B';
  font-size: 18px;
  line-height: 22px;
  color: #0a0a0a;
  margin-bottom: 4px;
}

.addDashboard p {
  /* font-style: normal; */
  /* font-weight: 400; */
  font-size: 14px;
  line-height: 24px;
  color: #363636;
  margin-top: 16px;
  margin-bottom: 32px;
}

.addDashboard p:last-child {
  /* margin-top: 0; */
  margin-bottom: 0;
}

.upload-holder .upload-btn {
  margin-right: 16px;
}

.objectiveModalNew .modal-dialog {
  max-width: 656px;
}

.objectiveModalNew .objectiveUpper {
  background: #ffffff;
  padding: 32px 40px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
}

.objectiveModalNew .objectiveBottom {
  background: #ffffff;
  padding: 32px 40px;
  box-shadow: 0 -4px 12px 0 rgba(0, 0, 0, 0.16);
}

.objectiveModalNew .modal-content {
  background: none;
  border: 0;
  padding: 0 12px;
}

.objectiveModalNew .modal-content .modalHeaderIcons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.objectiveModalNew .modal-content .modalHeaderIcons .modalClose {
  background: unset;
  border: 0;
  outline: 0;
}

.objectiveModalNew .right-side-row {
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
}

.objectiveRow {
  max-height: 330px;
  overflow: auto;
  padding-left: 40px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 10px;
}

.customScrollBar::-webkit-scrollbar {
  width: 12px;
}

/* Track */
.customScrollBar::-webkit-scrollbar-track {
  background: #f5f5f5;
  border: 1px solid #d2d6da;
  width: 12px;
}

/* Handle */
.customScrollBar::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 24px;
}

/* Handle on hover */
.customScrollBar::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd;
}

.objectiveExpanded {
  padding: 0px 15px;
  position: relative;
}

.leftHolder {
  height: 100%;
}

.page-header.profile-header {
  margin-top: 0 !important;
}

.objectiveBorder {
  border: 6px solid #252f40;
}

.objectiveBorder.objectiveCard p.objectiveName {
  text-shadow: unset;
  transform: translateY(-50%);

}

.businessDetail .card-headings .status {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: auto;
}

.businessDetail .card-headings .status p {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #0a0a0a;
}

.businessDetail .card-headings .status span.approved {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: var(--secondry-color);
  padding: 6px 12px;
  background: var(--primary-color);
  border: 0.813665px solid var(--secondry-color);
  border-radius: 6.50932px;
  margin-left: 8px;
}

.businessDetail .card-headings .status span.pending {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #7e7e7e;
  background: #d2d6da;
  border: 0.813665px solid #7e7e7e;
  padding: 6px 12px;
  margin-left: 8px;
  border-radius: 6.50932px;
}

.shareHolderName .addEmail {
  color: #0a0a0a;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  cursor: pointer;
  position: relative;
  font-size: 16px;
}

/* .emailTooltip:hover::before {
  content: "Add email";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 16px;
  padding: 8px;
  background-color: #555;
  color: #fff;
  border-radius: 4px;
  width: 70px;
} */

[data-tooltip-top] {
  position: relative;
}

[data-tooltip-top]::after {
  position: absolute;
  width: 140px;
  bottom: 35px;
  left: -50px;
  text-align: center;
  box-sizing: border-box;
  content: attr(data-tooltip-top);
  background: black;
  color: #ffffff;
  padding: 5px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: normal;
  visibility: hidden;
  opacity: 0;
  transform: translateX(20px);
  transition: opacity 0.3s, transform 0.2s;
}

[data-tooltip-top]:hover::after {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
}

.objectiveDesc * {
  color: #fff !important;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px !important
}

.objectiveDesc p,
.objectiveDesc ol li,
.objectiveDesc ul li {
  margin-bottom: 10px;
}

.objectiveDesc ol,
.objectiveDesc ul {
  margin-left: 0;
  padding-left: 25px;
}

div.showMore {
  cursor: pointer;
  display: inline-block;
  text-align: right;
  width: 100%;
  color: purple;
  font-size: 10px;
}

div#esignPopup {
  max-width: 80%;
}

div#esignPopup .modal-content {
  height: 90vh;
}
.upgradeBtn.actionCustom{
  width:-webkit-fill-available;
}
@media screen and (max-width: 920px) {
  .landingPage .banner p {
    margin: 4px 0 8px;
    font-size: 11px;
    line-height: 14px;
  }

  .landingPage .banner {
    text-align: left;
    margin-bottom: 18px;
    padding-right: 36px;
  }

  .landingPage .banner .close-banner {
    right: 6px;
    top: 5px;
  }

  /* .landingPage .verifyForm {
    padding: 27px 27px;
  } */

  .landingPage .verfiyDesc {
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }

  .landingPage .verifyForm .verifyIndicator {
    padding: 16px;
  }

  .custom-plan-modal .modal-dialog .modal-content .modal-body .planBack {
    padding-top: 200px;
  }

  .custom-plan-modal .modal-dialog .modal-content .modal-body .planBack h4 {
    font-size: 38px;
    line-height: 54px;
  }

  .custom-plan-modal .modal-dialog .modal-content .modal-body .plandesc p {
    font-size: 14px;
    line-height: 24px;
  }

  .custom-plan-modal .modal-dialog .modal-content .modal-body .plandesc {
    padding: 15px 11px;
  }

  .custom-plan-modal .modal-dialog .modal-content .modal-body .planPrice {
    padding: 12px;
  }

  .custom-plan-modal .modal-dialog .modal-content .modal-body .planPrice .number-text {
    font-size: 37px;
    line-height: 45px;
    margin: 0px;
  }

  .custom-plan-modal .modal-dialog .modal-content .modal-body .planPrice .annually-toggal-row .annually-toggal .annually-lable {
    font-size: 15px;
    line-height: 18px;
  }

  .custom-plan-modal .modal-dialog .modal-content .modal-body .planDetail {
    padding: 13px 24px 0;
  }

  .custom-plan-modal .modal-dialog .modal-content .modal-body .planDetail h5 {
    font-size: 11px;
    line-height: 12px;
  }

  .custom-plan-modal .modal-dialog .modal-content .modal-body .planDetail li {
    padding: 8px 0 0;
  }

  .content-text-payment ul li p {
    font-size: 10px;
    line-height: 14px;
  }

  .custom-plan-modal .modal-dialog .modal-content .modal-body .listHolder {
    height: 365px;
  }

  .custom-plan-modal .modal-dialog {
    width: 95%;
  }

  .objectiveModalNew .objectiveUpper {
    padding: 23px 21px;
  }

  .objectiveModalNew .objectiveBottom {
    padding: 23px 21px;
  }
}

@media screen and (max-width: 767px) {
  .landingPage .banner {
    flex-direction: column;
    padding-left: 15px;
    padding-right: 30px;
    padding-top: 5px;
    padding-bottom: 10px;
  }

  .landingPage .banner .bannerBtn {
    width: auto;
    max-width: max-content;
    min-width: auto;
  } 

  .addDashboard h5 {
    font-size: 14px;
    line-height: 16px;
  }

  .addDashboard p:last-child {
    margin-top: 0;
    margin-bottom: 0;
  }

  .custom-plan-modal .modal-dialog .modal-content .modal-body .listHolder {
    height: 225px;
  }

  .custom-plan-modal .modal-dialog .modal-content .modal-body .listStageHold {
    position: relative;
    height: auto;
    background: #fff;
    padding-bottom: 90px;
  }

  .custom-plan-modal .modal-dialog .modal-content .modal-body .listHolder button {
    width: 220px;
  }
}